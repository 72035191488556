$faq-item-border-color: #B6DAFD;
$faq-table-border-color: #ddd;
$faq-image-text-color: #92a5b7;

.faq__container {
  color: $color-light-black;
  font-size: 20px;
  line-height: 1.5;
  margin: 0;
}

.faq__outer {
  border-top: 1px solid $faq-item-border-color;
  margin: 25px 0;
}

.faq__item {
  border-bottom: 1px solid $faq-item-border-color;
}

.faq__content {
  display: none;
}

.faq__question {
  color: $color-light-black;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 700;
  padding: 20px 40px 20px 0;
  text-align: left;
  position: relative;
  transition: all 0.3s linear;

  &:after {
    content: '';
    position: absolute;
    background: url('@/static/images/icons/default/icon-select.svg');
    background-size: 100% auto;
    width: 14px;
    height: 8px;
    right: 0;
    top: calc(50% - 4px);
    transition: all 0.3s linear;
  }

  &:hover {
    cursor: pointer;
  }

  &--show-answer {
    color: $color-orange;

    &:after {
      background: url('@/static/images/icons/default/icon-select-orange.svg');
      transform: rotate(180deg);
    }

    +.faq__content {
      display: flex;
      flex-direction: column;
    }
  }
}

.article-view__content .faq__answer,
.faq__answer {
  color: $color-light-black;
  font-size: 20px;
  line-height: 1.5;
  padding: 0;

  .overflowed {
    white-space: unset !important;
  }

  a {
    font-weight: 700;
    word-break: break-all;
  }

  p {
    margin: 0 0 15px;
    text-align: left;
  }

  img {
    display: block;
    height: auto;
    margin: 0 auto 25px;
    max-width: 100%;
  }

  ul {
    border: 0;
    list-style: none;
    margin: 0 0 20px;
    padding: 0 0 0 20px;

    li {
      color: $color-light-black;
      font-size: 22px !important;
      line-height: 1.5 !important;
      list-style: none;
      margin: 0 0 15px;
      position: relative;
      text-align: left;

      &::before {
        background: $color-blue;
        border-radius: 50%;
        content: '';
        height: 8px;
        left: -20px;
        position: absolute;
        top: 11px;
        width: 8px;
      }
    }

    ul {
      font-size: 22px;
      margin: 15px 0;
      padding: 0;

      li {
        margin: 0 0 10px;

        &::before {
          background: transparent;
          color: $color-orange;
          content: '•';
          height: auto;
          padding-right: 2px;
          position: static;
          width: auto;
        }
      }

      ul {
        margin: 15px 0;

        li {
          color: $color-grey-text-list;
          margin: 0 0 10px 30px;
        }
      }
    }
  }

  ol {
    border: 0;
    counter-reset: heading;
    list-style: none;
    margin: 0 0 20px;
    padding: 0 0 0 20px;

    li {
      color: $color-light-black;
      font-size: 22px !important;
      line-height: 1.5 !important;
      list-style: none;
      margin: 0 0 15px;
      position: relative;
      text-align: left;

      &::before {
        color: $color-blue;
        content: counter(heading) ". ";
        counter-increment: heading;
        font-size: 20px;
        font-weight: 700;
        left: -20px !important;
        padding-right: 3px;
        position: absolute;
        top: 0;
      }

      ol {
        border: 0;
        font-size: 22px;
        margin: 10px 0 25px;
        padding: 0;

        > li {
          &::before {
            font-size: 22px;
            position: static;
          }

          ol {
            counter-reset: my-awesome-counter;

            > li {
              color: $color-grey-text-list;
              margin: 0 0 10px 30px;

              &::before {
                color: $color-orange;
                content: counter(my-awesome-counter, lower-alpha) '. ';
                counter-increment: my-awesome-counter;
              }
            }
          }
        }
      }
    }
  }

  table {
    border: 2px solid $faq-table-border-color;
    border-radius: 0 0 4px 4px;
    margin: 0 0 40px;
    position: relative;
    width: 100%;

    p {
      margin: 0;
    }

    caption {
      background: $color-title;
      caption-side: top;
      color: $color-white;
      font-size: 20px !important;
      font-weight: 700;
      line-height: 1.5 !important;
      padding: 7px 10px 7px 20px;
      position: relative;
      text-align: left;
      text-transform: uppercase;

      &::before {
        background: $color-title;
        bottom: 0;
        content: '';
        left: -6px;
        position: absolute;
        top: 0;
        width: 6px;
      }

      &::after {
        border-color: transparent $color-main transparent transparent;
        border-style: solid;
        border-width: 6px 6px 6px 0;
        bottom: -6px;
        content: '';
        height: 0;
        left: -6px;
        position: absolute;
        width: 0;
      }
    }

    thead {
      th {
        background: $color-white;
        color: $color-light-black;
        font-size: 20px !important;
        font-weight: 700;
        line-height: 1.5 !important;
        padding: 20px;
        position: relative;
        text-align: left;
        text-transform: uppercase;

        &::after {
          background: $faq-table-border-color;
          bottom: 10px;
          content: '';
          position: absolute;
          right: 0;
          top: 10px;
          width: 2px;
        }

        &:last-child {
          &::after {
            display: none;
          }
        }
      }
    }

    tbody {
      td {
        background: $color-white;
        color: $color-light-black;
        font-size: 20px !important;;
        line-height: 1.5 !important;;
        padding: 17px 20px;
        text-align: left;
      }

      tr {
        &:nth-child(2n + 1) {
          td {
            background: $faq-table-border-color;
          }
        }
      }
    }

    &.table-last-cell-right-alignment {

      tbody,
      thead {

        td,
        th {
          &:last-child:not(:first-child) {
            text-align: right;
          }
        }
      }
    }

    &.table-all-cells-center-alignment {

      tbody,
      thead {

        td,
        th {
          text-align: center;
        }
      }
    }

    &.table-all-cells-right-alignment {

      tbody,
      thead {

        td,
        th {
          text-align: right;
        }
      }
    }
  }

  >.overflowed {
    white-space: unset;
  }
}

.faq__answer-full-image {
  margin: 0 -20px;
}

.faq__answer-dark-image {
  background: $color-title;
  margin: 0 -20px 25px;
  padding: 40px;

  img {
    box-shadow: $box-shadow-image;
    margin: 0 auto;
  }
}

.faq__answer-image-descr {
  background: $color-title;
  margin: 0 -20px 25px;
  padding: 55px 20px 0;

  p {
    color: $faq-image-text-color;
    font-size: 20px;
    font-style: italic;
    line-height: 1.5;
    margin: 0 auto;
    max-width: 450px;
    text-align: center;
  }

  img {
    box-shadow: $box-shadow-image;
    margin: 30px auto 0;
  }
}