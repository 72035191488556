.about-us {

  &__our-team {
    position: relative;
    padding: 150px 0 140px;
    background-color: $color-sky-blue;
    display: flex;
    align-items: center;
    justify-content: center;

    @media #{$mobile} {
      padding: 120px 15px 80px;
    }

    &:before,
    &:after {
      position: absolute;
      content: '';
      width: 100%;
      left: 0;
      background-repeat: no-repeat;
      background-size: 100% auto;
      z-index: 1;

      @media #{$mobile} {
        background-size: auto;
      }
    }

    &:before {
      top: 0;
      background-color: white;
      background-image: url('@/static/images/pages/index/mobile-app/blue-wave-v2.svg');
      background-position: right bottom -2px;
      height: 150px;

      @media #{$min-width-xl} {
        height: 230px;
      }

      @media #{$mobile} {
        height: 90px;
        background-position: left 90%;
      }
    }

    &:after {
      background-size: cover;
      bottom: 0;
      background-color: white;
      background-image: url('@/static/images/pages/index/mobile-app/blue-wave-bottom.svg');
      background-position: left bottom;
      z-index: 1;
      height: 140px;

      @media #{$mobile} {
        height: 80px;
      }
    }

    &-wrapper {
      display: flex;
      position: relative;
      z-index: 11;
    }

    &-half {
      display: flex;

      &--columned {
        flex-direction: column;
      }

      &:first-of-type {
        @media #{$tablet} {
          display: none;
        }
      }
    }

    &-line {
      display: flex;
      flex-direction: column;

      &:nth-child(odd) {
        margin-top: 44px;
      }

      > img {
        width: 86px;
        height: 86px;
        object-fit: contain;
        box-shadow: 9px 19px 35px rgba(0, 0, 0, .1), 0 4px 4px rgba(0, 0, 0, .25);
        border-radius: 16px;
        margin: 10px;

        @media #{$mobile} {
          width: 62px;
          height: 62px;
          margin: 5px;
        }

        @media #{$extra-small} {
          width: 50px;
          height: 50px;
        }
      }
    }

    &-top-images {
      display: flex;

      .about-us__our-team-line {
        &:nth-child(3),
        &:nth-child(5) {
          margin-top: 44px;
        }
      }

      @media #{$mobile} {
        display: none;
      }
    }

    &-bottom-images {
      display: flex;

      .about-us__our-team-line {
        &:nth-child(1),
        &:nth-child(3),
        &:nth-child(5) {
          margin-top: 44px;
        }
      }

      @media #{$mobile} {
        display: none;
      }
    }

    &-mobile {
      display: none;
      justify-content: center;

      .about-us__our-team-line {
        &:nth-child(1),
        &:nth-child(3),
        &:nth-child(5) {
          margin-top: 25px;
        }
      }

      @media #{$mobile} {
        display: flex;
      }
    }

    &-text {
      height: 380px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > div {
        max-width: 320px;
        text-align: left;
      }

      .static-title {
        text-align: left;
      }

      p {
        font-size: 25px;
        line-height: 1.2;

        @media #{$mobile} {
          text-align: center;
        }
      }

      @media #{$mobile} {
        height: 280px;
      }
    }
  }
}

// Our Team
.about-us-our-team {
  .static-title {
    margin: 0 0 25px;
    text-align: left;

    @media #{$mobile} {
      text-align: center;
    }
  }

  .static-title-sub {
    text-align: left;

    @media #{$mobile} {
      text-align: center;
    }
  }

  &__item {
    float: left;
    width: 10%;

    &:nth-child(41) {
      margin-left: 25%;
    }

    img {
      max-width: none;
      width: 100%;
    }

    @media #{$xl} {
      margin: 0;
      width: 11.11%;

      &:nth-child(41) {
        margin: 0;
      }
    }

    @media #{$lg} {
      width: 14.28%;

      &:nth-child(43) {
        margin-left: 28.56%;
      }
    }

    @media #{$lg-md} {
      width: 16.66%;

      &:nth-child(43) {
        margin-left: 24.99%;
      }
    }

    @media #{$mobile} {
      width: 33.33%;

      &:nth-child(43) {
        margin-left: 33.33%;
      }
    }
  }
}

// Country Guide
.about-us-country-guide {
  padding: 90px 0 90px;

  .static-title {
    margin: 0 auto 70px;
    text-align: center;

    @media #{$lg-md} {
      margin: 0 auto 20px;
    }
  }
}
