@import "consultations-calendar";

.phone-cons-page {
  .container {
    max-width: 980px;
  }

  &__content {
    padding: 40px 60px;
    background: $color-white;
    max-width: 970px;
    margin: 0 auto 80px;
    box-shadow: 0 9px 22px rgba(0, 0, 0, .1), 0 3px 6px rgba(0, 0, 0, .1);
    border-radius: 4px;
    position: relative;
    z-index: 1;

    p {
      color: $color-grey;
      font-size: 16px;
      line-height: 1.31;
      margin: 0 0 25px;
      text-align: justify;
    }

    a:not(.btn) {
      font-weight: 700;

      &:hover {
        color: $color-link;
        text-decoration: none;
      }
    }

    h3 {
      color: $color-title;
      font-size: 35px;
      font-weight: 700;
      margin: 70px 0 20px;
      text-align: center;

      @media #{$mobile} {
        margin: 0 0 20px;
      }
    }

    @media #{$mobile} {
      padding: 40px 20px;
    }
  }

  &__head {
    position: relative;
    background-color: $color-blue;
    padding-top: 40px;
    padding-bottom: 80px;

    &:after {
      position: absolute;
      width: 100%;
      content: "";
      background-size: cover;
      bottom: 0;
      background-color: white;
      background-image: url("../images/default/dark-wave-bottom.svg");
      height: 110px;
      background-position: left bottom;
      z-index: -1;
      top: 99%;

      @media #{$mobile} {
        display: none;
      }
    }

    @media #{$mobile} {
      padding-bottom: 30px;
    }
  }

  &__head-inner {
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 100%;
  }

  &__head-content {
    max-width: 532px;
  }

  &__head-image {
    padding: 20px;

    @media #{$mobile} {
      display: none;
    }
  }

  &__nav {
    align-items: center;
    justify-content: center;
    display: flex;
    margin: -50px auto 0;
    max-width: 970px;
    position: relative;
    z-index: 1;

    @media #{$mobile} {
      margin: 0;
      padding: 0 20px;
      background-color: $color-blue;
    }
  }

  &__nav-item {
    padding: 12px 30px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: white;
    opacity: .5;
    position: relative;

    @media #{$mobile} {
      padding: 7px;
    }

    &.active {
      opacity: 1;
      cursor: default;

      &:after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        border-style: solid;
        border-width: 0 8px 8px 8px;
        border-color: transparent transparent white transparent;
      }

      &:hover,
      &:focus {
        color: white;
      }
    }

    &:hover,
    &:focus {
      color: white;
    }
  }

  &__nav-item-image {
    display: none;

    @media #{$mobile} {
      display: block;
      margin: 10px auto;
    }
  }

  &__container {
    background: $color-white;
    margin: 0 auto;
    max-width: 970px;
    padding: 600px 105px 80px 105px;

    @media #{$mobile} {
      margin-top: 20px;
      padding: 20px;
    }
  }
}
