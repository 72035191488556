$single-independent-review__item-count-color: rgba(255, 255, 255, .5);

// Color reviews
$single-independent-review__item--trust-pilot-color: #1a1a1a;
$single-independent-review__item--facebook-color: #3d5a98;
$single-independent-review__item--verified-color: #1a1a1a;

.single-independent-review {
  color: $color-title;
  font-size: 25px;
  letter-spacing: .5px;
  line-height: 1.5;
  padding: 25px 0 50px;
  text-align: center;

  .container {
    max-width: 860px;
  }

  &__sub-title {
    margin-bottom: 20px;
  }

  &__wrapper {
    align-items: center;
    display: flex;
    justify-content: center;

    @media #{$tablet} {
      display: block;
      text-align: center;
    }
  }

  &__inner {
    padding: 15px;
    flex: 1;

    @media #{$xl} {
      width: calc(25% - 20px);
      padding: 0;
      float: left;
      margin: 10px;
    }

    @media #{$tablet} {
      width: calc(50% - 20px);
      padding: 0;
    }

    @media #{$mobile} {
      width: calc(100% - 20px);
    }
  }

  &__item {
    height: 124px;
    padding: 15px 30px 15px;
    position: relative;
    width: 100%;

    @media #{$xl} {
      width: 100%;
      padding: 15px 10px;
    }

    &--trust-pilot {
      background: $single-independent-review__item--trust-pilot-color;
    }

    &--facebook {
      background: $single-independent-review__item--facebook-color;
    }

    &--verified {
      background: $single-independent-review__item--verified-color;
    }

  }

  &__item-photo {
    align-items: center;
    display: flex;
    height: 50px;
    justify-content: flex-start;
    width: 100%;

    img {
      margin: 0;
      max-width: 80%;
    }
  }

  &__item-count {
    bottom: 15px;
    color: $single-independent-review__item-count-color;
    font-size: 12px;
    left: 15px;
    letter-spacing: .3px;
    line-height: 1.4;
    position: absolute;
    text-align: left;

    @media #{$mobile} {
      font-size: 16px;
    }
  }

  &__item-stars {
    background: url('@/static/images/pages/compare/review/icon-orange-patch.svg') no-repeat 50% 50%;
    background-size: cover;
    color: $color-white;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -.55px;
    line-height: 50px;
    position: absolute;
    right: -10px;
    text-align: center;
    bottom: 10px;
    width: 95px;
    height: 39px;

    @media #{$mobile} {
      font-size: 16px;
    }
  }
}

.icon-single-independent-review-star {
  background: url('@/static/images/pages/compare/review/icon-star-review.svg') no-repeat 50% 50%;
  background-size: contain;
  height: 10px;
  margin: -5px -2px 0;
  width: 10px;

  &:first-of-type {
    margin-left: 10px;
  }
}
