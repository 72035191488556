.reviews-landing {
  padding: 70px 0;

  .static-title {
    margin: 0 0 50px;
    text-align: center;
  }

  .container {
    overflow: visible;
  }
}
