.countries {
  margin-top: 160px;

  .container {
    max-width: 1100px;
  }

  @media #{$mobile} {
    margin-top: 100px;
  }

  &__inner {
    padding: 30px 0;
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
    justify-items: start;
    align-items: center;

    @media #{$mobile} {
      gap: 24px;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__country-name {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    position: relative;
    padding-right: 20px;

    &:after {
      content: '';
      position: absolute;
      background: url('@/static/images/pages/index/countries/arrow.svg');
      background-size: 100% auto;
      width: 8px;
      height: 12px;
      right: 0;
      top: calc(50% - 6px);
      transition: all 0.3s linear;
    }
  }

  &__country-link {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;

    &:hover {
      .countries__country-name {
        text-decoration: underline;
        &:after {
          right: -4px;
        }
      }
    }
  }
}
