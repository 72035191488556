$guides-text-blue-image-box-shadow: .3px 5px 20px 0 rgba(0, 0, 0, .2);
$guides-section-height: 68px;
$guides-section-title-number-bg: #151d2e;

.guides-section {
  padding: 40px 0 10px;
}

.guides-section__title {
  align-items: center;
  background: $color-title;
  color: $color-white;
  display: flex;
  font-size: 35px;
  font-weight: 700;
  height: $guides-section-height;
  justify-content: space-between;
  letter-spacing: -.4px;

  @media #{$mobile} {
    font-size: 26px;
  }
}

.guides-section__title-number {
  align-items: center;
  background: $guides-section-title-number-bg;
  display: flex;
  height: $guides-section-height;
  justify-content: center;
  width: $guides-section-height;
}

.guides-section__title-text {
  padding-right: $guides-section-height;
  text-align: center;
  width: calc(100% - #{$guides-section-height});
}

.guides-text {
  color: $color-grey;
  font-size: 16px;
  line-height: 1.3;
  text-align: justify;

  p {
    margin: 0 0 25px;

    &:last-child {
      margin: 0;
    }
  }

  &.without-padding-bottom {
    padding-bottom: 0;
  }
}

.guides-number-text {
  padding: 0 0 45px;

  @media #{$mobile} {
    padding-bottom: 20px;
  }
}

.guides-text__title {
  color: $color-text-dark;
  font-size: 34px;
  font-weight: 700;
  letter-spacing: -.4px;
  line-height: 1.2;
  margin: 0;
  padding: 20px 0 15px;
  text-align: center;
}

.guides-quote {
  border-left: 8px solid $color-orange;
  color: $color-text-dark;
  font-size: 24px;
  font-style: italic;
  letter-spacing: .5px;
  line-height: 1.2;
  margin: 40px 130px 40px 30px;
  padding: 5px 0 5px 90px;
  text-align: justify;

  @media #{$tablet} {
    font-size: 20px;
    margin: 20px 30px;
  }

  @media #{$mobile} {
    font-size: 18px;
    margin: 30px 20px 30px;
    padding: 10px 0 10px 20px;
  }

  .guides-text__title {
    padding-top: 10px;
    text-align: left;
  }
}

.guides-quote--small {
  margin: 40px auto;
  max-width: 960px;
  padding: 5px 90px;

  @media #{$tablet} {
    padding: 5px 0 5px 20px;
  }

  @media #{$mobile} {
    font-size: 16px;
    margin: 20px auto;
  }
}
