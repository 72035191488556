$styled-input-border-color: #ebebeb;
$input-phone__flag-color-select: #b2b5b9;
$country-flags-list__item-color-bg: #f2f2f2;
$country-flags-list__item-flag-box-shadow: rgba(0, 0, 0, .4);

.country-flags-tooltip {
  background: $color-white;
  border: 1px solid $color-border;
  border-radius: 4px;
  margin-top: 5px;
  position: absolute;
  top: 100%;
  width: 350px;
  z-index: 10;
}

.country-flags-list {
  border-top: 1px solid $color-border;
  max-height: 220px;
  overflow-y: auto;
  padding: 10px 0;
}

.country-flags-list-empty {
  color: $color-grey;
  font-size: 16px;
  padding: 0 12px;
}

.country-flags-wrapper-search {
  padding: 10px;
  position: relative;
}

.country-flags-search-icon {
  display: none;
}

.country-flags-search-clear {
  cursor: pointer;
  height: 32px;
  position: absolute;
  right: 12px;
  top: 15px;
  width: 32px;
  z-index: 9;

  &::before,
  &::after {
    background: $color-orange;
    content: '';
    height: 16px;
    position: absolute;
    top: 8px;
    width: 2px;
  }

  &::before {
    left: 15px;
    transform: rotate(45deg);
  }

  &::after {
    right: 15px;
    transform: rotate(-45deg);
  }
}

.country-flags-search {
  border: 1px solid $styled-input-border-color;
  border-radius: 4px;
  color: $color-grey;
  display: block;
  font-size: 14px;
  height: 32px;
  margin: 0;
  outline: none;
  padding: 0 32px 0 52px;
  width: 100%;

  &::placeholder {
    color: $color-grey;
    font-size: 14px;
  }
}

.country-flags-list__item {
  align-items: center;
  color: $color-grey;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  height: 50px;
  padding: 0 20px;
  width: 100%;

  &:hover {
    background: $country-flags-list__item-color-bg;

    .country-flags-list__item-name {
      color: $color-blue;
    }
  }
}

.country-flags-list__item-flag {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  box-shadow: 0 0 3px 0 $country-flags-list__item-flag-box-shadow;
  height: 30px;
  min-width: 45px;
  width: 45px;
}

.country-flags-list__item-code {
  font-weight: 700;
  min-width: 70px;
  padding: 0 0 0 15px;
  width: 70px;
}

.input-phone__flag {
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% - 2px);
  z-index: 1;
  width: 55px;
  cursor: pointer;

  &::after {
    position: absolute;
    content: '';
    top: 21px;
    margin-top: -2px;
    right: 7px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 3px 0 3px;
    border-color: $color-black transparent transparent;

  }
}

.form-group__styled--phone {
  .input-phone__flag {
    top: 34%;
  }
}

.input-phone__flag-image {
  position: relative;
  top: 14px;
  left: 14px;
  width: 20px;
  height: 14px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
}

.input-phone {
  padding-left: 60px !important;

  &__wrapper {
    margin-right: 15px;

    @media #{$mobile} {
      margin-right: 0;
    }
  }

  &__inner {
    position: relative;
  }
}
