$articles-list-news__item-height: 340px;

body {
  &.article-category-page {
    background: #EFF0F4;
    min-width: auto;

    .header {
      min-width: auto;
    }
  }
}

.article-category-page {
  .page-title {
    margin-top: 0;
    padding-top: 100px;
  }
}

.dropdown {
  .dropdown-menu {
    border: 0;
    display: none;
    transition: all .5s ease;
    margin-top: 10px;

    &::after {
      display: none;
    }
  }

  .show-dropdown {
    + .dropdown-menu {
      display: flex;
      flex-direction: column;
    }
  }
}

.articles-list-categories__wrapper-select,
.list-containers__wrapper-select {
  height: 20px;
  line-height: 20px;
  position: relative;
  right: $indent-left-right-side-page;
  top: 30px;
  max-width: 280px;
  z-index: 99;
  float: right;

  @media #{$md} {
    right: 30px;
  }

  @media #{$mobile} {
    top: 60px;
    width: calc(100% - 40px);
    left: 20px;
    right: 20px;
    float: unset;
    max-width: unset;

    .dropdown {
      width: 100%;

      .articles-list-categories__button {
        width: 100%;
      }
    }
  }

  .dropdown-menu {
    position: relative;
    width: 100%;
    max-width: 240px;
    top: 0;
    float: right;

    @media #{$mobile} {
      max-width: unset;
    }

    &::before,
    &::after {
      left: initial;
      right: 10px;
    }

    &:before {
      top: -8px;
    }

    .dropdown-item {
      &.active {
        color: white;
      }
    }
  }

  .dropdown {
    right: 30px;
    white-space: nowrap;
  }
}

.articles-list-categories__button,
.list-containers__button-select {
  float: right;
  border: 0;
  color: $color-blue;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  position: relative;
  padding: 10px 35px 10px 10px;
  border-radius: 4px;
  background: $color-white;

  &:hover {
    color: darken($color-blue, 5%);
  }

  &::after {
    background-image: url('@/static/images/pages/articles/icon-articles-latest-news__article-link.svg');
    background-position: 50% 65%;
    background-repeat: no-repeat;
    background-size: 7px;
    border: 0;
    bottom: 0;
    content: '';
    height: 100%;
    margin: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: rotate(90deg);
    width: 35px;
  }

  @media #{$mobile} {
    width: 100%;
  }
}

.articles-list-news {
  padding: 0 0 70px;

  .container {
    display: flex;
    flex-wrap: wrap;
    max-width: 1330px;
  }
}

.articles-list-news__item {
  background: $color-white;
  border-radius: $border-radius-base;
  display: flex;
  height: $articles-list-news__item-height;
  margin: 0 30px 30px 0;
  position: relative;
  width: calc(50% - 15px);

  @media #{$tablet} {
    width: 100%;
  }

  @media #{$mobile} {
    margin: 0 0 30px 0;
    flex-direction: column;
    height: auto;
  }

  &:nth-child(2n + 1) {
    margin-right: 0;

    @media #{$tablet} {
      margin: 0 0 30px 0;
    }

    @media #{$mobile} {
      margin: 0 0 30px 0;
    }
  }

  &:first-child {
    width: 100%;

    .articles-list-news__item-title {
      font-size: 34px;
      letter-spacing: .7px;
      max-height: 160px;
      max-width: 90%;

      @media #{$mobile} {
        max-width: 100%;
        max-height: unset;
        font-size: 28px;
      }
    }

    .articles-list-news__item-image {
      height: auto;
      max-width: 100%;
      width: 100%;
    }
  }
}

.articles-list-news__item-content {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding: 55px 25px 55px 40px;
  width: 55%;

  @media #{$mobile} {
    width: 100%;
    padding: 40px 20px 20px;
    position: relative;
  }
}

.articles-list-news__item-date {
  color: $color-light-grey;
  font-size: 14px;
  font-weight: 700;
  left: 40px;
  letter-spacing: .3px;
  line-height: 1;
  position: absolute;
  top: 35px;

  @media #{$mobile} {
    top: 20px;
    left: 20px;
  }
}

.articles-list-news__item-wrapper-title {
  display: block;
  width: 100%;
}

.articles-list-news__item-title {
  color: $color-title;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: .5px;
  line-height: 1.2;
  margin: 0 0 10px;
  max-height: 140px;
  overflow: hidden;
  width: 100%;
  text-align: left;

  @media #{$mobile} {
    font-size: 24px;
    margin: 10px 0;
  }
}

.articles-list-news__item-category {
  color: $color-orange;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: .3px;
  line-height: 1.3;
  width: 100%;
}

.articles-list-news__item-link {
  bottom: 35px;
  color: $color-blue;
  font-size: 14px;
  font-weight: 700;
  left: 40px;
  letter-spacing: 1.4px;
  line-height: 1;
  position: absolute;

  @media #{$mobile} {
    bottom: 15px;
    left: 20px;
  }
}

.articles-list-news__item-photo {
  align-items: center;
  border-radius: 0 #{$border-radius-base} #{$border-radius-base} 0;
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 45%;
  position: relative;

  @media #{$ie} {
    flex-direction: column;
  }

  @media #{$mobile} {
    height: 200px;
    align-items: center;
    border-radius: 0 0 4px 4px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 100%;
  }
}

.articles-list-news__item-image {
  max-height: 100%;
  max-width: none;
  width: auto;

  @media #{$mobile} {
    height: 200px;
    width: 100%;
    max-width: none;
    max-height: 100%;
    object-fit: contain;
  }

  @media #{$ie} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
