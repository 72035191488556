.landing__header {
  align-items: stretch;
  display: flex;
  position: relative;
  min-height: 650px;

  @media #{$mobile} {
    flex-direction: column;
    height: auto;
    min-height: auto;
  }

  .container {
    display: flex;
    padding-top: 60px;
    padding-bottom: 120px;

    @media #{$mobile} {
      flex-direction: column;
      padding-bottom: 80px;
    }
  }

  &__wave {
    background: url('@/static/images/pages/services/pricing/additional-bg.svg') no-repeat bottom center / contain;
    height: 100%;
    min-height: 100%;
    position: absolute;
    z-index: -1;
    bottom: -2px;
    right: 0;
    width: 100%;
    pointer-events: none;
  }

  &-pic {
    position: relative;
    overflow: hidden;
    min-width: 500px;
    max-width: 500px;

    @media #{$md} {
      min-width: 500px;
    }

    @media #{$sm} {
      min-width: 400px;
    }

    @media #{$extra-small} {
      min-width: auto;
      max-width: 300px;
    }

    &:before,
    &:after {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      background-color: white;
      z-index: 1;
    }

    &:before {
      height: 1px;
      width: 100%;
    }

    &:after {
      height: 100%;
      width: 1px;
    }
  }

  &-pic-main {
    position: relative;
    max-width: 500px;

    img {
      width: 100%;
      line-height: 0;
    }
  }

  &-pic-flag {
    position: absolute;
    right: -18%;
    top: 0;

    img {
      height: 306px;
      width: auto;
      line-height: 0;

      @media #{$md} {
        height: 256px;
      }

      @media #{$sm} {
        height: 204px;
      }

      @media #{$mobile} {
        height: 306px;
      }

      @media #{$extra-small} {
        height: 154px;
      }
    }
  }

  &__header-left {
    align-items: flex-start;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px 0 0;

    @media #{$xl} {
      min-width: 550px;
      padding: 0 20px;
    }

    @media #{$tablet} {
      min-width: unset;
    }

    @media #{$mobile} {
      width: 100%;
    }
  }

  &-sub-title {
    font-size: 24px;
    line-height: 30px;
    color: $color-blue-navi;
    max-width: 570px;

    @media #{$mobile} {
      text-align: center;
    }
  }

  &-right {
    display: flex;
    align-items: baseline;
    justify-content: center;
    position: relative;
    padding-top: 60px;

    /* flag with specific positions */
    &.hong-kong-header {
      .landing__header-pic-flag {
        right: -12%;
      }
    }

    &.ireland-header,
    &.italy-header {
      .landing__header-pic-flag {
        right: -7%;
      }
    }


    @media #{$mobile} {
      width: 100%;
      flex-direction: column-reverse;
      margin-top: 50px;
    }
  }
}

.landing-home__form {
  position: relative;
  border: solid 1px #A6C5E6;
  border-radius: 4px;
  padding: 30px;
  width: 100%;
  max-width: 430px;
  margin: 0;

  @media #{$tablet} {
    margin: 0;
    padding: 30px 10px;
    border-radius: 0;
    border: 0;
  }

  @media #{$mobile} {
    margin: 0 auto 90px;
    padding: 0;
  }

  .auth-form-buttons {
    .button__email {
      background: #f8620e;
    }

    &__group {
      display: flex;

      .button + .button {
        margin-left: 15px;
      }
    }

    &__separator {
      &:after {
        background-color: white;
      }
    }

    &__agreements {
      position: absolute;
      top: calc(100% + 23px);
      left: 0;
      right: 0;
      width: 84%;
      margin: 0 auto;
      display: block;
      font-size: 14px;
      color: #404040;
      background: white;
      padding: 7px 0;
      text-align: center;
      white-space: normal;

      a {
        color: #2588ed;
        font-weight: bold;
      }
    }
  }
}

.landing__form-title {
  font-size: 60px;
  font-weight: bold;
  letter-spacing: -.4px;
  line-height: 1.15;
  margin-bottom: 20px;
  color: $color-title;
  text-align: left;

  @media #{$tablet} {
    font-size: 44px;
  }

  @media #{$mobile} {
    font-size: 34px;
    text-align: center;
  }
}
