.simple-form-registration__btn__wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  width: 100%;
}

.simple-form-registration__btn {
  width: calc(50% - 10px);
  margin: 0;
}

.simple-form-registration {
  display: flex;
  flex-wrap: wrap;

  .form-group__styled {
    width: calc(50% - 8px);
    margin: 0 20px 20px 0;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  .btn {
    display: block;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    min-width: auto;
  }
}




.simple-form-registration__btn {
  width: calc(50% - 10px) !important;
  margin: 0;

  .btn {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    min-width: auto;
  }
}