
.brand-book-article {
  h2 {
    font-weight: 700;
    margin: 0 0 30px;
  }
}

.brand-book-article-uploading {
  margin: 0 0 30px;
}

.brand-book-article-image {
  margin: 0 30px 50px 0;
}

.brand-book-article-image__title {
  color: $color-title;
  font-size: 22px;
  margin: 0 0 15px;
}

.brand-book-article-image--inside {
  .brand-book-article-image__photo {
    height: 330px;
    width: 960px;
  }
}

.brand-book-article-image--big-cover {
  .brand-book-article-image__photo {
    height: 340px;
    width: 650px;
  }
}

.brand-book-article-image--small-cover {
  .brand-book-article-image__photo {
    height: 340px;
    width: 280px;

    img {
      height: 100%;
      max-width: none;
      width: auto;
    }
  }
}

.brand-book-article-image--mobile-cover {
  .brand-book-article-image__photo {
    height: 200px;
    width: 336px;
  }
}

.brand-book-article-image--original {
  .brand-book-article-image__photo {
    height: auto;
    max-width: 1920px;
    min-height: 300px;
  }
}

.brand-book-article-image__photo {
  align-items: center;
  background: $color-lightest-grey;
  display: flex;
  justify-content: center;
  overflow: hidden;

  &::before {
    color: rgba(26, 35, 54, .2);
    content: 'See your image here';
    font-size: 24px;
    position: absolute;
  }
}

.brand-book-article-image__photo--uploaded {
  &::before {
    display: none;
  }
}
