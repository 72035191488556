.container-error-page-wrapper {
  background-color: #11467d;
}

.container-error-page {
  max-width: 80%;
  padding: 80px 0 20px 0;
  margin: 0 auto;
  color: #fff;

  .error-page-information {
    display: flex;
    max-width: 920px;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: auto;
  }

  .error-page-image {
    padding: 0 30px;
    img {
      margin: 0 auto;
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  .error-page-description {
    max-width: 400px;
    padding: 40px 30px 0 30px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .error-page-title {
    font-size: 60px;
    font-weight: 700;
    margin: 0 auto 30px auto;
  }

  .error-page-message {
    line-height: 24px;
    text-align: justify;
  }

  .error-page-navigation {
    list-style: none;
    padding: 0;
    text-align: left;
    margin: 10px auto 0 auto;

    li {
      list-style: none;
      margin: 15px 0;
    }
  }
}
