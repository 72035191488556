h1,
h2,
h3,
h4,
h5 {
  color: $color-title;
  font-weight: 700;
  text-align: center;
  line-height: 1.2;
}

h1 {
  font-size: 60px;

  @media #{$tablet} {
    font-size: 48px;
  }

  @media #{$mobile} {
    font-size: 34px;
  }
}

h2 {
  font-size: 40px;
  margin: 40px 0 20px;

  @media #{$mobile} {
    font-size: 32px;
  }

  &:first-child {
    margin-top: 0;
  }
}

h3 {
  font-size: 34px;
  margin: 30px 0 15px;

  &:first-child {
    margin-top: 0;
  }

  @media #{$mobile} {
    font-size: 26px;
  }
}

h4 {
  font-size: 19px;
  margin: 20px 0 10px;
}

h5 {
  font-size: 16px;
  color: $color-grey;
}

h6 {
  font-size: 14px;
  color: $color-blue;
  letter-spacing: 1px;
  text-transform: uppercase;
}

p {
  font-size: 16px;
  line-height: 22px;
  margin: 0 0 20px;
}


//visibility

.hide-for-tablet {
  @media #{$tablet} {
    display: none !important;
  }
}

.hide-for-mobile {
  @media #{$mobile} {
    display: none !important;
  }
}

.show-for-tablet {
  display: none !important;

  @media #{$tablet} {
    display: block !important;
  }
}

.show-for-mobile {
  display: none !important;

  @media #{$mobile} {
    display: block !important;
  }
}


*,
body {
  font-family: 'PT Sans', sans-serif;

  @media #{$mobile} {
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu;
  }
}

body {
  min-width: 768px;
  padding-top: 75px;

  &.modal-open {
    .header {
      right: 15px;
      width: calc(100% - 15px);
    }
  }

  &.open-tablet-nav {
    @media #{$tablet} {
      section:not(.tablet-nav) {
        filter: blur(3px);
      }
    }
  }

  &.open-tablet-nav {
    @media #{$tablet} {
      section:not(.tablet-nav) {
        filter: blur(3px);
      }
    }
  }

  &.cke_editable {
    padding: 50px 90px !important;
  }

  @media #{$mobile} {
    min-width: auto;
  }
}

.body-grey-bg {
  background: $color-border;
}

.body-blue-bg {
  background: $color-sky-blue;
}

.main-container {
  min-height: calc(100vh - 88px - 518px);

  @media #{$tablet} {
    min-height: calc(100vh - 88px - 534px);
  }
}

a {
  color: $color-link;

  &:hover {
    text-decoration: none;
  }
}

a,
button {

  &:focus,
  &:hover,
  &:active {
    outline: none;
  }
}

.hr {
  max-width: 860px;
  margin: 35px auto;
  height: 1px;

  &-blue {
    background-color: #A6C5E6;
  }
}

.hide {
  display: none !important;
}

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  padding-left: 20px;
  padding-right: 20px;

  &.container-small {
    max-width: 860px !important;
  }
}

.container-inside {
  padding: 0 60px;

  @media #{$tablet} {
    padding: 0;
  }
}

.flex-container {
  display: flex;

  &.vertical-align-top {
    align-items: flex-start;
  }

  &.vertical-align-center {
    align-items: center;
  }

  &.vertical-align-baseline {
    align-items: baseline;
  }
}

// Margin

.margin-left-10 {
  margin-left: 10px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.cf {

  &::before,
  &::after {
    content: '';
    display: table;
  }

  &::after {
    clear: both;
  }
}

.hidden {
  opacity: 0;
}

.visible {
  opacity: 1;
}

.overflowed {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.relative {
  position: relative;
}

img {
  display: block;
  height: auto;
  margin: 0 auto;
  max-width: 100%;

  .image-width-400 {
    max-width: 400px;

    @media #{$mobile} {
      max-width: 100%;
    }
  }
}

.image-margin-auto {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
}

.white-space-nowrap {
  white-space: nowrap;
}

.text-transform-uppercase {
  text-transform: uppercase;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

// Font Styles
.bold {
  font-weight: 700;
}

// Loader

.loader {
  &::after {
    background: $color-bg-loader url('@/static/styles/images/icons/default/loader.gif') no-repeat 50% 50%;
    background-size: 50px;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 11;
  }
}

// Colors

.color {
  &-blue {
    color: $color-blue;
  }

  &-light-grey {
    color: $color-light-grey;
  }

  &-orange {
    color: $color-orange;
  }

  &-main {
    color: $color-title;
  }

  &-white {
    color: white;
  }
}



// Title

.static-title {
  color: $color-title;
  font-size: 34px;
  line-height: 40px;
  font-weight: 700;
  margin: 0 auto;

  &-sub {
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: $color-blue-navi;
  }
}

.static-title--secondary {
  color: $color-text-dark;
  font-size: 25px !important;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: 1.2 !important;
  text-align: center;
  margin: 0;

  &.color-white {
    color: white;
  }

  @media #{$mobile} {

    &.text-left {
      text-align: center !important;
    }
  }

}

.page-title {
  color: $color-title;
  font-size: 34px;
  font-weight: 700;
  line-height: 1.2;
  margin: 35px 0 20px;
  text-align: center;

  @media #{$mobile} {
    text-align: center !important;
  }
}

.page-description {
  color: $color-grey;
  font-size: 17px;
  letter-spacing: .3px;
  line-height: 1.3;
  margin: 0 auto 35px;
  max-width: 600px;
  text-align: center;
}

// Font Size

.font-size {
  &-14 {
    font-size: 14px !important;
  }

  &-19 {
    font-size: 19px !important;
  }

  &-20 {
    font-size: 20px !important;
  }

  &-24 {
    font-size: 24px !important;
  }

  &-34 {
    font-size: 34px !important;
  }
}

.text-align-left,
.text-left {
  text-align: left !important;
}

.text-align-right,
.text-right {
  text-align: right !important;
}

.text-align-center,
.text-center {
  text-align: center !important;
}

// Blocks

.display-inline-block {
  display: inline-block;
}

// Borders

.border-semicircle-white {
  position: relative;

  &::after {
    background: url('@/static/images/pages/services/pricing/icon-services-tabs-border.svg') repeat-x 0 0;
    content: '';
    height: 8px;
    left: 0;
    position: absolute;
    right: 0;
  }

  &.border-bottom {
    &::after {
      bottom: -8px;
    }
  }

  &.border-top {
    &::after {
      background-position: 8px 0;
      top: -8px;
      transform: rotate(180deg);
    }
  }
}

// Backgrounds

.opacity-05 {
  opacity: .5;
}

.bg-lightest-grey {
  background: $color-lightest-grey;
}

.bg-blue {
  background: $color-blue;
}

.validate-msg,
.form-control-feedback.error {
  color: #ff0000;
  font-size: 12px;
  list-style-type: none;
}

//Flexbox & Grid
.flex {
  display: flex;
  flex-flow: row wrap;

  @for $i from 0 through 3 {
    &-#{$i} {
      flex: #{$i};
    }
  }

  &-column {
    -moz-flex-flow: column;
    flex-flow: column;
  }

  &-content-between {
    justify-content: space-between;
  }

  &-center {
    align-items: center;
  }
}

.full-width {
  width: 100%;
}

input[type="number"] {
  appearance: textfield;

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    opacity: 0;
  }

  &::-ms-clear {
    appearance: none;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

// Search result page
@media only screen and (max-width: 480px) {
  #siq_search_results .search-results-R .sr-R-cont,
  #siq_search_results .search-results-R .search-results-title,
  #siq_search_results .search-results-R .srch-res-info {
    width: 220px !important;
  }

  #siq_search_results .srch-det-arrow {
    right: -10% !important;
  }
}

@media only screen and (max-width: 400px) {
  #siq_search_results .search-results-R .sr-R-cont,
  #siq_search_results .search-results-R .search-results-title,
  #siq_search_results .search-results-R .srch-res-info {
    width: 180px !important;
  }

  #siq_search_results .search-results-R div.sr-R-link {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.registration__radio-buttons-wrapper {
  align-items: center;
  color: $color-grey;
  display: flex;
  font-size: 17px;
  height: 56px;
  line-height: 20px;
  margin-right: 20px;

  &.selected-radio-button {
    width: calc(50% - 10px);
  }

  +.form-group__styled {
    margin: 0;
  }
}

.registration__radio-buttons {
  align-items: center;
  display: flex;
  margin: 0 0 0 20px;
}


.registration__input-radio-wrapper {
  margin-right: 20px;
  position: relative;

  &:last-child {
    margin-right: 0;
  }

  .form-control-feedback {
    right: inherit;
    left: 0;
    padding-left: 0;
  }
}

.registration__input-radio {
  float: left;
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 0;

  +.registration__input-radio-label {
    color: $color-title;
    cursor: pointer;
    float: left;
    font-size: 17px;
    line-height: 20px;
    margin: 0;
    padding: 0 0 0 29px;
    position: relative;
    text-align: left;
    white-space: nowrap;

    &::before {
      border: 2px solid #b2b5b9;
      border-radius: 4px;
      content: '';
      height: 20px;
      left: 0;
      position: absolute;
      top: 0;
      width: 20px;
    }

    .form-group__styled-with-error & {
      &:before {
        border-color: $color-orange;
      }
    }
  }

  &:checked {
    +.registration__input-radio-label {
      &::before {
        border-color: $color-blue;
      }

      &::after {
        background: $color-blue;
        border-radius: 3px;
        content: '';
        height: 12px;
        left: 4px;
        position: absolute;
        top: 4px;
        width: 12px;
      }
    }
  }
}
