$notify-box-shadow-color: #00080E;

$notify-info-bg-color: #eaeffa;
$notify-info-border-color: #bdccef;

$notify-error-bg-color: #faeef0;
$notify-error-border-color: #ffaba0;
$notify-error-icon-color: #d0021b;

$notify-alert-bg-color: #fff9e5;
$notify-alert-border-color: #fcebaf;
$notify-alert-icon-color: #ff8c00;

$notify-success-bg-color: #e0ffd6;
$notify-success-border-color: #bbda98;
$notify-success-icon-color: #53a128;

$notify-warning-bg-color: #ffffff;
$notify-warning-border-color: #f9630e;
$notify-warning-icon-color: #f9630e;

$color-text: #404040;

.tfx-notify {
  width: 300px;

  .ui-pnotify-icon {
    width: 17px;
    height: 17px;
    min-width: 17px;
  }

  &-icon {
    width: 17px;
    height: 17px;
    min-width: 17px;
  }

  .ui-pnotify {
    &-container {
      width: 100% !important;
      box-shadow: 0 6px 14px -3px rgba($notify-box-shadow-color, 0.17);
      border: 1px solid;
      border-radius: 4px;
      padding: 20px;
      position: relative;
    }

    &-closer {
      position: absolute;
      top: -5px;
      right: -5px;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
      outline: none;
      background: url('~tfx-common/styles/icons/icon-close-rounded.svg') no-repeat 50% 50% / 25px;
      transition: all .3s ease-in-out;
      cursor: pointer;
    }

    &-title {
      text-align: left;
      color: $color-text;
      font-size: 16px;
      font-weight: bold;
    }

    &-text {
      font-size: 14px;
      color: $color-text;
    }
  }

  &-info {
    .ui-pnotify-container {
      background-color: $notify-info-bg-color;
      border-color: $notify-info-border-color;
    }

    .icon-info {
      width: 17px;
      height: 17px;
      background-size: cover;
    }
  }

  &-error {
    .ui-pnotify-container {
      background-color: $notify-error-bg-color;
      border-color: $notify-error-border-color;
    }

    .icon-error {
      background: none;

      &__bg {
        fill: $notify-error-icon-color;
      }

      &__sign {
        fill: #fff;
      }
    }
  }

  &-alert {
    .ui-pnotify-container {
      background-color: $notify-alert-bg-color;
      border-color: $notify-alert-border-color;
    }

    .icon-error {
      background: none;

      &__bg {
        fill: $notify-alert-icon-color;
      }

      &__sign {
        fill: #fff;
      }
    }

    &-dynamic {
      .tfx-notify-icon {
        width: 25px;
        height: 25px;
      }

      .ui-pnotify-title {
        margin-top: 4px;
        margin-left: 34px;
        margin-bottom: 0;
      }
    }
  }

  &-success {
    .ui-pnotify-container {
      background-color: $notify-success-bg-color;
      border-color: $notify-success-border-color;
    }

    .tfx-notify-icon {
      background: $notify-success-icon-color;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 20px;
        height: 20px;
        display: inline-block;
      }

      .icon-check-mark-path {
        fill: #fff;
      }
    }
  }

  &-warning {
    .ui-pnotify-container {
      background-color: $notify-warning-bg-color;
      border-color: $notify-warning-border-color;
    }

    .tfx-notify-icon {
      background: $notify-warning-icon-color;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: inline-block;

      svg {
        width: 20px;
        height: 20px;
        display: inline-block;
      }

      .icon-check-mark-path {
        fill: #fff;
      }
    }
  }
}
