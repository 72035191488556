.badge {
  height: 18px;
  font-size: 12px;
  line-height: 12px;
  padding: 3px 10px;
  border-radius: 10px;
  text-transform: uppercase;
}

.badge-primary {
  background: $color-blue;
}
