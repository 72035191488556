.goals {
  padding: 25px 0;
  background: #0A315A;
  background-image: url('@/static/images/pages/index/goals/layer-1.svg'),  url('@/static/images/pages/index/goals/layer-2.svg'),  url('@/static/images/pages/index/goals/layer-3.svg');
  background-repeat: no-repeat;
  background-position: center;

  @media #{$sm} {
    padding: 30px 0;
  }

  &_inner {
    display: flex;
    justify-content: space-between;

    &:before,
    &:after {
      display: none;
    }

    @media #{$mobile} {
      display: block;
    }
  }

  &--item {
    padding: 15px;
    font-size: 70px;
    font-weight: bold;
    letter-spacing: -.18px;
    color: $color-white;
    line-height: 1;

    @media #{$mobile} {
      text-align: left;
    }

    &_title {
      opacity: .6;
      font-size: 22px;
      letter-spacing: -.06px;
    }
  }
}
