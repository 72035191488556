.no-bold {
  font-weight: normal !important;
}

%searh-input {
  border-radius: 4px;
  background-color: #F9FAFA;
  border: 1px solid #D8DADE;
  display: block;
  background-image: url('~tfx-common/styles/icons/default/ic-search-light.svg');
  background-position: left 12px center;
  background-repeat: no-repeat;
}

%empty-section {
  align-items: center;
  color: #89919B;
  display: flex;
  flex: 1;
  font-size: 15px;
  font-weight: normal;
  line-height: 18px;
  justify-content: center;
  margin: 0;
  padding: 0;
  height: calc(100% - 20px);
}

.messages-center,
.tickets-center {
  background-color: white;
  height: calc(100vh - 220px);
  border-radius: .4rem;
  font-size: 15px;
  line-height: 18px;
  color: #3B4858;

  #messages {
    height: 100%;
  }

  &-header {
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid $br-color-light-gray;
    padding: 16px;
  }

  &-select-area {
    width: 160px;
    position: relative;
  }

  &-select {
    padding: 10px 38px 10px 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 15px;
    line-height: 18px;
    border: 0;
    color: $bd-color-blue-curious;

    &:after {
      border-style: solid;
      border-color: $bd-color-blue-curious;
      border-width: 1px 1px 0 0;
      content: '';
      display: inline-block;
      height: 7px;
      right: 20px;
      position: absolute;
      top: 15px;
      transform: rotate(135deg);
      vertical-align: top;
      width: 7px;
    }

    &:hover {
      border: inherit;
      cursor: pointer;
    }

    &-text {
      display: inline-block;
      white-space: nowrap;
      border-bottom: 1px solid rgba(0, 0, 0, 0.16);
    }

    &.active {
      color: $bd-color-btn-hvr;

      &:after {
        border-color: $bd-color-btn-hvr;
        transform: rotate(-45deg);
        top: 16px;
      }

      .messages-center-select-text {
        border: 0;
      }
    }

    &-option {
      padding: 7px 12px;
      display: flex;
      align-items: center;
      transition: all .3s ease;
      font-size: 15px;
      line-height: 18px;

      &:hover {
        background-color: $bd-color-blue-curious;
        color: white;
        cursor: pointer;
      }

      &.active {
        color: $bd-color-dark-text;
        background: #E2E4E6;
        font-weight: bold;

        &:hover {
          cursor: default;
          background-color: transparent;
        }
      }
    }
  }

  &-select-wrapper {
    margin-top: 3px;
    border-radius: 0 0 4px 4px;
    position: absolute;
    background-color: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12);
    width: 100%;
    z-index: 1;
    right: 16px;
  }

  &_move-inbox {
    box-shadow: 0 4px 20px rgba(191, 191, 191, .4);
    padding: 12px 15px;
    background-color: $bd-color-white;
    height: 44px;
    display: flex;
    position: absolute;
    align-items: center;
    left: 0;
    top: 0;
    width: 100%;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    color: $bd-color-blue-curious;
    z-index: 1;
    border-radius: 4px;

    &-img {
      line-height: 0;
      margin-right: 10px;

      svg {
        path {
          fill: $bd-color-blue-curious;
        }
      }
    }

    &-text {
      cursor: pointer;
      width: calc(100% - 100px);
    }

    &-close {
      top: 10px;
      right: 15px;
      position: absolute;
      opacity: .5;
      cursor: pointer;
      width: 14px;
      height: 16px;

      svg {
        width: inherit;
        height: inherit;
      }
    }
  }

  &-select-all {
    padding: 16px;
    background-color: $bd-color-white;
    cursor: pointer;
    display: flex;
    align-items: center;
    white-space: nowrap;

    .checkbox-label {
      margin-right: 10px;
    }
  }

  &-search-wrapper {
    @extend %searh-input;

    width: calc(100% - 32px);
    padding: 0;
    margin: 16px;
  }

  &-search {
    &-form {
      position: relative;
    }

    &-input {
      border: 0;
      box-shadow: none;
      padding-left: 36px;
      padding-right: 0;
      font-size: 15px;
      color: $bd-color-slate-darker;

      &:focus {
        border: 0;
        box-shadow: none;
      }
    }

    &-button {
      border: 0;
      position: absolute;
      background: transparent;
      padding: 10px;
      top: 0;
      right: 0;
      cursor: pointer;

      .messages-center-icon-search {
        margin: 0;
      }
    }
  }

  &-actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: bold;
    white-space: nowrap;

    .messages-center-icon-important {
      margin-right: 5px;
      line-height: 0;

      &.noactive {
        stroke-width: 0;
      }

      path {
        stroke: $bd-color-blue-curious;
      }
    }

    .button_link {
      text-decoration: none;
      font-size: 15px;
      line-height: 18px;
      height: 18px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);

      &:hover {
        color: $bd-color-btn-hvr;
        border-color: $bd-color-btn-hvr;

        +svg {
          path {
            stroke: $bd-color-btn-hvr;
          }
        }
      }
    }
  }

  &-item {
    &-wrapper {
      width: 0;
      flex: 1;
      height: calc(100% - 7px);

      &-empty {
        @extend %empty-section;

        width: 100%;
      }
    }

    &-view {
      display: flex;
      flex: 1;
      flex-direction: column;
      overflow-y: hidden;
      height: 100%;

      &-user {
        &-avatar {
          $measurements: 36px;
          border-radius: 100%;
          height: $measurements;
          margin-right: 8px;
          min-width: $measurements;
          width: $measurements;
          margin-bottom: 12px;

          &-wrapper {
            $measurements: 100%;
            align-items: center;
            display: inline-flex;
            height: $measurements;
            justify-content: center;
            width: $measurements;
          }

          &-icon {
            background: none;
            height: $measurements;
            width: $measurements;
            border-radius: 4px;

            >path {
              fill: transparent;
            }
          }

          &-img {
            $measurements: 100%;
            height: $measurements;
            min-width: $measurements;
            width: $measurements;
            object-fit: cover;
            border-radius: 4px;
          }
        }
      }

      &-content {
        height: auto;
        min-height: 120px;

        .attachments {
          .attachment-desc {
            width: calc(100% - 154px);
          }
        }
      }

      &-body {
        height: 100%;
        position: relative;

        .attachments {
          margin: 0;
          width: auto;
        }

        .attachments-wrapper {
          position: absolute;
          bottom: 10px;
          background-color: $bd-color-white;
          border-top: 1px solid $bd-color-grey-border;
          padding: 16px 0;
          margin: 0 16px;
          width: calc(100% - 32px);

          .attachments-buttons {
            position: absolute;
            right: 0;
            top: 0;
            left: auto;
            text-align: right;
            width: auto;
            display: flex;
            align-items: flex-end;

            .font-bold {
              display: inline-block;
              background-image: url('~tfx-common/styles/icons/default/icon-download.svg');
              background-repeat: no-repeat;
              background-position: left center;
              padding-left: 22px;
              font-weight: normal;
              text-decoration: none;

              >span {
                border-bottom: 1px dashed;
                white-space: nowrap;
                display: inline-block;
              }
            }
          }

          h4 {
            font-weight: bold;
          }

          @media #{$mobile} {
            padding: 0;
          }
        }
      }
    }

    &-overflow {
      height: calc(100% - 49px);
    }
  }


  // TODO: remove when buttons will be changed
  .button:not(.button_smallest) {
    color: $bd-color-blue-curious;

    &:hover:not(.button_disabled) {
      background: none;
      color: $bd-color-btn-hvr;

      .messages-center-icon-reply {
        fill: $bd-color-btn-hvr;
      }
    }

    &.button_disabled {
      background-color: #F1F1F1;
      color: $bd-color-grey-darken;
      border-color: #F1F1F1;

      &:hover {
        color: $bd-color-grey-darken;
        cursor: not-allowed;
      }
    }

    &.button_white_disabled {
      border-color: $bd-color-grey-alto;
      color: $bd-color-grey-darken;
      cursor: not-allowed;

      &:hover {
        color: $bd-color-grey-darken;
      }
    }

    &.messages-center-new {
      color: $bd-color-white;
      font-weight: bold;
      line-height: 0;
      height: 32px;

      &:hover {
        background-color: $bd-color-btn-hvr;
        color: $bd-color-white;
      }
    }

    &.button_blue:not(.button_disabled) {
      color: $bd-color-white;

      &:hover {
        background-color: $bd-color-btn-hvr;
        color: $bd-color-white;
      }
    }

    &.button_dark-link {
      color: $bd-color-black-text;
    }
  }

  &-new {
    padding: 7px 22px;

    &.button {
      margin-left: 10px;
    }
  }

  &-icon {
    color: $bd-color-white;
    height: 22px;
    width: 22px;

    &-add {
      width: inherit;
      height: inherit;
      background: none;
      fill: $bd-color-white;
      @include transition(all, ease-in-out, .3s);

    }

    &-archive,
    &-search,
    &-reply {
      width: 20px;
      height: 20px;
      margin: 0 5px 0 0;
    }

    &-reply {
      fill: white;
    }

    &-important {
      width: 20px;
      height: 20px;
      margin: 0;

      #change-star-color {
        fill: $bd-color-slate-darker;
      }
    }

    &-document-add {
      background: none;

      &-wrapper {
        $measurements: 20px;

        height: $measurements;
        margin-right: 6px;
        width: $measurements;
      }

      fill: $bd-color-white;
    }

    &:hover {
      color: $bd-color-white;

      .messages-center-icon-reply {
        fill: $bd-color-white;
      }
    }
  }

  &__wrapper {
    .tfx-tabs {
      margin: 10px 0 6px;
      border-top: 1px solid $bd-color-white-middle;
      padding-top: 10px;
      overflow: inherit;

      &-flat {
        .tab {
          margin: 0 5px 0 0;
          padding: 10px 5px;
          width: 100%;
          text-align: center;
          max-width: 32%;
          height: 35px;
          @extend %ellipsis;

          &:last-of-type {
            margin: 0;
          }
        }
      }
    }
  }

  &-list {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    max-width: 376px;
    height: 100%;
    overflow-y: auto;

    @media #{$mobile} {
      border: 0;
    }

    &-empty {
      @extend %empty-section;
      border: 0;

      &-inner {
        padding-left: 15px;
        padding-right: 15px;
      }

      &-search {
        align-items: flex-start;
        justify-content: flex-start;

        @media #{$mobile} {
          margin-top: 10px;
        }
      }
    }
  }

  &-delivery-state {
    margin-left: 5px;
    line-height: 0;

    &_not-sent {
      color: #D0021B;
      display: inline-block;
      border-bottom: 1px dashed #D0021B;
    }
  }

  &-back {
    @media (min-width: 1024px) {
      display: none;
    }
  }
}

//Unclosed tickets
.tickets-center {
  .pane {
    background-color: white;
    border-radius: 0;
    box-shadow: none;
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    line-height: inherit;
    color: inherit;
    border: 0;

    >div:not(.filter) {
      width: 100%;
    }
  }

  .messages-center-search-wrapper {
    border: 0;
    margin: 15px 16px;

    .messages-center-search {
      .input {
        height: 32px;
      }
    }
  }

  &-filter {
    flex-flow: row-reverse !important;

    .button_simple-small {
      padding-right: 12px;
      padding-left: 12px;
      height: 32px;
    }
  }

  .messages-center-search {
    @extend %searh-input;
    margin: 0 0 0 8px;
    width: 100%;
  }
}


// User info block
.userinfo {
  width: 100%;
  position: relative;

  &-item {
    display: flex;
    font-size: 14px;
    margin: 0 0 2px;
    line-height: 20px;

    &-title {
      color: $bd-color-grey-darken;
      white-space: nowrap;
    }

    &-description {
      color: $bd-color-dark-text;

      &-subject {
        display: flex;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 0;
        line-height: 1;
        align-items: flex-start;
      }

      &-wrap {
        display: inline-block;
        width: 100%;

        @media #{$mobile} {
          white-space: nowrap;
        }
      }
    }

    &-recipients {
      width: 100%;

      @media #{$mobile} {
        width: auto;
      }

      &-name {
        @extend %ellipsis;
        max-width: 87%;
        display: inline-block;
        float: left;
      }
    }

    &-from {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
      max-width: calc(100% - 160px);
      color: $bd-color-dark-text;
      line-height: 1.1;
      align-items: center;
      display: flex;

      @media #{$mobile} {
        max-width: 200px;
      }
    }

    &-more {
      color: $bd-color-blue-curious;
      margin-left: 5px;
      font-weight: normal;
      cursor: pointer;
      white-space: nowrap;
    }

    &-date {
      text-align: right;
      white-space: nowrap;
    }

    &-right-part {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .messages-center-delivery-state {
        margin-top: 3px;
        color: $bd-color-grey-darken;
        line-height: 1;
      }
    }

    &-dropdown {
      transform: rotate(90deg);
      display: inline-block;
      font-size: 10px;
      cursor: pointer;
    }

    &-archive {
      margin-right: 7px;

      svg {
        width: 16px;
        height: 16px;

        path {
          fill: #6772E5;
        }
      }
    }
  }
}

// Client Side modifications
.client-message-center-head {
  display: flex;
  justify-content: space-between;
}

.page-content {
  &.page-content--messages {
    margin: 10px 0 0;
    height: calc(100vh - 210px);
    border: 0;
    padding: 0;

    @media #{$mobile} {
      width: 100%;
      position: static;
      margin: 10px 0 0;
      height: calc(100vh - 172px);
      height: calc(100dvh - 172px);
    }

    .tfx-ckeditor-wrapper .cke_top {
      margin: 0;
      min-height: 30px;

      @media #{$mobile} {
        display: none;
      }
    }

    .client-side-messages {
      height: 100%;

      .show-on-mobile {
        display: none;
      }

      @media #{$mobile} {
        border-top: 1px solid $bd-color-grey-alto;

        .hide-on-mobile {
          display: none;
        }
      }

      .messages-center {
        &-select-area {
          margin-right: 20px;
          width: 100%;
          flex: 1;
          position: relative;
        }

        &-select {
          border: 1px solid $bd-color-blue-curious;
          padding: 10px 12px;
          display: flex;
          justify-content: flex-start;
          border-radius: 4px;
          align-items: center;
          font-weight: bold;
          font-size: 15px;
          line-height: 20px;
          color: $bd-color-dark-text;

          &-text {
            border: 0;
          }

          &:after {
            border-style: solid;
            border-color: $bd-color-dark-text;
            border-width: 2px 2px 0 0;
            content: '';
            display: inline-block;
            height: 7px;
            right: 20px;
            position: absolute;
            top: 15px;
            transform: rotate(135deg);
            vertical-align: top;
            width: 7px;
          }

          &.active {
            background: $bd-color-light-blue;

            &:after {
              border-color: $bd-color-blue-curious;
              transform: rotate(-45deg);
              top: 18px;
            }
          }

          &-option {
            padding: 10px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            transition: all .3s ease;
            color: $bd-color-blue-curious;
            font-weight: bold;
            font-size: 15px;
            line-height: 18px;

            &-img {
              margin-right: 10px;
              display: flex;
              align-items: center;

              .messages-center-icon-important,
              .icon-important {
                margin: 0 !important;
              }
            }

            svg {
              path {
                fill: $bd-color-blue-curious;
              }
            }

            &:hover {
              background-color: #F4F4F4;
              color: $bd-color-blue-curious;
              cursor: pointer;

              svg {
                margin: 0 !important;

                path {
                  fill: $bd-color-blue-curious;
                }
              }
            }

            &.active {
              color: $bd-color-dark-text;

              svg {
                path {
                  fill: $bd-color-dark-text;
                }
              }

              &:hover {
                cursor: default;
                background-color: transparent;
              }
            }
          }
        }

        &-select-wrapper {
          margin-top: 3px;
          border-radius: 4px;
          position: absolute;
          padding: 2px 4px;
          right: unset;
          background-color: $bd-color-white;
          z-index: 11;
        }

        &-search-wrapper {
          width: 100%;
          margin: 0;
          display: block;
          background-color: $bd-color-white;
          position: relative;
          border-left: none;
          border-right: none;
          border-radius: 0;

          .input {
            border: 0;
            border-radius: 4px;
          }

          @media #{$mobile} {
            max-width: 100%;
            padding: 0;
          }
        }

        &-header {
          padding: 0;

          @media #{$mobile} {
            position: fixed;
            bottom: 0;
            width: calc(100% - 30px);
            padding: 13px 0 15px;
            margin: 0;
            background-color: $bd-color-white;
            z-index: 11;
          }
        }

        &-search {
          &-input {
            padding-left: 40px;
            background-color: $bd-color-white;
            background-image: url('~tfx-common/styles/icons/default/ic-search-light.svg');
            background-position: left 14px center;
            background-repeat: no-repeat;
            border-radius: 0;
            max-height: 44px;
            min-height: 44px;
            border-right: 0;
            border-left: 0;

            @media #{$mobile} {
              border-radius: 0;
            }

            &:focus,
            &:hover {
              box-shadow: 0 4px 20px rgba(191, 191, 191, .4);
              border-left: 0;
              border-right: 0;
            }
          }
        }

        &-actions {
          flex-direction: row-reverse;

          @media #{$mobile} {
            flex-direction: row;
            justify-content: space-between;

            .button {
              width: 48%;
              margin: 0 !important;
            }
          }

          .button {
            background-color: $bd-color-white;
            color: $bd-color-blue-curious;
            font-weight: bold;
            padding: 5px 10px;
            font-size: 15px;
            min-width: auto;

            >svg {
              path {
                fill: $bd-color-blue-curious;
              }
            }

            &:hover {
              color: $bd-color-btn-hvr;

              .messages-center-icon-reply path {
                fill: $bd-color-btn-hvr;
              }
            }
          }
        }

        &-new {
          border: 0;
          padding: 0;

          //TODO: need to change buttons
          &.button {
            align-items: center;
            background-color: transparent;
            border: 1px solid transparent;
            border-radius: $border-radius;
            cursor: pointer;
            display: inline-flex;
            font-size: 14px;
            font-weight: normal;
            justify-content: center;
            line-height: unset;
            padding: 0;
            text-decoration: none;
            white-space: nowrap;
            margin: 0 10px 0 0;

            &_small {
              padding: 6px 12px;
            }

            &_blue {
              background-color: $bd-color-blue-curious;
              color: $bd-color-white;

              &:hover {
                background-color: $bd-color-btn-hvr;
              }
            }

            &_blue-border {
              background-color: $bd-color-white;
              border: 1px solid $bd-color-blue-curious;
              color: $bd-color-blue-curious;

              &-reply {
                fill: $bd-color-blue-curious;
              }

              &:hover {
                background: $bd-color-btn-hvr;
                border: 1px solid $bd-color-btn-hvr;
                color: $bd-color-white;

                .messages-center-icon-reply {
                  fill: $bd-color-white;
                }
              }
            }

            &_dashed-link {
              border-bottom: 1px dashed $bd-color-btn-hvr;
              color: $bd-color-btn-hvr;
              cursor: pointer;
              display: inline;
              font-size: 14px;
              padding-bottom: 1px;
              text-decoration: none;
              transition: all .4s ease;

              &:visited,
              &:hover {
                color: $bd-color-blue-curious;
                border-bottom: 1px dashed $bd-color-blue-curious;
              }
            }
          }
        }

        &-list {
          max-width: 345px;
          min-width: 345px;
          height: 100%;
          border: 0;

          @media #{$mobile} {
            max-width: 100%;
            height: 100%;
            max-height: 100%;
            min-width: unset;
            min-height: 259px;
            padding-bottom: 0;
          }
        }

        &-item {

          &-overflow {
            height: 100%;
          }

          &-view {
            overflow: hidden;
            border: 0;

            @media #{$mobile} {
              height: 100%;
            }

            &-user {
              &-avatar {
                $measurements: 40px;
                height: $measurements;
                min-width: $measurements;
                width: $measurements;
                border: 0;

                &-img {
                  $measurements: 100%;
                  height: $measurements;
                  min-width: $measurements;
                  width: $measurements;
                  object-fit: cover;
                  border-radius: 100%;
                }

                @media #{$mobile} {
                  $measurements: 40px;
                  height: $measurements;
                  min-width: $measurements;
                  width: $measurements;
                }
              }
            }

            &-body {
              border-top: 1px solid $bd-color-grey-border;
              padding-bottom: 10px;

              @media #{$mobile} {
                padding: 0;
              }

              .attachments {
                margin: 0;
              }

              .attachments-wrapper {
                .attachments-buttons {
                  position: relative;
                  top: 0;
                  right: unset;
                  left: 0;
                  text-align: left;
                  justify-content: space-between;
                  padding-right: 5px;

                  .font-bold {
                    font-weight: bold;
                    background: none;
                    padding-left: 0;

                    >span {
                      border: 0;
                    }
                  }
                }
              }

              &.has-attachment {
                overflow: auto;
              }
            }

            &-subject {
              display: none;

              @media #{$mobile} {
                display: block;
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 10px;
              }
            }
          }



          &-wrapper {
            height: initial;

            @media #{$mobile} {
              display: none;
            }

            &-empty {
              height: calc(100% - 20px);
            }

            &.opened-block {
              @media #{$mobile} {
                display: block;
                background: #fff;
                padding: 15px;
                margin-left: 0;
                z-index: 1;
              }
            }
          }
        }

        &-back {
          display: none;
          position: absolute;
          top: -98px;
          right: 0;
          left: 0;
          z-index: 10;
          height: 68px;
          border-bottom: 1px solid #e7e7e7;
          background: #fff;
          padding: 20px 15px;

          @media #{$mobile} {
            display: flex;
            justify-content: space-between;
            top: 0;

            .messages-center-new {
              display: none;
            }

            .messages-center-actions {
              justify-content: flex-end;
              margin-bottom: 0;

              .button {
                width: auto;
              }
            }
          }

          &-button {
            position: relative;
            padding: 0 0 0 38px;
            font-size: 18px;
            font-weight: 700;
            color: #1f4159;
            line-height: 28px;
            display: block;

            &:before {
              position: absolute;
              content: '';
              top: 0;
              left: 0;
              width: 28px;
              height: 28px;
              background: url('~tfx-common/styles/icons/default/icon-back.svg') no-repeat 50% 50%;
              transform: rotate(180deg);
            }
          }
        }

        &-mobile-new {
          display: none;
          position: fixed;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 1;
          padding: 15px;
          border-top: 1px solid $bd-color-grey-border;
          background: $bd-color-white;

          button {
            width: 100%;
          }

          @media #{$mobile} {
            display: block;
          }
        }

        //client side multiselect

        .multiselect {
          &__tags {
            align-items: flex-start;

            &-wrap {
              display: flex;
              width: 100%;
            }
          }

          &__tag {
            margin: 7px 5px 0 0;
            background: $bd-color-blue-curious;

            &-icon {
              &::after {
                color: $bd-color-white;
              }

              &:hover {
                background: darken($bd-color-blue-curious, 10%);
              }
            }
          }

          &__content-wrapper {
            top: 100%;
          }
        }
      }

      .userinfo {
        &-item {
          @media #{$mobile} {
            margin: 3px 0;
          }

          &-description {
            color: $bd-color-dark-text;

            @media #{$mobile} {
              font-size: 13px;
            }

            &-subject {
              margin-top: 0;
              margin-bottom: 10px;
            }
          }

          &-title {
            @media #{$mobile} {
              margin-right: 5px;
              font-size: 13px;
            }
          }

          &-right-part {
            right: 15px;

            @media #{$mobile} {
              right: 10px;

              .messages-center-reply {
                padding: 6px;
              }

              .show-on-mobile {
                @media #{$mobile} {
                  display: block;
                }
              }
            }
          }

          &-date {
            @media #{$mobile} {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              text-align: left;
              max-width: calc(100vw - 160px);
            }
          }

          &.show-on-mobile {
            @media #{$mobile} {
              display: flex;
              width: 100%;
              font-size: 12px;

              .userinfo-item-date {
                display: block;
                width: 100%;
                text-align: left;
                font-size: 12px;
              }
            }
          }

          &-from {
            color: $bd-color-dark-text;
            max-width: 360px;

            @media #{$mobile} {
              margin-right: 5px;
              white-space: normal;
              font-size: 13px;
            }

            @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
              min-width: 360px;
            }
          }
        }
      }

      .message-btn-blue-border {
        padding: 10px 25px;
        border-radius: 4px;
        border: 1px solid $bd-color-blue-curious;
        font-size: 15px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: $bd-color-blue-curious;
        cursor: pointer;
        height: 42px;

        svg {
          margin: 0;
          fill: $bd-color-blue-curious;

          path {
            fill: $bd-color-blue-curious;
          }
        }

        &:hover {
          color: $bd-color-white;
          background: $bd-color-blue-curious;

          svg {
            fill: $bd-color-white;

            path {
              fill: $bd-color-white;
            }
          }
        }


        &[disabled] {
          cursor: not-allowed;
          color: #6ac6ff;
          border: 1px solid #6ac6ff;

          svg {
            fill: #6ac6ff;

            path {
              fill: #6ac6ff;
            }
          }

          &:hover {
            background: none;
            box-shadow: none;

            svg {
              fill: #6ac6ff;

              path {
                fill: #6ac6ff;
              }
            }
          }
        }
      }

      .message-btn-blue {
        height: 42px;
        background: $bd-color-blue-curious;
        border-radius: 4px;
        color: $bd-color-white;
        font-size: 15px;
        font-weight: 700;
        padding: 7px 12px;
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        border: 0;

        &.btn-send {
          padding: 10px 35px;
        }

        &:hover {
          background: darken($bd-color-blue-curious, 10%);
        }

        svg {
          margin: 0 5px 0 0;
          fill: $bd-color-white;
        }
      }

      .ps__rail-x,
      .ps__rail-y {
        display: none;
      }
    }
  }
}


// attachments
.attachments {
  align-self: flex-end;
  display: flex;
  flex-flow: row wrap;
  margin: 0;
  width: 100%;
  padding-top: 10px;

  @media #{$mobile} {
    width: 100%;
  }

  &-wrapper {
    width: 100%;
    overflow: visible;
    border: 0;
    margin: 0;
    padding: 0 15px;
  }

  &-buttons {
    margin: 0;
    width: calc(100% - 30px);

    @media #{$mobile} {
      padding-top: 10px;
    }

    .button {
      margin-right: 15px !important;
      min-height: auto;
      color: $bd-color-slate-darker;
      border-bottom: 1px dashed $bd-color-slate-darker;
      font-weight: 400;
      font-size: 14px;
      padding: 1px 0;
      border-radius: 0;

      @media #{$mobile} {
        min-height: auto;
        color: $bd-color-slate-darker;
        border-bottom: 1px dashed $bd-color-slate-darker;
        margin: 0 20px 0 0;
        font-weight: 400;
        font-size: 14px;
        padding: 1px 0;
        border-radius: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.tooltip.message-details-tooltip {
  border: 1px solid #EBEBEB;
  background: #fff;

  .message-details-tooltip__subtitle {
    color: #7E7D7F;
    font-weight: bold;
  }

  .tooltip-arrow {
    display: none !important;
  }

  .tooltip-content {
    color: #303030;
    max-width: 85vw;
    white-space: normal;
    word-break: break-word;
  }
}

//Client resets
.file-preview_name {
  flex: 1;
  margin-left: 10px;

  @extend %ellipsis;
}

//Scrollbar resets
.ps {
  &__rail-y {
    border-right: 0;
  }

  &__thumb-y {
    right: 3px;
  }
}