$styled-block-info-blue-box-shadow-color: rgba(0, 0, 0, .2);

@mixin styled-block-title($color-title) {
  position: relative;

  .styled-block-title {
    bottom: 100%;
    color: $color-title;
    font-size: 14px;
    font-weight: 700;
    left: 60px;
    letter-spacing: .3px;
    line-height: 1;
    padding: 0 0 5px;
    position: absolute;
    text-transform: uppercase;

    @media #{$md} {
      left: 20px;
    }
  }

  .styled-block-title-transparent {
    bottom: 100%;
    color: $color-orange;
    font-size: 14px;
    font-weight: 700;
    left: -20px;
    letter-spacing: .3px;
    line-height: 1;
    padding: 7px 20px 5px;
    position: absolute;
    text-transform: uppercase;

    @media #{$mobile} {
      left: 0;
      text-align: center;
      padding: 10px 15px;
      width: 100%;
    }
  }
}

@mixin styled-block-checklist($color, $icon-name) {
  position: relative;

  ul {
    border: 0;
    font-size: 16px;

    li {
      &::before {
        background: url('@/static/images/icons/articles/icon-check-#{$icon-name}.svg') no-repeat 50% 50%;
        height: 15px;
        top: 5px;
        width: 16px;

        @media #{$mobile} {
          left: -28px;
        }
      }
    }
  }
}

//CTA blocks
@mixin styled-cta-block ($bg-color, $img-name, $btn-color) {
  position: relative;
  padding: 52px 350px 52px 40px;
  border-radius: 8px;
  margin: 25px 0 34px;
  overflow: hidden;
  min-height: 150px;
  background: $bg-color url('@/static/styles/images/pages/articles/cta-block-#{$img-name}.svg') no-repeat center right 60px;

  p {
    font-weight: 700;
    font-size: 30px;
    line-height: 1.5;
    color: white;
    margin: 0 0 30px;
    text-align: left;
  }

  .btn,
  a.btn {
    width: 100%;
    max-width: 280px;
    color: $btn-color;
    font-size: 18px;

    &:hover {
      background-color: white;
      text-decoration: none;
      opacity: 0.85;
    }
  }

  @media #{$mobile} {
    padding: 55px 20px 230px;
    background-position: center bottom 25px;
    margin: 0 0 34px;

    p {
      text-align: center;
    }

    .btn {
      width: 100%;
      max-width: unset;
    }
  }
}

.styled-cta-block-orange {
  @include styled-cta-block($color-orange, 'orange', $color-orange);
}

.styled-cta-block-blue {
  @include styled-cta-block($color-blue, 'blue', $color-blue);
}

//'Also read' blocks
.styled-also-read-block-blue {
  border-radius: 8px;
  margin: 10px 0 34px;
  padding: 50px 25px 0 30px;
  background-color: $color-sky-blue;

  &__content {
    position: relative;
    background-image: url('@/static/styles/images/pages/articles/also-read-bg.svg');
    background-size: 100% auto;
    background-repeat: no-repeat;
    min-height: 192px;
    padding: 20px 30px 30px;
    text-align: center;

    &:after {
      position: absolute;
      content: '';
      background-color: $color-blue;
      background-image: url('@/static/styles/images/pages/articles/icon-click.svg');
      background-repeat: no-repeat;
      background-position: center;
      width: 64px;
      height: 64px;
      border-radius: 100%;
      box-shadow: 3px 8px 17px rgba(9, 19, 35, 0.24);
      right: 18px;
      top: -8px;

      @media #{$mobile} {
        right: -18px;
        top: -18px;
      }
    }

    @media #{$mobile} {
      background: white;
      border-radius: 8px;
      box-shadow: 3px 8px 17px rgba(9, 19, 35, 0.24);
      text-align: left;
    }

    p, a {
      margin: 0;
      color: $color-blue;
      font-weight: 700;
      font-size: 22px;
      line-height: 1.2;
    }
  }

  &__title {
    color: $color-title;
    font-weight: 700;
    font-size: 30px;
    line-height: 22px;
    margin-bottom: 20px;

    @media #{$mobile} {
      font-size: 28px;
    }
  }

  @media #{$mobile} {
    margin: 0 0 34px;
    padding: 30px;
  }
}

.styled-also-read-block-orange {
  padding: 16px 16px 16px 64px;
  background-color: white;
  display: flex;
  border-top: 1px solid $color-sky-blue;
  border-bottom: 1px solid $color-sky-blue;
  background-image: url('@/static/styles/images/pages/articles/icon-articles.svg');
  background-size: 40px 48px;
  background-repeat: no-repeat;
  background-position: 3px 16px;
  min-height: 80px;
  align-items: center;
  margin: 0 0 34px;

  &__content {
    text-align: left;

    p {
      color: $color-orange;
      font-weight: 700;
      font-size: 22px;
      line-height: 1.5;
      margin: 0;
    }

    a {
      color: $color-blue;
      font-weight: 700;
      font-size: 22px;
      line-height: 1.5;
    }
  }
}


.styled-block-wrapper {
  display: block;
  margin: 0 0 50px;
  padding: 0;
  position: relative;
}

.styled-block-checklist-check-orange {
  @include styled-block-checklist($color-orange, 'orange');
}

.styled-block-checklist-check-blue {
  @include styled-block-checklist($color-blue, 'blue');
}

.styled-block-checklist-number-orange {
  @include styled-block-title($color-title: $color-orange);

  ol {
    border: 1px solid $color-orange;
    border-radius: 4px;
    color: $color-title;
    counter-reset: heading;
    font-size: 20px;
    letter-spacing: .5px;
    line-height: 1.2;
    margin: 50px 0 25px;
    padding: 55px 40px 55px 70px;
    text-align: justify;

    @media #{$md} {
      margin: 25px 0;
    }

    @media #{$mobile} {
      margin: 0 0 25px;
      padding: 25px 17px 25px 42px;
    }

    li {
      list-style: none;
      margin: 0 0 15px;
      position: relative;

      &::before {
        color: $color-blue;
        content: counter(heading) '.';
        counter-increment: heading;
        font-size: 20px;
        font-weight: 700;
        left: -30px;
        position: absolute;
        top: 0;
      }

      ol {
        border: 0;
        font-size: 16px;
        letter-spacing: 0;
        margin: 10px 0 25px;
        padding: 0;

        &:last-of-type {
          margin: 0;
        }

        li {
          &::before {
            font-size: 16px;
            position: static;
          }

          ol {
            counter-reset: my-awesome-counter;

            li {
              opacity: 0.9;

              &::before {
                color: $color-blue;
                content: counter(my-awesome-counter, lower-alpha) '.';
                counter-increment: my-awesome-counter;
                opacity: 1;
              }

              &:last-of-type {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}

.styled-block-checklist-marked-orange {
  @include styled-block-title($color-orange);

  ul {
    border: 1px solid $color-orange;
    border-radius: 4px;
    color: $color-title;
    font-size: 20px;
    letter-spacing: .5px;
    line-height: 1.2;
    list-style: none;
    margin: 50px 0 25px;
    padding: 55px 40px;
    text-align: left;

    &.two-col {
      columns: 2;

      @media #{$tablet} {
        columns: 1;
      }
    }

    &.five-col {
      columns: 5;

      @media #{$tablet} {
        columns: 1;
      }
    }

    @media #{$md} {
      margin: 25px 0;
    }

    @media #{$mobile} {
      margin: 0 0 25px;
      padding: 25px 17px;
    }

    li {
      list-style: none;
      margin: 0 0 12px;
      position: relative;
      padding-left: 30px;

      @media #{$ie} {
        padding: 0 0 20px;
      }

      &::before {
        background: $color-blue;
        border-radius: 50%;
        content: '';
        height: 10px;
        left: 0;
        position: absolute;
        top: 6px;
        width: 10px;

        @media #{$ie} {
          left: -5px
        }
      }

      &:last-of-type {
        margin: 0;
      }
    }

    ul {
      border: 0;
      font-size: 16px;
      letter-spacing: 0;
      margin: 0;
      padding: 0;

      li {
        margin: 15px 0 0;

        &::before {
          background: transparent;
          color: $color-orange;
          content: '▪';
          height: auto;
          padding-right: 10px;
          position: static;
          width: auto;
        }

        &:last-of-type {
          margin: 15px 0 0;
        }
      }

      ul {
        margin: 15px 0 0;
        opacity: 0.9;

        li {
          &::before {
            color: $color-blue;
            content: '•';
          }
        }
      }
    }
  }

  &.styled-block-with-title {
    margin-top: 50px;
  }
}

.styled-block-checklist-marked-white {
  width: 100%;

  @include styled-block-title($color-white);

  ul {
    border: 1px solid $color-white;
    border-radius: 4px;
    color: $color-white;
    font-size: 20px;
    letter-spacing: 0.5px;
    line-height: 1.2;
    list-style: none;
    margin: 25px 0 25px;
    padding: 55px 60px;
    text-align: left;

    @media #{$mobile} {
      margin: 10px 10px 20px;
    }

    &.two-col {
      columns: 2;

      @media #{$tablet} {
        columns: 1;
      }
    }

    @media #{$tablet} {
      margin: 0 0 40px;
    }

    li {
      list-style: none;
      margin: 0 0 15px;
      position: relative;

      @media #{$ie} {
        padding: 0 0 20px;
      }

      &::before {
        background: $color-white;
        border-radius: 50%;
        content: '';
        height: 10px;
        left: -30px;
        position: absolute;
        top: 8px;
        width: 10px;
      }
    }

    ul {
      border: 0;
      font-size: 16px;
      letter-spacing: 0;
      margin: 0;
      padding: 0;

      li {
        margin: 15px 0 0;

        &::before {
          background: transparent;
          color: $color-white;
          content: '▪';
          height: auto;
          padding-right: 10px;
          position: static;
          width: auto;
        }
      }

      ul {
        margin: 15px 0 0;
        opacity: 0.9;

        li {
          &::before {
            color: $color-white;
            content: '•';
          }
        }
      }
    }
  }

  &.styled-block-with-title {
    margin-top: 50px;

    @media #{$mobile} {
      margin-top: 70px;
    }
  }

  .styled-block-title-transparent {
    color: $color-white;
    left: unset;
    width: 100%;
    text-align: center;
  }
}

.styled-block-list-blue {
  ul {
    border: 0;
    font-weight: normal;
    color: #656B6F;
    font-size: 16px;
    line-height: 22px;
    list-style: none;
    margin: 0 0 20px;
    padding: 0;
    text-align: left;

    li {
      list-style: none;
      margin: 0 0 10px;
      position: relative;
      padding-left: 30px;
      color: #656B6F;

      @media #{$ie} {
        padding: 0 0 10px;
      }

      &::before {
        background: $color-blue;
        border-radius: 50%;
        content: '';
        height: 10px;
        left: 0;
        position: absolute;
        top: 6px;
        width: 10px;

        @media #{$ie} {
          left: -5px
        }
      }
    }
  }
}

.styled-block-info-blue {
  @include styled-block-title($color-blue);

  background: $color-blue;
  box-shadow: 2.5px 4.3px 20px 0 $styled-block-info-blue-box-shadow-color;
  color: $color-white;
  font-size: 20px;
  line-height: 26px;
  margin: 50px 0 25px;
  padding: 55px 40px;
  position: relative;

  a {
    color: $color-white;
    font-weight: 700;
    text-decoration: underline;

    &:hover {
      color: $color-white;
      text-decoration: none;
    }
  }

  @media #{$md} {
    margin: 0 0 45px;
  }

  @media #{$mobile} {
    margin: 0 -20px 25px;
    padding: 20px;
    box-shadow: none;
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.styled-block-quote {
  background: $color-sky-blue;
  border-radius: 8px;
  font-weight: 400;
  font-size: 22px;
  line-height: 1.5;
  text-align: left;
  font-style: italic;
  margin: 25px 0 25px;
  padding: 50px 40px 50px 104px;
  position: relative;
  color: $color-light-black;

  p {
    color: inherit;
    font-size: inherit;
    line-height: inherit;

    &:last-child {
      margin: 0;
    }
  }

  &::before {
    background: url('@/static/images/pages/articles/icon-quote.svg') no-repeat 50% 50%;
    content: '';
    left: 32px;
    position: absolute;
    top: 60px;
    height: 40px;
    width: 49px;
  }

  &.indent-40 {
    &::before {
      left: -40px;
    }
  }

  ol {
    margin-top: 8px;
    margin-bottom: 8px;

    li {
      padding-left: 8px;
      color: $color-title;
      margin-bottom: 18px;

      &::marker {
        color: $color-blue;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  @media #{$md} {
    margin: 0 15px 25px;
  }

  @media #{$mobile} {
    margin: 0 0 25px;
    padding: 70px 30px 30px;

    &::before {
      top: 20px;
      left: 30px;
    }
  }
}

.styled-block-image {
  background: $color-sky-blue;
  margin: 0 auto 25px;
  padding: 40px;

  img {
    background-color: $color-white;
    box-shadow: $box-shadow-image;
    margin: 0 auto !important;
    max-width: 100%;
  }
}

.styled-block-image-text {
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  color: $color-grey;
  font-size: 16px;
  line-height: 1.5;
  margin: 25px 0;
  position: relative;
  text-align: justify;
  display: flex;

  @media #{$md} {
    margin: 25px 0;
  }

  @media #{$mobile} {
    flex-direction: column;
    margin: 25px 0;
  }

  .styled-block-title {
    left: 60px;
    position: absolute;
    top: -20px;
    font-weight: bold;
    text-transform: uppercase;

    @media #{$mobile} {
      left: 5px;
    }
  }

  p {
    padding: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h2,
  h3 {
    text-align: left;
  }

  .styled-block-image-wrapper {
    align-items: center;
    justify-content: center;
    background: $color-white;
    display: flex;
    height: inherit;
    width: 32%;
    min-width: 32%;
    padding: 45px 50px;
    border-radius: 6px 6px 0 0;

    @media #{$mobile} {
      width: 100%;
      padding: 25px 20px;
      border-radius: 0 6px 6px 0;
    }

    img {
      margin: 0;
      max-height: 80%;
      max-width: 80%;
    }
  }

  .styled-block-image-content,
  div:nth-child(3){
    padding: 45px 50px;
    background-color: #e3e3e3;

    @media #{$mobile} {
      padding: 25px 20px;
    }
  }
}

.article-view__content {
  p {
    + {
      .styled-block-with-title {
        margin-top: 50px;
      }
    }
  }
}

.article-view__content--country-guides {
  margin: 0 0 100px;
}
