.select {
  display: flex;
  position: relative;
  padding: 0 20px 0 10px;
  width: 100%;
  min-width: 90px;
  max-height: 42px;
  height: 42px;
  background: #fff;
  color: #303030;
  font-family: 'PT Sans', sans-serif;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #DEDEDE;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
  transition: border .3s ease;
  box-sizing: border-box;

  @media #{$mobile} {
    min-width: 90px;
  }

  &::-ms-expand {
    display: none;
  }

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }

  &::after {
    content: '';
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    display: inline-block;
    border: 0 solid transparent;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 3px solid #303030;
  }

  + .input-select {
    width: .1px;
    height: .1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  option {
    color: #303030;
  }

  option[disabled],
  &.placeholder {
    color: #AAAAAA;
  }

  &-wrapper {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      display: inline-block;
      border: 0 solid transparent;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 3px solid #303030;
    }

    .select::after {
      display: none;
    }

    a {
      text-decoration: none;
    }
  }

  &-item {
    padding: 10px 20px;
    border-bottom: 1px solid #FAFAFA;
    cursor: pointer;
    text-decoration: none;
  }

  &:hover {
    border: solid 1px #2586EA;
  }

  &.w-100 {
    max-width: 100px;
  }

  &.w-300 {
    max-width: 300px;
  }
}
