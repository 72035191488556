.guides-text-blue {
  background: $color-blue;
  color: $color-white;
  font-size: 16px;
  line-height: 21px;
  padding: 55px 140px;
  text-align: center;

  @media #{$tablet} {
    padding: 20px;
  }

  ul {
    color: $color-white;

    li {
      &::before {
        background: $color-white;
      }
    }

    ul {
      li {
        &::before {
          color: $color-white;
        }
      }
    }
  }

  p {
    text-align: justify;

    &:last-child {
      margin: 0;
    }
  }

  a {
    color: $color-white;
    text-decoration: underline;

    &:hover {
      color: darken($color-white, 10%);
      text-decoration: none;
    }
  }

  img {
    box-shadow: $guides-text-blue-image-box-shadow;
    margin: 0 auto 50px;
  }

  table {
    background-color: white;
  }
}

.guides-text-blue__photo {
  background: $color-white;
  box-shadow: $guides-text-blue-image-box-shadow;
  display: inline-block;
  margin: 0 auto 50px;
  padding: 30px 50px;

  img {
    box-shadow: none;
    margin: 0;
  }
}

.guides-text-blue__title {
  color: $color-white;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 35px;
  text-align: center;
}

.guides-text-blue__sub-title {
  color: $color-white;
  font-size: 25px;
  font-style: italic;
  letter-spacing: .5px;
  line-height: 1.3;
  margin: 0 -170px 50px;
  text-align: center;

  @media #{$mobile} {
    margin: 0 0 30px;
  }

  &.without-margin {
    margin-bottom: 0;
  }
}

.guides-photo-blue {
  align-items: stretch;
  background: $color-title;
  display: flex;

  @media #{$mobile} {
    flex-direction: column;
  }

  .guides-text__title {
    color: $color-white;
    padding: 0 0 20px;
    text-align: left;
  }

  ul {
    color: $color-white;

    li {
      &::before {
        background: $color-white;
      }
    }

    ul {
      li {
        &::before {
          color: $color-white;
        }
      }
    }
  }
}

.guides-photo-blue__light {
  background: $color-blue;
}

.guides-photo-blue__text {
  color: $color-white;
  font-size: 16px;
  line-height: 1.31;
  padding: 60px 50px 60px 120px;
  text-align: left;

  @media #{$mobile} {
    padding: 20px 30px;
  }
}

.guides-photo-blue__image {
  align-items: center;
  background: $color-lightest-grey;
  display: flex;
  min-width: 30%;
}
