.styled-page-referral-program {
  background: $color-title;
  height: 225px;
  border-radius: 6px;
  overflow: hidden;
  margin: 40px -60px;
  padding: 10px 380px 10px 60px;
  position: relative;
  display: flex;
  align-items: center;

  @media #{$tablet} {
    margin: 40px 0;
    padding-left: 20px;
  }

  @media #{$mobile} {
    position: relative;
    margin: 40px 0;
    padding: 300px 20px 20px;
    height: auto;
    text-align: center;
  }

  &__image {
    position: absolute;
    top: -65px;
    right: -70px;

    @media #{$mobile} {
      position: absolute;
      top: -50px;
      right: -10px;
    }
  }

  &__title {
    font-size: 34px;
    line-height: 40px;
    color: $color-white;
    text-align: left;
    margin: 0 0 15px;

    @media #{$mobile} {
      text-align: center;
    }
  }

  &__text {
    font-size: 25px;
    line-height: 32px;
    color: $color-white;
    margin: 0 0 10px;
    text-align: left;

    @media #{$mobile} {
      text-align: center;
    }
  }

  &--orange-bg {
    background: $color-orange;
    min-height: 293px;
    height: auto;
    padding: 10px 570px 10px 60px;

    @media #{$mobile} {
      padding: 35px 20px;
      margin: 50px 0 30px;
      flex-direction: column;
    }

    .styled-page-referral-program__image {
      right: 60px;
      top: unset;
      bottom: 0;

      @media #{$mobile} {
        position: relative;
        bottom: -35px;
        left: auto;
        right: auto;
        margin: 0 auto;
      }
    }

    .styled-page-referral-program__title {
      color: white;
      text-align: left;
      margin: 0 0 15px;
    }

    .styled-page-referral-program__text {
      font-size: 20px;
      line-height: 26px;
      color: white;
    }

    .btn-white {
      color: $color-orange;
      min-width: 220px;

      @media #{$mobile} {
        width: 100%;
        min-width: unset;
      }

      &:hover,
      &:focus {
        color: white;
        border-color: white;
      }
    }
  }
}
