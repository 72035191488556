.square-info {
  padding: 70px 0 0;
  text-align: center;

  .container {
    max-width: 920px;
  }

  @media #{$mobile} {
    padding: 0;
  }
}

.square-info__title {
  font-size: 34px;
  line-height: 40px;
  font-weight: 700;
  color: $color-title;
  margin: 0 0 20px;
}

.square-info__list {
  display: flex;
  align-items: center;
  padding: 50px 0;

  @media #{$mobile} {
    display: block;
    padding-top: 0;
  }
}

.square-info__item {
  width: 33.33%;
  margin: 15px;
  border: 2px solid #eff0f4;
  border-radius: 4px;
  padding: 85px 30px 0;
  position: relative;
  min-height: 220px;

  @media #{$mobile} {
    min-height: auto;
    width: 100%;
    margin: 80px 0 0;
    padding: 70px 20px 10px;
  }
}

.square-info__item-image {
  width: 100px;
  height: 100px;
  border: 2px solid $color-orange;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: -50px;
  left: 50%;
  margin-left: -50px;
  background: $color-white;
}

.square-info__item-title {
  font-size: 19px;
  line-height: 21px;
  font-weight: 700;
  color: $color-title;
  margin: 0 0 20px;
}

.square-info__item-text {
  font-size: 16px;
  line-height: 22px;
  color: $color-grey;
  text-align: left;
}
