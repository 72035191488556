.banner {
  background: #1A5DA2;
  position: relative;
  overflow: hidden;

  &--promo {
    background-color: $color-blue;
  }

  &__inner {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  &__slider-wrapper {
    margin: 15px 0;
    overflow: hidden;
  }

  &__slider {
    display: flex;
    position: relative;
    transition: transform 0.5s;
    will-change: left;
    max-width: 980px;

    &--transition {
      transition: left 0.2s ease-out;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 100%;
    width: 100%;
    overflow: hidden;
  }

  &__item-inner {
    display: flex;
    align-items: center;
  }

  &__img {
    width: 32px;
    height: 32px;
  }

  &__text {
    margin-left: 20px;
    font-size: 14px;
    line-height: 18px;
    color: $color-white;
    display: inline-block;
  }

  &__link {
    color: $color-white;
    text-decoration: underline;

    &:hover {
      color: darken($color-white, 20%);
      text-decoration: underline;
    }
  }

  &__close-btn {
    cursor: pointer;
    background: url('@/static/images/icons/default/icon-close-dark.svg') no-repeat 50% 50%;
    width: 20px;
    height: 20px;
    margin-right: 15px;
    top: calc(50% - 10px);
    position: absolute;
    right: 2px;
  }

  &__controls {
    cursor: pointer;
    height: 16px;
    min-width: 16px;
    margin: 0 10px;
    z-index: 1000;

    &:hover {
      opacity: 0.85;
    }

    &--disabled {
      min-width: 0;
      visibility: hidden;
    }

    &--prev {
      background: url('@/static/images/icons/slider/arrow-white-small.svg') no-repeat 50% 50%;
      transform: rotate(180deg);
    }

    &--next {
      background: url('@/static/images/icons/slider/arrow-white-small.svg') no-repeat 50% 50%;
      margin-right: 50px;
    }
  }

  &__content {
    padding: 10px 15px;
    max-width: 960px;
    margin: 0 auto;
    color: white;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
  }

  &__label {
    display: inline-block;
    font-size: 12px;
    line-height: 18px;
    color: white;
    background-color: #10940B;
    font-weight: bold;
    padding: 1px;
    border-radius: 4px;
  }
}