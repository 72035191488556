// Base page style

.base-page--webinar {
  .base-page-head {
    min-height: 430px;
  }

  .base-page-head__column--image {
    min-width: 450px;
    padding: 0;

    @media #{$tablet} {
      min-width: 300px;
    }
  }

  .base-page-head__content {
    padding-top: 80px;
    align-items: flex-start;
  }

  .base-page-head__title {
    margin-top: 15px;

    @media #{$tablet} {
      margin-top: 0;
      font-size: 35px;
    }
  }
}

// Style for webinar

.webinar-page {
  padding: 0 0 40px;

  .container {
    max-width: 980px;
  }

  &__live-webinar {
    padding: 20px 60px 0;
    display: flex;
    background-color: white;
    box-shadow: 0 4px 25px rgba(0, 17, 34, 0.2);
    border-radius: 4px;

    @media #{$mobile} {
      flex-direction: column-reverse;
    }
  }

  &__live-webinar-content {
    flex: 1;
    text-align: left;
    margin-left: 40px;

    h3 {
      text-align: left;
    }

    @media #{$mobile} {
      margin-left: 0;
      margin-bottom: 30px;
    }
  }

  &__recorded-webinar {
    padding: 20px 60px 0 0;
    display: flex;
    background-color: $color-blue;
    box-shadow: 0 4px 25px rgba(0, 17, 34, 0.2);
    border-radius: 4px;

    @media #{$mobile} {
      flex-direction: column;
      padding: 20px 20px 0;
    }
  }

  &__recorded-content {
    flex: 1;
    text-align: left;
    margin-left: 60px;

    h3 {
      text-align: left;
      color: white;
      font-size: 25px;
    }

    p {
      color: white;
    }

    @media #{$mobile} {
      margin-left: 0;
      margin-bottom: 30px;
    }
  }

  &__archive {
    margin: 0 -12px;
    text-align: center;
    padding: 10px 0;
    column-count: 3;
    column-gap: 25px;

    @media #{$mobile} {
      margin: 0;
      column-count: 1;
    }
  }

  &__archive-item {
    box-shadow: 0 4px 25px rgba(0, 17, 34, 0.2);
    border-radius: 8px;
    background: white;
    break-inside: avoid;
    margin-bottom: 25px;

    @media #{$mobile} {
      margin: 10px 0 20px;
      width: 100%;
      max-width: none;
    }
  }

  &__archive-content {
    padding: 25px 32px 32px;
    text-align: left;

    > h4 {
      text-align: left;
      margin-bottom: 5px;
    }

    .btn-watch-recordered {
      width: 100%;
    }
  }

  &__archive-image {
    position: relative;
    display: block;
    height: 170px;
    width: 100%;
    cursor: pointer;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
  }

  &__archive-image-button {
    background: url("../images/icons/default/icon-video-play.svg") no-repeat 50% 50%;
    background-size: 100% auto;
    width: 100px;
    height: 70px;
    position: absolute;
    z-index: 11;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__archive-image-wrapper {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: linear-gradient(180deg, rgba(17, 71, 125, 0) 0%, #11477d 100%);
      opacity: 0.8;
    }
  }
}
