.attachment {
  background-color: white;
  display: flex;
  padding: 8px 0;
  position: relative;
  width: 100%;

  @media #{$mobile} {
    width: 100%;
    height: 49px;
    margin-right: 0;
  }

  &-desc {
    font-size: 15px;
    line-height: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: calc(100% - 20px);
    color: $color-dark-link;

    @media #{$mobile} {
      overflow: hidden;
      word-wrap: break-word;
      margin-top: 0;
      width: calc(100% - 90px);
    }
  }

  &-icon {
    height: 18px;
    margin-right: 4px;
    width: 18px;

    .icon-file {
      background-size: cover;
      height: 20px;
      width: 20px;

      &.document {
        &-lock {
          background-image: url('~tfx-common/images/icons-file-type/ic-lock.svg');
        }

        &-error {
          background-image: url('~tfx-common/images/icons-file-type/ic-error.svg');
        }

        &-pdf {
          background-image: url('~tfx-common/images/icons-file-type/ic-file-pdf.svg');
        }

        &-doc {
          background-image: url('~tfx-common/images/icons-file-type/ic-file-doc.svg');
        }

        &-csv {
          background-image: url('~tfx-common/images/icons-file-type/ic-file-csv.svg');
        }

        &-img {
          background-image: url('~tfx-common/images/icons-file-type/ic-file-img.svg');
        }

        &-all {
          background-image: url('~tfx-common/images/icons-file-type/ic-file-all.svg');
        }

        &-xls {
          background-image: url('~tfx-common/images/icons-file-type/ic-file-xls.svg');
        }
      }
    }
  }

  &-image {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    &_img {
      border-radius: $border-radius;
      height: inherit;
      object-fit: cover;
      width: inherit;
    }
  }

  &-actions {
    display: flex;
    position: absolute;
    top: 6px;
    width: 20px;
    left: unset;
    right: 0;
    bottom: unset !important;
    justify-content: flex-end;

    .button {
      display: block;
      height: 18px;
      width: 18px;
      min-width: 18px;
      min-height: 18px;
      padding: 0;

      > svg {
        height: inherit;
      }

      .icon-view-as-client {
        $measurments: 20px;

        background: none;
        fill: $bd-color-blue-curious;
        height: $measurments;
        width: $measurments;
      }

      .icon-delete {
        fill: $bd-color-blue-curious;
        height: 16px;
        width: 14px;
        background: none;
      }

      &:hover {
        background-color: $bd-color-white-middle;
      }

      &:last-child {
        margin-right: 0;
      }

      @media #{$mobile} {
        padding: 0;
        text-align: center;
        width: 28px;
        height: 28px;
        box-shadow: 0 1px 0 0 rgba(0, 20, 29, .07);
        background-color: $bd-color-white;
        border: 1px solid $bd-color-grey-alto;
        min-width: unset;
        min-height: unset;
        margin-right: 8px;
      }
    }

    .select-doc-year {
      position: relative;
      right: 10px;
      height: 32px;
      top: -6px;

      .multiselect,
      .select {
        height: inherit;
        max-height: unset;
        min-width: 80px;

        .multiselect__tags {
          min-height: 30px;
        }
      }
    }
  }
}

.button_link {
  text-decoration: none;
  font-size: 15px;
  line-height: 18px;
  height: 18px;
  border-bottom: 1px solid rgba(0,0,0,.12);

  &:hover {
    color: $bd-color-btn-hvr;
    border-color: $bd-color-btn-hvr;
  }
}
