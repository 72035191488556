.static-page {
  &__container {
    align-items: flex-start;
    display: flex;
    min-height: 750px;
    position: relative;
    flex-wrap: wrap;

    @media #{$sm} {
      padding: 0;
    }
  }

  &__view {
    margin: 0 auto;
    max-width: 1020px;
    padding: 0 30px;
    width: 100%;

    .page-title {
      font-size: 48px;
      margin: 0;
      padding: 70px 90px 20px;

      @media #{$md} {
        padding: 70px 0 20px;
        font-size: 44px;
      }

      @media #{$mobile} {
        font-size: 34px;
      }
    }

    @media #{$md} {
      padding: 0 20px;
    }

    @media #{$mobile} {
      margin-top: 50px;
    }
  }

  &__title {
    font-size: 60px;
    line-height: 72px;
    font-weight: 700;
    text-align: center;
    color: $color-title;
    padding: 70px 90px 20px;
  }

  &__sub-title {
    font-size: 18px;
    line-height: 23px;
    color: $color-title;
    text-align: center;
  }

  &__embed {
    margin: 0 0 25px;
    width: 100%;
    border-bottom: 1px solid $color-border;
  }

  &__wrapper-table {
    width: 100%;
    overflow: auto;
  }

  &__content {
    color: $color-grey;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    margin: 0 0 100px;
    padding: 0 90px;
    text-align: justify;

    &.small-margin {
      margin: 0 0 50px;
    }

    &.without-margin {
      margin: 0;
    }

    @media #{$md} {
      padding: 0;

      .styling-table-dark {
        margin-left: 10px;
        width: calc(100% - 10px);
      }
    }

    a {
      font-weight: 700;

      &:hover {
        color: $color-link;
        text-decoration: underline;
      }

      &.btn {
        &:hover {
          opacity: 0.9;
          text-decoration: none;
        }
      }
    }

    p {
      margin: 0 0 25px;
      line-height: 22px;
    }

    img {
      display: block;
      margin: 0 auto 30px;
    }

    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5 {
      color: $color-title;
      font-weight: bold;
      text-align: center;
    }

    h2 {
      margin: 40px 0 20px;
      text-align: center;

      @media #{$mobile} {
        font-size: 28px;
      }

      &:first-child {
        margin-top: 0;
      }
    }

    h3 {
      margin: 30px 0 15px;
      text-align: center;

      &:first-child {
        margin-top: 0;
      }
    }

    h4 {
      margin: 20px 0 10px;
      text-align: center;
    }
  }
}

.page__home--no-waves {
  .header__wave {
    display: none;
  }

  .how-we-work_header__inner {
    hr {
      display: none;
    }
  }

  .video-testimonials--main {
    margin-top: -2px;

    &:after {
      background-color: #E9F3FD;

      @media #{$mobile} {
        height: 160px;
      }
    }
  }

  .block-organizations {
    .block-organizations_wave {
      display: none;
    }

    .block-organizations_inner {
      background-color: #E9F3FD;
      padding-top: 50px;
      padding-bottom: 60px;
    }

    .block-organizations__item-info-title {
      color: #0A315A;

      @media #{$min-width-md} {
        padding-right: 80px;
      }

    }

    .block-organizations__item-info-wrapper .btn {
      border-color: #2586EA;
      color: #2586EA;
    }
  }

  .mfa-section {
    background-image: url('@/static/images/pages/index/mfa-dark-bg.svg');
  }

  .mob-app-section{
    margin-top: -60px;
    margin-bottom: 0;
    padding-bottom: 60px;

    @media #{$mobile} {
      margin-top: 0;
    }

    &:before {
      background-image: url('@/static/images/pages/index/wave-left-top.svg');
      background-color: #2588ED;
      background-position: 100% 100%;

      @media #{$mobile} {
        background: none;
      }
    }

    &:after {
      background-image: none;
      background-color: #E9F3FD;
    }
  }
}
