.block-mentioned-in {
  background: $color-white;
  box-sizing: border-box;
  padding: 0 0 80px;

  @media #{$tablet} {
    padding: 0 0 60px;
  }

  .static-title {
    margin: 0;
    padding: 40px 0;
    text-align: center;

    @media #{$tablet} {
      padding: 30px 0;
    }
  }

  &__list {
    align-items: center;
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    width: 100%;

    @media #{$tablet} {
      flex-wrap: wrap;
    }
  }

  &__item {
    width: 20%;
    opacity: .6;
    filter: grayscale(1);
    transition: all .3s ease-in-out;

    &:hover {
      opacity: 1;
      filter: grayscale(0);
    }

    @media #{$tablet} {
      margin: 20px;
      width: auto;
    }
  }
}
