.affiliate-list {
  padding: 10px 0 0;

  &__item {
    margin: 0 0 30px;
    display: flex;
    position: relative;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      bottom: -30px;
      left: 21px;
      border-left: 2px dashed $color-blue;
    }

    &:last-child {
      margin: 0;

      &:before {
        display: none;
      }
    }

    h3 {
      font-weight: 700;
      font-size: 18px;
      line-height: 1;
      color: $color-title;
      margin: 0 0 8px;
      text-align: left;
    }

    p {
      margin: 0;
      text-align: left;
    }
  }

  &__number {
    width: 44px;
    min-width: 44px;
    height: 44px;
    border: 2px solid $color-blue;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 700;
    color: $color-blue;
    margin: 0 22px 0 0;
    border-radius: 50%;
    background: $color-white;
    position: relative;
    z-index: 2;
  }
}

.affiliate-form-container {
  padding: 40px 0 10px;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    height: 400px;
    background: $color-blue;
    z-index: -1;
  }
}

h2.affiliate-form__title {
  color: $color-white;
  margin: 0 0 30px;
}

.affiliate-form {
  box-shadow: 3px 2px 9px 0 rgba(0, 0, 0, .4), 3px 19px 35px 0 rgba(0, 0, 0, .2);
  background-color: $color-white;
  border-radius: 4px;
  padding: 40px;
  position: relative;

  &__inputs {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px 10px;
  }

  &__item {
    width: 50%;
    padding: 0 10px;

    &.column-2 {
      display: flex;
      align-items: flex-start;

      .form-group__styled {
        width: calc(50% - 10px);
        margin: 0;

        &:last-child {
          margin-left: 20px;
        }
      }

      @media #{$mobile} {
        flex-direction: column;

        .form-group__styled {
          width: 100%;

          &:last-child {
            margin: 11px 0;
          }
        }
      }
    }

    @media #{$mobile} {
      width: 100%;
    }
  }

  &__footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media #{$mobile} {
      flex-direction: column;
    }

    .registration__input-radio-wrapper {
      margin-bottom: 20px;
    }

    .registration__input-radio {
      +.registration__input-radio-label {
        white-space: normal;
      }
    }

    .registration__input-radio-wrapper {
      .form-control-feedback {
        &.validate-msg {
          position: absolute;
          top: 30px;
          left: 0;
        }
      }
    }
  }
}