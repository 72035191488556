$color-reviews-text: #656b6f;
$color-reviews-border-user-list: #b8b8b8;
$color-reviews-orange: #f96b1b;

.reviews-home {
  padding: 60px 0 30px;
  background-color: $color-blue-bg;

  @media #{$mobile} {
    padding: 30px 0;
  }

  .static-title {
    margin: 0 0 40px;
    text-align: center;
    color: $color-white;
  }

  .container {
    overflow: visible;
    max-width: 920px;
  }

  .single-independent-review__wrapper {
    padding: 20px 30px;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .1), 0 9px 22px 8px rgba(0, 0, 0, .1);
    background-color: $color-white;
    overflow: hidden;

    @media #{$tablet} {
      padding: 15px;
    }
  }

  &__text {
    position: relative;
    min-height: 120px;
    background-color: white;

    @media #{$mobile} {
      min-height: 40px;
    }

    .container {
      max-width: 920px;
    }
  }

  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: -1px;
  }

  &__inner {
    width: 100%;
    background-color: $color-blue-bg;
    height: calc(100% - 300px);
    margin-top: -1px;
  }

  &__wave {
    margin-top: -1px;
    width: 100%;

    img {
      transform: rotate(180deg);
      width: 100%;
    }
  }
}

.landing-body {
  .reviews-home__text {
    padding-bottom: 60px;
  }

  .reviews-home__bg {
    background: $color-sky-blue;
  }
}
