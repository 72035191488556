.security-personal-data {
  .container {
    max-width: 860px;
  }

  .container-inside {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    margin: 30px -30px 0;

    @media #{$tablet} {
      padding: 0;
    }

    @media #{$mobile} {
      margin-right: 0;
      margin-left: 0;
      flex-direction: column;
    }
  }
}

.security-personal-data__item {
  border: 1px solid $color-border;
  border-radius: 6px;
  margin: 0 15px 82px;
  min-height: 230px;
  padding: 75px 28px 10px;
  position: relative;
  width: calc(33.33% - 30px);

  @media #{$mobile} {
    width: calc(100% - 30px);
  }
}

.security-personal-data__item-title {
  color: $color-title;
  font-size: 19px;
  font-weight: 700;
  letter-spacing: .2px;
  line-height: 1.11;
  margin: 0 0 15px;
  text-align: center;

  &.title-big-text {
    margin: 0 -15px 25px;
  }
}

.security-personal-data__item-text {
  color: $color-grey;
  font-size: 16px;
  letter-spacing: .3px;
  line-height: 1.2;
  text-align: justify;

  a {
    font-weight: 700;
    text-decoration: none;

    &:hover {
      color: $color-link;
      text-decoration: underline;
    }
  }
}

.security-personal-data__item-image {
  align-items: center;
  background: $color-white;
  border: 1px solid $color-orange;
  border-radius: 50%;
  display: flex;
  height: 100px;
  justify-content: center;
  left: 50%;
  margin-left: -50px;
  position: absolute;
  top: -50px;
  width: 100px;

  &.image-padding-bottom {
    padding-bottom: 8px;
  }
}

