.careers-page {
  .list-advantages {
    margin: 0 -15px 50px;
    display: flex;
    justify-content: space-between;

    @media #{$mobile} {
      flex-direction: column;
    }
  }

  .list-advantages__item {
    width: 33.33%;
    padding: 0 15px;

    @media #{$mobile} {
      width: 100%;
      margin-bottom: 80px;

      &:last-of-type {
        margin-bottom: 20px;
      }
    }
  }

  .list-advantages__content {
    border: 2px solid #d4d4d4;
    border-radius: 4px;
    padding: 80px 30px 30px;
    text-align: center;
    position: relative;
    min-height: 240px;
  }

  .list-advantages__photo {
    width: 100px;
    height: 100px;
    border: 2px solid $color-orange;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -50px;
    left: 50%;
    margin-left: -50px;
    background: white;

    &:after,
    &:before {
      position: absolute;
      content: '';
      top: 45px;
      width: 5px;
      height: 5px;
      background: $color-border;
    }

    &:before {
      left: -7px;
    }

    &:after {
      right: -7px;
    }
  }

  .list-advantages__title {
    font-weight: 700;
    margin: 0 0 12px;
    font-size: 19px;
    line-height: 21px;
    letter-spacing: .2px;
    text-transform: uppercase;
  }

  .list-advantages__text {
    text-align: justify;
    margin: 0;
  }

  .form-description {
    margin: -10px 0 25px;
    font-size: 17px;
    text-align: center;
  }

  .form-careers {
    padding: 35px 40px 40px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .1), 0 9px 22px 8px rgba(0, 0, 0, .1);
    background-color: $color-white;
    border-radius: 4px;
    position: relative;

    @media #{$mobile} {
      padding: 20px;
    }
  }

  .form-careers__content {
    display: flex;
    align-items: stretch;
    margin: 0 -10px 40px;

    @media #{$mobile} {
      flex-direction: column;
      margin: 0 0 30px
    }
  }

  .form-careers__column {
    width: 50%;
    padding: 0 10px;
    position: relative;

    @media #{$mobile} {
      width: 100%;
    }
  }

  .registration__radio-buttons-wrapper {
    margin: -12px 0 0;
    justify-content: space-between;
  }

  .form-careers__title {
    color: $color-orange;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 17px;
    line-height: 1;
    margin: 0 0 15px;
  }

  .form-careers__footer {
    display: flex;
    justify-content: flex-end;

    @media #{$mobile} {
      justify-content: center;
    }
  }

  .form-group__file-content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10px;
    right: 10px;

    @media #{$mobile} {
      position: relative;
      right: 0;
      bottom: 0;
      height: 160px;
      left: 0;
      margin-top: 10px;
    }
  }

  .form-group__file {
    cursor: pointer;
    width: 100%;
    height: 100%;
    outline: none;
    display: block;
    transition: border-color ease .2s;
  }

  .form-group__styled-with-error {
    .form-group__file {
      + .form-careers__file-info {
        border-color: $color-orange;
        color: $color-orange;

        g {
          fill: $color-orange;
        }
      }
    }
  }

  // Info

  .form-careers__file-info {
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    background: $color-white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-blue;
    text-align: center;
    font-weight: 700;
    transition: color ease .2s;
    border: 2px dashed $color-blue;

    &.file-uploaded {
      border-color: #26ca06;
      color: #26ca06;
    }
  }

  .form-careers__file-info-image {
    width: 60px;
    height: 60px;
    margin: 0 auto 17px;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    g {
      transition: fill ease .2s;
    }
  }

  .form-careers__file-info-title {
    font-size: 16px;
    letter-spacing: .2px;
    text-transform: uppercase;
  }

  .form-careers__file-info-desc {
    font-size: 12px;
    letter-spacing: .1px;
  }

  // Error

  .form-careers__file-custom-error {
    position: absolute;
    top: 100%;
    right: -2px;
    z-index: 10;
    background: $color-white;
    color: $color-orange;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: .2px;
    line-height: 12px;
    margin-top: 5px;
    padding-bottom: 2px;
    padding-left: 5px;
    white-space: nowrap;

    &.file-uploaded {
      color: #26ca06;

      padding-right: 15px;
      background: url('@/static/images/pages/careers/icon-success.svg') no-repeat 100% 30%;
    }
  }


  // Success

  .form-careers__success {
    position: absolute;
    border-radius: 4px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-white;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    .btn {
      min-width: 210px;
    }
  }

  .form-careers__success-content {
    margin: 10px 0 0;
  }

  .form-careers__success-image {
    width: 250px;
    height: 148px;
    background: url('@/static/images/pages/careers/success.svg') no-repeat 100% 50%;
    background-size: contain;
    margin: 0 auto 10px;
  }

  .form-careers__success-title {
    font-weight: 700;
    letter-spacing: -.1px;
    margin: 0 0 5px;
  }

  .form-careers__success-text {
    margin: 0 0 25px;
  }
}
