.cookie-banner {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 99;
  width: 100%;
  box-shadow: 0 -6px 14px -3px rgba(0, 8, 14, .17);
  background-color: $color-white;
  transition: all .15s linear;

  .cookie-consent {
    display: flex;
    margin: 15px;
    padding-left: 60px;
    background-image: url('@/static/styles/images/icons/icon-cookie-man.svg');
    background-position: left 10px top;
    background-size: 36px 42px;
    min-height: 42px;
    background-repeat: no-repeat;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    color: #404040;

    @media #{$mobile} {
      flex-direction: column;
      padding-left: 0;
      background-image: none;
      text-align: center;
    }

    &_more {
      text-decoration: underline;
    }

    .btn {
      width: 150px;

      @media #{$mobile} {
        margin-top: 20px;
      }
    }
  }
}
