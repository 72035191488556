.advantages-information {
  background-color: $color-blue;
  padding: 80px 0;

  @media #{$tablet} {
    padding: 0 0 30px;
  }

  &__intro {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-right: 30px;

    h2, p {
      color: inherit;
      text-align: left;
    }
  }

  &__inner {
    display: grid;
    grid-template-columns: repeat(4,296px);
    grid-template-rows: repeat(4,296px);
    gap: 20px 20px;
    width: 100%;
    height: 100%;
    justify-content: center;

    @media #{$md} {
      grid-template-columns: repeat(3,296px);
      grid-template-rows: repeat(6,296px);
    }

    @media #{$tablet} {
      grid-template-columns: repeat(2,296px);
      grid-template-rows: repeat(8,296px);
    }

    @media #{$mobile} {
      gap: 20px 0;
      grid-template-columns: 296px;
      grid-template-rows: repeat(16,296px);
    }
  }

  &__item {
    position: relative;
    overflow: hidden;
    padding: 25px 30px;
    box-shadow: 3px 8px 17px rgba(9, 19, 35, .24);
    border-radius: 4px;
    text-align: center;
    align-items: center;

    &:hover {
      cursor: pointer;

      .advantages-information__item--back {
        top: 0;
      }

      .advantages-information__item--front {
        top: 100%;
      }
    }

    &--front,
    &--back {
      position: absolute;
      width: 100%;
      height: 100%;
      text-align: center;
      overflow: hidden;
      transition: top .1s ease-out;
    }

    &--back {
      top: 100%;
      left: 0;
      background-color: white;
    }

    &--front {
      top: 0;
      left: 0;
    }

    &--content {
      position: relative;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      padding: 25px 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;

      .advantages-information__title {
        color: $color-title;
        margin: 2px 0 10px;
        position: unset;
      }
    }
  }

  &__title {
    color: white;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 32px;
    letter-spacing: .2px;
    position: absolute;
    z-index: 1;
    bottom: 25px;
    left: 30px;
    text-align: left;
    margin-bottom: 0;

    &--type {
      color: $color-orange;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      text-align: left;
    }
  }

  &__text {
    color: $color-grey;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
    margin: 0;

  }

  &__img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: fill;
    left: 0;
    top: 0;
  }
}
