body {
  &.article-view-page {
    min-width: auto;
  }
}

.article-container {
  min-height: 750px;
  position: relative;
}

.article-view {
  margin: 0 auto;
  max-width: 880px;
  padding: 0;
  width: 100%;

  @media #{$xl} {
    max-width: 720px;
  }

  @media #{$md} {
    padding: 0 20px;
  }

  &__related-items-holder {
    margin-top: 40px;
  }

  &__related-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__related-item {
    border: 1px solid #2588ED;
    border-left: 7px solid #2588ED;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 10px 10px 10px 30px;
    width: calc(50% - 12px);
    display: flex;
    align-items: center;
    text-align: left;
    font-size: 16px;
    line-height: 21px;

    @media #{$mobile} {
      flex-direction: column;
      width: 100%;
    }
  }

  .page-title {
    margin: 0;
    padding: 100px 20px 44px;
    font-size: 40px;

    @media #{$mobile} {
      font-size: 34px;
      padding: 80px 30px 30px;
    }
  }

  &__main-photo {
    align-items: center;
    display: flex;
    margin: 0 0 32px;
    max-height: 400px;
    overflow: hidden;

    picture, img {
      width: 100%;
      max-height: 400px;
      object-fit: cover;
      height: auto;
      line-height: 0;
    }
  }

  &__information {
    color: $color-grey;
    display: flex;
    font-size: 14px;
    font-weight: 700;
    justify-content: space-between;
    margin: 0 0 50px;

    &--date {
      justify-content: flex-end;
    }
  }

  &__content-wrapper-table {
    overflow: auto;
    width: 100%;
  }

  &__content {
    margin: 0 auto;

    .btn-lg {
      font-size: 18px;
    }
  }

  //lead-magnet-form
  .get-guide-form {
    @media (min-width: 1024px) {
      max-width: 420px;
    }

    a:hover,
    a:focus {
      color: white !important;
      text-decoration: none !important;
    }
  }

  .get-guide-form__img {
    right: -100px !important;
  }

  .get-guide-form__container {
    padding: 0 !important;
  }
}

.article-view__content,
.cke_editable,
.wire__content-item {
  color: $color-light-black;
  font-size: 22px;
  line-height: 1.5;
  font-weight: 400;
  text-align: left;

  a:not(.articles-show__cta-btn) {
    font-weight: 700;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;

    &:hover {
      color: $color-link;
      text-decoration: underline;
    }

    &.btn-orange {
      &:hover {
        color: $color-white;
        text-decoration: none;
      }
    }
  }

  p {
    font-size: 22px;
    margin: 0 0 20px;
    line-height: 1.5;
    text-align: left;

    + .styled-block-with-title {
      margin-top: 64px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: $color-title;
    font-weight: 700;
    line-height: 1.2;
  }

  h2 {
    font-size: 34px;
    margin: 32px 0;
    text-align: center;

    &:first-child {
      margin-top: 0;
    }
  }

  h3 {
    font-size: 28px;
    margin: 32px 0 16px;
    text-align: center;

    &:first-child {
      margin-top: 0;
    }
  }

  h4 {
    font-size: 24px;
    margin: 24px 0 16px;
    text-align: center;

    &:first-child {
      margin-top: 0;
    }
  }

  ol:not(.toc-list) {
    counter-reset: heading;
    font-size: 22px;
    line-height: 1.5;
    margin: 10px 0 20px;
    text-align: left;

    li {
      list-style: none;
      margin: 0 0 15px;
      position: relative;
      font-size: 22px;
      line-height: 1.5;

      &::before {
        color: $color-blue;
        content: counter(heading) '.';
        counter-increment: heading;
        font-size: 22px;
        font-weight: 700;
        left: -30px;
        position: absolute;
        top: 0;
      }

      &:last-of-type {
        margin: 0;
      }

      ol:not(.toc-list) {
        border: 0;
        font-size: 22px;
        line-height: 1.5;
        letter-spacing: 0;
        margin: 10px 0 25px;
        padding: 0;

        li {
          &::before {
            font-size: 22px;
            padding-right: 5px;
            position: static;
          }

          ol {
            counter-reset: my-awesome-counter;

            li {
              opacity: .6;
              padding-left: 30px;

              &::before {
                color: $color-blue;
                content: counter(my-awesome-counter, lower-alpha) '.';
                counter-increment: my-awesome-counter;
                opacity: 1;
              }
            }
          }
        }

        &:last-of-type {
          margin: 10px 0 25px !important;
        }
      }
    }
  }

  ul {
    font-size: 22px;
    line-height: 1.5;
    list-style: none;
    margin: 10px 0 20px;
    text-align: left;

    > li {
      list-style: none;
      margin: 0 0 15px;
      position: relative;
      font-size: 22px;
      line-height: 1.5;

      &::before {
        background: $color-blue;
        border-radius: 50%;
        content: '';
        height: 10px;
        left: -30px;
        position: absolute;
        top: 15px;
        width: 10px;
      }

      &:last-of-type {
        margin: 0;
      }
    }

    > ul {
      border: 0;
      font-size: 22px;
      letter-spacing: 0;
      margin: 15px 0 0;
      padding: 0;

      > li {
        margin: 15px 0 0;
        font-size: 22px;

        &::before {
          background: transparent;
          color: $color-orange;
          content: '▪';
          height: auto;
          padding-right: 10px;
          position: static;
          width: auto;
        }
      }

      ul {
        margin: 15px 0 0;
        opacity: 0.6;

        > li {
          &::before {
            color: $color-blue;
            content: '•';
          }
        }
      }
    }
  }

  .styled-block-checklist-check-blue,
  .styled-block-checklist-check-orange {
    ul {
      li:before {
        top: 15px;
      }
    }
  }

  .styled-block-checklist-marked-orange,
  .styled-block-checklist-marked-blue {
    ul,
    ol {
      li:before {
        top: 15px;
      }
    }

    .styled-block-title {
      left: 0;
    }
  }
}

// will be deleted when the blog is merged
.articles-show {
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;

  &__cta {
    padding: 32px;
    margin-bottom: 24px;
    border-radius: 16px;
    font-size: 20px;
    line-height: 36px;
    text-align: center;

    @media #{$mobile} {
      padding: 16px;
      font-size: 16px;
      line-height: 24px;
    }

    ul, ol {
      font-size: 20px;
      line-height: 36px;

      @media #{$mobile} {
        font-size: 16px;
        line-height: 24px;
      }
    }

    p {
      text-align: center;
    }

    &--orange {
      background-color: #fff7ed;

      .articles-show__cta-title {
        color: #f97316;
      }
    }

    &--blue {
      background-color: #F0F7FE;

      .articles-show__cta-title {
        color: #2586EA;
      }
    }

    &__cta-title {
      margin-bottom: 12px;
      font-weight: bold;
      font-size: 20px;
      line-height: 36px;

      @media #{$mobile} {
        line-height: 24px;
        font-size: 18px;
        margin-bottom: 8px;
      }
    }
  }
  &__cta-btn {
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    border-radius: 6px;
    line-height: 1;
    transition: all 150ms ease-in-out;
    padding: 12px 32px;

    &:hover,
    &:focus {
      cursor: pointer;
    }
  }

  &__author-text {
    p {
      font-size: inherit;
      line-height: inherit;
      color: inherit;
    }
  }
}
