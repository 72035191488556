$section-bg: #E9F3FD;

.mob-app-section {
  position: relative;
  z-index: 1;
  background-color: $section-bg;
  margin: 70px 0;

  @media #{$tablet} {
    margin: 50px 0;
  }

  .container {
    max-width: 920px;
  }

  @media #{$mobile} {
    padding-top: 50px;
    margin-top: 0;
  }

  &:before,
  &:after {
    position: absolute;
    content: '';
    width: 100%;
    left: 0;
    background-repeat: no-repeat;
    background-size: 100% auto;
    z-index: 1;
    height: 120px;

    @media #{$min-width-xl} {
      height: 220px;
    }

    @media #{$tablet} {
      height: 120px;
    }

    @media #{$mobile} {
      background-size: auto;
    }
  }

  &:before {
    top: 0;
    background-color: $section-bg;
    background-image: url('@/static/images/pages/index/mobile-app/blue-wave-top-white.svg');
    background-position: right top;

    @media #{$mobile} {
      background-position: left top;
    }
  }

  &:after {
    bottom: 0;
    background-color: white;
    background-image: url('@/static/images/pages/index/mobile-app/blue-wave-bottom.svg');
    background-position: left top;
  }

  &_inner {
    position: relative;
    z-index: 3;
    display: flex;

    @media #{$mobile} {
      flex-direction: column;
    }
  }

  &_description {
    width: 50%;
    padding-top: 220px;

    @media #{$tablet} {
      display: flex;
      flex-direction: column;
    }

    @media #{$mobile} {
      width: 100%;
      padding-top: 0;
      padding-bottom: 30px;
    }

    .mob-app-section_title {
      width: 100%;
      max-width: 315px;
      position: relative;
      margin: 0 0 0 30px;
      color: $color-header-text-title;
      padding-bottom: 45px;
      text-align: left;

      @media #{$tablet} {
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
        margin: 0 auto 50px;
      }

      span {
        display: block;
        color: $color-orange;
      }
    }
  }

  &_image {
    width: 50%;
    text-align: center;
    padding: 20px;

    @media #{$mobile} {
      width: 100%;
      padding: 0;
    }

    img {
      max-width: 320px;
      min-height: 450px;
      width: 100%;
      margin: 0 auto;

      @media #{$tablet} {
        min-height: 400px;
      }

      @media #{$mobile} {
        max-width: 180px;
        min-height: auto;
        margin: 15px auto 25px;
        padding-right: 0;
      }
    }
  }

  &_links {
    width: 100%;
    max-width: 315px;
    margin: 0 0 0 30px;

    @media #{$mobile} {
      margin: 0 auto;
    }

    &--title {
      color: #bec2cd;
      position: relative;
      font-size: 14px;
      font-weight: bold;
      line-height: 2;
      letter-spacing: .35px;
      width: 100%;
      text-align: center;
      margin-bottom: 5px;

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 40%;
        background-color: #bec2cd;
        height: 1px;
        width: calc(100%/2 - 75px);
      }

      &:before {
        left: 0;
      }

      &:after {
        right: 0;
      }
    }

    &--wrapper {
      display: flex;
      justify-content: space-between;

      @media #{$mobile} {
        flex-direction: column;
      }
    }

    &--item {
      display: inline-block;
      width: 49%;

      @media #{$mobile} {
        width: 100%;
        margin-bottom: 15px;
        background: black;
        border-radius: 4px;
      }

      img {
        width: 100%;
        line-height: 0;

        @media #{$mobile} {
          width: 50%;
        }
      }
    }
  }
}

.mfa-section {
  margin-top: 30px;
  padding-top: 20px;
  background-image: url('@/static/images/pages/index/mfa-wave.svg');
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;

  @media #{$min-width-xl} {
    background-position: left top;
    background-size: cover;
  }

  .static-title {
    span {
      color: $color-orange;
    }
  }

  &_line {
    height: 1px;
    max-width: 860px;
    margin: 0 auto 55px;
    background-color: #D4D4D4;
  }

  &_line-bottom {
    display: none;
  }

  &--landing {
    .mfa-section_line {
      display: none;
    }
  }

  &--tfx-tax {
    clear: both;
    padding-top: 20px;

    .mfa-section {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: 60px;
        left: 50%;
        transform: translate(-50%, 0);
        height: 1px;
        width: calc(100% - 30px);
        max-width: 860px;
        background: #A6C5E6;
        margin: 0 auto;

        @media #{$mobile} {
          bottom: 20px;
        }
      }
    }


    .mfa-section_line-bottom {
      display: block;
      background: #A6C5E6;
      position: absolute;
      bottom: 60px;
      z-index: 1;
      width: 100%;
      margin: 0 auto;
      left: 0;
      right: 0;

      @media #{$mobile} {
        left: 20px;
        right: 20px;
        bottom: 30px;
      }
    }
  }
}

// for new Home
.mfa {
  background-color: $color-sky-blue;

  @media #{$mobile} {
    padding-bottom: 60px;
  }

  &-inner {
    display: flex;
    align-items: center;

    @media #{$mobile} {
      flex-direction: column;
    }
  }

  &-description {
    max-width: 400px;
    color: $color-grey;
    font-size: 16px;
    line-height: 22px;
    margin: 20px 0;
    overflow: hidden;

    .static-title-sub {
      text-align: left;
    }

    .static-title {
      text-align: left;
      margin-bottom: 15px;

      @media #{$mobile} {
        text-align: center;
      }
    }

    @media #{$mobile} {
      margin-top: 50px;
      margin-bottom: 0;
    }
  }

  &-pic {
    margin: 30px;

    > img {
      max-width: 100%;
    }

    @media #{$mobile} {
      margin: 15px;
    }
  }

  &--wave {
    background-color: white;
  }
}

.tfx-tax-landing {
  .mob-app-section {
    &:before {
      background-image: url('@/static/images/pages/index/mobile-app/blue-wave-top-white.svg');
    }
  }
}
