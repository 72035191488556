$color-bg-block-country-guide: #2588ed;
$color-bg-landing-country-guide: #F8620E;
$color-border-link-block-country-guide: rgba(255, 255, 255, .3);

.country-guides {
  background: $color-bg-block-country-guide;
  padding: 25px 0;

  .container {
    max-width: 920px;
  }

  .country-guide__content {
    margin: 0;
  }

  &__content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 5px 0;

    @media #{$mobile} {
      flex-direction: column;
    }
  }

  &__title {
    color: $color-white;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: -.4px;
    line-height: 1.1;
    margin: 0;
    padding: 0 0 25px;

    @media #{$mobile} {
      color: $color-white;
      font-size: 22px;
      font-weight: bold;
      letter-spacing: -.2px;
      line-height: 24px;
      padding: 0 20px 0 0;
      width: 60%;
    }
  }

  &__inner {
    @media #{$mobile} {
      display: flex;
      margin-bottom: 20px;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__photo {
    margin-left: 50px;
    max-width: 310px;
    min-width: 310px;
    position: relative;
    width: 310px;

    @media #{$mobile} {
      margin-right: 2px;
      min-width: auto;
      width: 40%;
    }

    &::after {
      border: 10px solid $color-white;
      border-radius: 4px;
      bottom: -5px;
      content: '';
      left: -5px;
      position: absolute;
      right: -5px;
      top: -5px;

      @media #{$mobile} {
        border: 5px solid $color-white;
        bottom: -2px;
        left: -2px;
        right: -2px;
        top: -2px;
      }
    }

    img {
      height: auto;
      max-width: 100%;
      width: 100%;
    }
  }

  .btn {
    min-width: 280px;

    @media #{$mobile} {
      width: 100%;
      margin-top: 20px;
    }
  }

  &__orange-bg {
    padding: 50px 0;
    background: $color-bg-landing-country-guide;

    .btn {
      min-width: auto;

      &:not(.btn-white) {
        &:hover,
        &:focus {
          color: $color-bg-landing-country-guide;
        }
      }

      + .btn {
        margin-left: 15px;

        @media #{$mobile} {
          margin-left: 0;
        }
      }
    }

    .btn-white {
      color: $color-bg-landing-country-guide;

      &:hover,
      &:focus {
        color: white;
      }
    }

    .country-guides__title {
      padding-bottom: 0;
      font-size: 34px;
      letter-spacing: 0;

      @media #{$mobile} {
        width: 100%;
        line-height: 1.1;
        text-align: center;
        margin-bottom: 40px;
      }
    }

    .country-guides__content {
      @media #{$mobile} {
        align-items: flex-start;
      }
    }
  }

  &__btn-group {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media #{$mobile} {
      width: 100%;
      flex-direction: column;
    }
  }
}

.landing-body,
.styled-page-graphic__head--about-us {
  .country-guides {
    background: $color-bg-landing-country-guide;
    padding: 25px 0;
    margin-bottom: 80px;

    &__title {
      @media #{$mobile} {
        float: left;
      }
    }

    &__photo {
      @media #{$mobile} {
        margin-left: 60%;
      }
    }
  }

  .video-testimonials--main {
    padding-bottom: 0;

    &:after {
      display: none;
    }
  }

  .on-boarding-team {
    padding-bottom: 60px;

    &:after {
      display: none;
    }
  }
}
