$client-review-border-color: #dcdcdc;
$client-review-text-color: #b7babe;
$client-review-placeholder-color: #aeaeae;

.client-review {
  background:
    url('@/static/images/pages/client-review/cover-blue.svg') no-repeat 50% 0,
    url('@/static/images/pages/client-review/cover-orange.svg') no-repeat 50% 0;
  padding: 40px 0 100px;
  position: relative;

  @media #{$lg} {
    background-size: contain;
    padding: 80px 0 63px;
  }

  @media #{$tablet} {
    padding: 80px 10px 63px;
  }

  &__content {
    background-color: $color-white;
    border-radius: 4px;
    box-shadow: $box-shadow-image;
    margin: 0 auto;
    max-width: 700px;
    padding: 73px 40px 40px;
    position: relative;
    text-align: center;

    @media #{$tablet} {
      max-width: none;
      width: 100%;
    }
  }

  &__title {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: -.1px;
    line-height: 40px;
    margin: 0 0 10px;
    text-align: center;
  }

  &__desc {
    color: $color-grey;
    font-size: 17px;
    letter-spacing: .2px;
    line-height: 1;
    margin: 0 0 27px;
    text-align: center;
  }

  &__form {
    textarea.input {
      min-height: 105px;
      padding-top: 15px;
      text-align: left;

      &::placeholder {
        color: $client-review-placeholder-color;
      }

      &:focus {
        &::placeholder {
          color: transparent;
        }
      }
    }

    .form-group__styled {
      margin: 0 0 20px;
    }
  }

  &__form--wrapper-btn {
    display: flex;
    justify-content: flex-end;
  }

  &__wrapper-stars {
    display: inline-block;
    margin: 0 50px 55px 0;
    position: relative;
    width: 210px;
  }

  &__image {
    position: absolute;
    right: -80px;
    top: -24px;
  }

  &__stars {
    align-items: center;
    border: 2px dashed $client-review-border-color;
    border-radius: 4px 0 0 4px;
    display: flex;
    height: 73px;
    justify-content: center;
    padding: 0 40px 2px 0;
  }

  &__stars-text {
    color: $client-review-text-color;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: .2px;
    line-height: 1;
    margin: 0 0 5px;
    text-align: left;
    text-transform: uppercase;
  }

  &__rating {
    align-items: center;
    display: flex;
    height: 35px;
    width: 150px;

    &.rating-1 {
      .client-review__rating-item {
        &:first-child {
          background: url('@/static/images/pages/client-review/star-active.png') no-repeat 50% 70%;
          background-size: 28px;
        }
      }
    }

    &.rating-2 {
      .client-review__rating-item {
        &:first-child,
        &:nth-child(2) {
          background: url('@/static/images/pages/client-review/star-active.png') no-repeat 50% 70%;
          background-size: 28px;
        }
      }
    }

    &.rating-3 {
      .client-review__rating-item {
        &:first-child,
        &:nth-child(2),
        &:nth-child(3) {
          background: url('@/static/images/pages/client-review/star-active.png') no-repeat 50% 70%;
          background-size: 28px;
        }
      }
    }

    &.rating-4 {
      .client-review__rating-item {
        &:not(:last-child) {
          background: url('@/static/images/pages/client-review/star-active.png') no-repeat 50% 70%;
          background-size: 28px;
        }
      }
    }

    &.rating-5 {
      .client-review__rating-item {
        background: url('@/static/images/pages/client-review/star-active.png') no-repeat 50% 70%;
        background-size: 28px;
      }
    }
  }

  &__rating-item {
    background: url('@/static/images/pages/client-review/star.png') no-repeat 50% 50%;
    background-size: 32px;
    height: 35px;
    margin: 0 2px;
    width: 35px;
  }
}
