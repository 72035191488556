$service-item-width: 254px;

.who-we-help {
  .container {
    overflow: visible !important;
  }

  &_inner {
    text-align: center;
    width: 100%;

    &:before,
    &:after {
      display: none;
    }
  }

  &_item-wrapper {
    position: relative;
    width: $service-item-width;
    min-width: $service-item-width;
    display: inline-block;
    min-height: 300px;
    margin: 12px;
    top: 0;
    left: 0;

    @media #{$mobile} {
      $service-item-width: 130px;
      width: $service-item-width;
      min-width: $service-item-width;
      min-height: 200px;
    }
  }

  &_item {
    width: 100%;
    background-color: white;
    border-radius: 8px;
    display: inline-flex;
    flex-direction: column;
    padding: 22px;
    transition: height .4s ease;
    position: absolute;
    z-index: 1;
    min-height: 300px;
    top: 0;
    left: 0;

    &:hover {
      box-shadow: 3px 8px 17px rgba(9, 19, 35, .24);
      z-index: 11;
      min-height: 440px;

      .who-we-help_text {
        display: block;
      }

      @media #{$mobile} {
        box-shadow: none;
        min-height: unset;

        .who-we-help_text {
          display: none;
        }
      }
    }

    @media #{$mobile} {
      padding: 0;
      min-height: 200px;
    }
  }

  &_title {
    margin-top: 15px;
    font-weight: bold;
    font-size: 25px;
    line-height: 32px;
    text-align: center;
    letter-spacing: .2px;
    color: $color-title;

    @media #{$mobile} {
      font-size: 20px;
      line-height: 26px;
      letter-spacing: 0;
    }
  }

  &_text {
    display: none;
    transition: all .4s ease;
    font-size: 16px;
    line-height: 22px;
    color: $color-grey;
    margin-top: 3px;
  }

  .btn {
    max-width: 280px;
    width: 100%;
    margin: 30px auto;
  }
}
