//pagination

.pagy-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .page {
    display: inline-block;
    width: 40px;
    height: 40px;
    font-size: 16px;
    color:  #2586EA;
    font-weight: bold;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    user-select: none;
    border-radius: 4px;
    background-color: transparent;
    transition: all 0.15s ease-in-out;
    border: 1px solid #2586EA;
    margin: 0 4px;

    > a {
      color:  #2586EA;
      display: inline-block;
      height: 100%;
      width: 100%;
    }

    &:not(.gap):hover,
    &.active {
      background-color: $color-blue;
      color: white;

      a {
        color: white;
      }
    }

    &.gap {
      color: #D4D4D4;
      border-color: #D4D4D4;
    }

    &.disabled {
      display: none;
    }

    &.next,
    &.prev {
      padding: 0 12px;
      width: auto;
      border: 0;


    }
  }
}
