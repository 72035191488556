.about-compare {
  padding: 0 0 75px;

  .static-title {
    font-size: 45px;
    letter-spacing: -.5px;
    margin: 0 0 15px;
    text-align: center;
  }

  .container {
    max-width: 1240px;
  }
}

.about-compare__sub-title {
  color: $color-grey;
  font-size: 25px;
  margin: 0 0 30px;
  text-align: center;
}
