$styled-input-border-color: #e0e2ea;

.form-group__styled {
  .v-select {
    font-family: 'PT Sans', sans-serif;

    .vs__selected-options {
      padding: 0;
    }

    input[type="search"],
    input[type="search"]:focus {
      background: none;
      box-shadow: none;
      display: block;
      font-size: 16px;
      line-height: 24px;
      margin: 0;
      max-width: 100%;
      padding: 18px 50px 0 16px;
      position: relative;
      width: 100% !important;
      padding: 0;
    }

    &.searchable {
      input[type="search"] {
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .selected-tag {
      border: 0;
      color: $color-grey;
      cursor: pointer;
      display: block;
      float: none;
      font-size: 16px;
      height: 52px;
      line-height: 24px;
      margin: 0;
      overflow: hidden;
      padding: 24px 45px 0 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.single {
      &.open {
        .selected-tag {
          opacity: 1;
          position: relative;
        }
      }
    }

    .open-indicator {
      background: url('@/static/images/icons/default/icon-select-arrow.png') no-repeat;
      bottom: inherit;
      height: 8px;
      margin-top: -4px;
      position: absolute;
      right: 16px;
      top: 50%;
      width: 14px;

      .form-group__styled_has-value & {
        top: 36px;
      }

      &::before {
        display: none;
      }
    }

    .dropdown-menu {
      border: solid 2px $color-blue;
      border-radius: 0 0 4px 4px;
      border-top: 0;
      box-shadow: none;
      left: -2px;
      margin: -2px 0 0;
      width: calc(100% + 4px);

      >.highlight>a {
        background: $color-blue;
        color: $color-white;
      }

      li {
        overflow: hidden;
        width: 100%;

        a {
          color: $color-grey;
          font-family: 'PT Sans', sans-serif;
          font-size: 16px;
          overflow: hidden;
          padding: 3px 16px;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
        }
      }

      .active>a {
        background: $color-blue;
        color: $color-white;
      }
    }

    .dropdown-toggle {
      border: 0;
      min-height: 52px;
      padding: 0;

      &::after {
        display: none;
      }

      .clear {
        display: none;
      }
    }

    .no-options {
      color: $color-grey;
      font-size: 16px;
      text-align: center;
    }
  }

  input[type="text"].v-select__input {
    height: 1px;
    left: 0;
    margin: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 0;
    width: 1px;
  }
}