.educators-abroad {
  width: 100%;
  background: $color-blue;
  padding: 50px 0 60px;
  margin: 0 0 50px;
  text-align: center;

  @media #{$mobile} {
    padding: 40px 0 50px;
    margin: 0 0 40px;
  }

  &__content {
    max-width: 800px;
    padding: 0 10px;
    color: $color-white;
    margin: 0 auto;
    font-size: 25px;
    line-height: 32px;

    @media #{$mobile} {
      font-size: 19px;
      line-height: 24px;
    }

    &-title {
      font-size: 34px;
      line-height: 40px;
      margin: 0 0 15px;
      font-weight: 700;
      color: $color-white;
    }
  }

  &__countries {
    background: $color-white;
    border-radius: 4px;
    box-shadow: 3px 0 7px 0 rgba(0, 0, 0, .1), 9px 19px 35px 0 rgba(0, 0, 0, .14);
    width: 100%;
    max-width: 900px;
    padding: 20px 60px;
    margin: 60px auto 0;
    position: relative;

    @media #{$mobile} {
      width: 100%;
      max-width: unset;
      padding: 20px 30px;
      margin: 60px auto 15px;
    }
  }

  &__tab {
    position: absolute;
    top: -26px;
    height: 29px;
    width: 165px;
    background: url('@/static/images/pages/we-help/educators-abroad/combined-shape.svg') no-repeat 50% 0;
    color: $color-white;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.first-tab {
      left: 35px;
    }

    &.second-tab {
      right: 35px;
    }

    &.active {
      background: url('@/static/images/pages/we-help/educators-abroad/combined-shape-white.svg') no-repeat 50% 50%;
      color: $color-blue;
    }

    @media #{$mobile} {
      &.first-tab {
        left: 31px;
      }

      &.second-tab {
        right: 31px;
      }
    }
  }

  &__slider {
    overflow: hidden;

    @media #{$mobile} {
      overflow-y: hidden;
      width: 312px;
      margin: 0 auto;
    }
  }

  &__countries-list {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    transition: left ease .3s;

    &.row-list {
      left: 0 !important;
    }

    &.full-list {
      width: 3900px;
    }
  }

  &__arrow {
    position: absolute;
    width: 20px;
    height: 20px;
    border: solid #d4d4d4;
    border-width: 0 4px 4px 0;
    top: 50%;
    margin-top: -10px;
    cursor: pointer;

    &.left {
      left: 25px;
      transform: rotate(-225deg);
    }

    &.right {
      right: 25px;
      transform: rotate(-45deg);
    }

    &:hover {
      border-color: darken(#d4d4d4, 15%);
    }

    @media #{$mobile} {
      &.left {
        left: 15px;
      }

      &.right {
        right: 15px;
      }
    }
  }

  &__country {
    width: 146px;
    height: 135px;
    font-size: 11px;
    line-height: 13px;
    font-weight: 700;
    color: transparent;
    transition: color ease .2s;
    margin: 0 5px;

    &:hover {
      color: $color-orange;

      .educators-abroad__country-image {
        border-color: $color-orange;
      }
    }

    @media #{$mobile} {
      height: 100px;
      margin: 5px;
      position: relative;
      display: block;

      > div:first-of-type {
        display: none;
      }

      &.active {
        &:before,
        &:after {
          position: absolute;
          content: '';
          left: 50%;
          margin-left: -4px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 8px 8px 8px;
        }

        &:before {
          bottom: -8px;
          border-color: transparent transparent $color-white;
          z-index: 2;
        }

        &:after {
          bottom: -5px;
          border-color: transparent transparent $color-orange;
          z-index: 1;
        }
      }
    }
  }

  &__country-image {
    height: 90px;
    margin: 3px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid transparent;
    border-radius: 4px;
    transition: border-color ease .2s;
    overflow: hidden;

    @media #{$mobile} {
      height: 100%;
      width: 100%;

      img {
        height: 72px;
        width: auto;
        object-fit: cover;
      }
    }
  }

  // Map

  &__map {
    margin: 40px auto;
    max-width: 780px;
    height: 395px;
    background: url('@/static/images/pages/we-help/educators-abroad/map.svg') no-repeat 50% 50%;
    position: relative;

    @media #{$tablet} {
      display: none;
    }
  }

  &__map-link {
    font-weight: 700;
    position: relative;
    display: inline-block;
    padding-right: 15px;
    line-height: 16px;
    margin: 2px 0 0;

    &:after {
      position: absolute;
      content: '';
      top: 50%;
      margin-top: -5px;
      right: 0;
      width: 10px;
      height: 10px;
      border: solid $color-blue;
      border-width: 0 2px 2px 0;
      transform: rotate(-45deg);
    }

    &:hover {
      color: darken($color-blue, 10%);

      &:after {
        border-color: darken($color-blue, 10%);
      }
    }
  }

  &__map-item {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: $color-white;
    box-shadow: 0 6px 7px 0 rgba(0, 0, 0, .2);
    border: 1px solid rgba(37, 136, 237, .66);
    cursor: pointer;

    &:hover {
      border-color: rgba(255, 255, 255, .66);
      background: $color-orange;

      .educators-abroad__map-tooltip-container {
        display: block;
      }
    }
  }

  &__info {
    border: 2px dashed $color-white;
    padding: 25px;
    border-radius: 4px;
    font-size: 17px;
    line-height: 22px;

    a {
      color: $color-white;
      font-weight: 700;

      &:hover {
        text-decoration: underline;
      }
    }

    @media #{$mobile} {
      margin: 50px 0 0;
    }
  }

  &__map-image {
    width: 50px;
    min-width: 50px;
    height: 50px;
    margin: 0 10px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__map-tooltip-container {
    display: none;
    padding: 28px 18px 0;
    margin-left: -10px;
    position: relative;
    z-index: 3;
  }

  &__map-tooltip {
    position: absolute;
    top: 100%;
    left: 50%;
    margin: 0 0 0 -144px;
    width: 286px;
    align-items: center;
    justify-content: flex-start;
    border-radius: 4px;
    box-shadow: 5px 0 20px 0 rgba(0, 0, 0, .2);
    background: $color-white;
    padding: 15px 20px;
    font-size: 14px;
    line-height: 20px;
    color: $color-grey;
    letter-spacing: .2px;
    text-align: left;
    display: flex;
    border-top: 2px solid $color-orange;

    &:before,
    &:after {
      position: absolute;
      content: '';
      left: 50%;
      margin-left: -8px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8px 8px 8px;
    }

    &:before {
      top: -6px;
      border-color: transparent transparent $color-white;
      z-index: 2;
    }

    &:after {
      top: -8px;
      border-color: transparent transparent $color-orange;
      z-index: 1;
    }
  }
}


.static-page__content {
  &.educators-abroad-content {
    margin: 0 0 50px;

    @media #{$mobile} {
      margin: 0 0 40px;
    }
  }

  &__slider-btn {
    @media #{$mobile} {
      position: absolute;
      bottom: -35px;
      left: 0;
      right: 0;
      color: $color-white;
      font-size: 14px;
      font-weight: 700;
    }
  }
}

// Countries

.item-brazil {
  top: 250px;
  left: 195px;
}

.item-italy {
  top: 105px;
  left: 377px;
}

.item-singapore {
  top: 226px;
  left: 656px;
}

.item-egypt {
  top: 145px;
  left: 425px;
}

.item-south-africa {
  top: 310px;
  left: 400px;
}

.item-united-arab-emirates {
  top: 160px;
  left: 492px;
}

.item-japan {
  top: 116px;
  left: 695px;
}

.item-kosova {
  top: 112px;
  left: 405px;
}

.item-nigeria {
  top: 200px;
  left: 360px;
}

.item-mexico {
  top: 160px;
  left: 70px;
}

.item-germany {
  top: 75px;
  left: 380px;
}

.item-thailand {
  top: 173px;
  left: 610px;
}

.item-ukraine {
  top: 79px;
  left: 427px;
}

.item-taiwan {
  top: 155px;
  left: 660px;
}

.item-dubai {
  top: 155px;
  left: 484px;
}

.item-australia {
  top: 300px;
  left: 695px;
}

.item-turkey {
  top: 108px;
  left: 427px;
}

.item-panama {
  top: 199px;
  left: 118px;
}

.item-belgium {
  top: 77px;
  left: 350px;
}

.item-china {
  top: 120px;
  left: 600px;
}

.item-argentina {
  top: 310px;
  left: 180px;
}

.item-nanjing {
  top: 145px;
  left: 620px;
}

.item-korea {
  top: 107px;
  left: 663px;
}

.item-switzerland {
  top: 89px;
  left: 370px;
}

.item-malta {
  top: 119px;
  left: 385px;
}
