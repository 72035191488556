@font-face {
  font-family: 'PT Sans';
  font-weight: 400;
  src: url('~tfx-common/styles/fonts/pt-sans-v12-latin-regular.woff') format('woff'),
       url('~tfx-common/styles/fonts/pt-sans-v12-latin-regular.ttf') format('truetype'),
       url('~tfx-common/styles/fonts/pt-sans-v12-latin-regular.svg#PTSans') format('svg');
  font-display: swap;
}

@font-face {
  font-family: 'PT Sans';
  font-weight: 700;
  src: url('~tfx-common/styles/fonts/pt-sans-v12-latin-700.woff') format('woff'),
       url('~tfx-common/styles/fonts/pt-sans-v12-latin-700.ttf') format('truetype'),
       url('~tfx-common/styles/fonts/pt-sans-v12-latin-700.svg#PTSans') format('svg');
  font-display: swap;
}

* {
  font-family: "PT Sans", sans-serif;
}
