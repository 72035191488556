.refund__page {
  padding: 130px 0 0;
  position: relative;

  .static-title {
    margin: 0 0 45px;
    text-align: center;
  }

  .container {
    max-width: 1200px;
  }
}

// IRS

.refund__irs {
  background: url('@/static/images/pages/dealing-irs-101/refund/irs.jpg') no-repeat 50% 0;
  background-size: cover;
  color: $color-white;
  font-size: 25px;
  line-height: 1.2;
  margin: 0 0 90px;
  padding: 35px 70px 40px;
  text-align: center;

  @media #{$tablet} {
    padding: 35px 30px 40px;
  }
}

.refund__irs-content {
  background: $color-white;
  border-radius: 4px;
  color: $color-grey;
  font-size: 16px;
  line-height: 1.31;
  margin: 35px 0 40px;
  padding: 60px 50px 30px;

  @media #{$tablet} {
    padding: 60px 20px 30px;
  }
}

.refund__irs-descr {
  margin: 0 auto;
  max-width: 570px;

  a {
    font-weight: 700;
    text-decoration: underline;

    &:hover {
      color: $color-blue;
      text-decoration: none;
    }
  }
}

.refund__irs-descr-item {
  margin: 0 0 25px;
}

.refund__irs-descr-item--grey {
  color: $color-light-grey;
}

.refund__irs-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0 40px;
  position: relative;

  &::after {
    background: rgba(184, 184, 184, .6);
    content: '';
    height: 1px;
    left: 70px;
    position: absolute;
    right: 70px;
    top: 66px;
    z-index: 1;
  }
}

.refund__irs-text-list {
  display: flex;
  justify-content: flex-start;
  left: 0;
  position: absolute;
  right: 0;
  top: 35px;

  @media #{$md} {
    justify-content: space-between;
  }

  @media #{$tablet} {
    display: none;
  }
}

.refund__irs-text-list-item {
  color: $color-title;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: 1.7px;
  padding: 0 0 0 125px;
  text-align: center;

  &:nth-child(2) {
    padding: 0 0 0 140px;

    @media #{$md} {
      padding: 0;
    }
  }

  &:nth-child(3) {
    padding: 0 0 0 220px;

    @media #{$md} {
      padding: 0 145px 0 20px;
    }
  }

  @media #{$md} {
    padding: 0 0 0 135px;
  }
}

.refund__irs-item {
  &:last-child {
    margin-left: 120px;

    @media #{$md} {
      margin: 0;
    }
  }
}

.refund__irs-item-image {
  align-items: center;
  border: 2px solid $color-orange;
  border-radius: 50%;
  display: flex;
  height: 50px;
  justify-content: center;
  margin: 0 auto 30px;
  position: relative;
  width: 50px;

  &::after {
    background: $color-orange;
    border-radius: 3px;
    bottom: -22px;
    content: '';
    height: 7px;
    left: 50%;
    margin-left: -14px;
    position: absolute;
    width: 28px;
    z-index: 2;
  }
}

.refund__irs-item-title {
  color: $color-orange;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.2;
  margin: 0 auto;
  text-align: center;
  width: 140px;
}

// Steps

.refund__steps {
  border-bottom: 2px solid $color-border;
  margin: 0 0 70px;
  padding: 0 0 60px;
}

.refund__step {
  display: flex;
  padding: 0 0 40px;
  position: relative;

  &:last-child {
    .refund__step-line {
      display: none;
    }
  }
}

.refund__step-line {
  bottom: 0;
  left: 48px;
  position: absolute;
  top: 100px;
  width: 4px;

  &::after {
    border-right: 4px dashed $color-blue;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    top: 4px;
    z-index: 2;
  }
}

.refund__step-circle {
  background: $color-white;
  border-radius: 50%;
  height: 40px;
  left: -18px;
  position: absolute;
  width: 40px;
  z-index: 1;

  &::after {
    background: $color-blue;
    border-radius: 50%;
    content: '';
    height: 20px;
    left: 10px;
    position: absolute;
    top: 10px;
    width: 20px;
    z-index: 3;
  }
}

.refund__step-circle--top {
  top: -20px;
}

.refund__step-circle--bottom {
  bottom: -20px;
}

.refund__step-number {
  align-items: center;
  background: $color-orange;
  border-radius: 4px 0 0 4px;
  color: $color-white;
  display: flex;
  font-size: 20px;
  font-weight: 700;
  height: 100px;
  justify-content: center;
  letter-spacing: -.2px;
  margin: 0 20px 0 0;
  min-width: 100px;
  width: 100px;
}

.refund__step-content {
  background: $color-lightest-grey;
  border-radius: 0 4px 4px;
  color: $color-grey;
  font-size: 16px;
  line-height: 1.31;
  padding: 35px 40px;
  width: 100%;

  .btn {
    margin-left: 20px;
    width: 288px;
  }

  p {
    margin: 0;
  }
}

.refund__step-link {
  text-decoration: underline;

  &:hover {
    color: $color-blue;
    text-decoration: none;
  }
}

.refund__step-title {
  font-size: 25px;
  font-weight: 700;
  line-height: 1;
  margin: 0 0 10px;
  text-align: left;
}

.refund__step-top {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.refund__step-bottom {
  border-top: 2px solid #d0d1d5;
  margin: 35px 0 0;
}

.refund__step-list {
  padding: 30px 0 15px;
}

.refund__step-list-item {
  font-size: 20px;
  padding: 0 0 20px 20px;
  position: relative;

  &::before {
    background: $color-blue;
    border-radius: 50%;
    content: '';
    height: 10px;
    left: 0;
    position: absolute;
    top: 8px;
    width: 10px;
  }
}

// Info

.refund__info {
  padding: 0 0 130px;
}

.refund__info-sub-title {
  color: $color-grey;
  font-size: 25px;
  margin: 0;
  padding: 10px 0 50px;
  text-align: center;
}

.refund__info-links {
  display: flex;
  flex-wrap: wrap;

  @media #{$tablet} {
    display: block;
  }
}

.refund__info-link {
  align-items: center;
  border: 2px solid $color-border;
  border-radius: 4px;
  color: $color-grey;
  display: flex;
  font-size: 16px;
  line-height: 1.31;
  margin: 0 30px 20px 0;
  min-height: 48px;
  padding: 12px 17px;
  position: relative;
  width: calc(50% - 15px);

  &::after {
    @include arrow_in_block($color-blue, 45deg);

    margin-top: -5px;
    right: 20px;
    top: 50%;
  }

  &:nth-child(2n) {
    margin-right: 0;
  }

  &:hover {
    border-color: $color-blue;
    color: $color-blue;
  }

  @media #{$tablet} {
    margin: 0 0 20px;
    width: 100%;
  }
}
