.expatriation-services__head {
  margin: 0;

  .base-page-head__column--text {
    max-width: 540px;
  }

  .base-page-head__column--image {
    padding-top: 115px;
    padding-left: 30px;

    @media #{$tablet} {
      padding-top: 80px;
    }
  }
}

.expatriation-services__head-image {
  min-width: 380px;

  @media #{$tablet} {
    min-width: 280px;
  }
}

.tfx-system--expatriation {
  .icon-tag {
    background: url('@/static/images/icons/default/icon-tag.svg') no-repeat 50% 50%;
    height: 30px;
    width: 30px;
  }

  .icon-clock {
    background: url('@/static/images/icons/default/icon-clock.svg') no-repeat 50% 50%;
    height: 30px;
    width: 30px;
  }

  .icon-tag-opacity {
    background: url('@/static/images/icons/default/icon-tag-opacity.svg') no-repeat 50% 50%;
    height: 20px;
    width: 20px;
  }

  .icon-clock-opacity {
    background: url('@/static/images/icons/default/icon-clock-opacity.svg') no-repeat 50% 50%;
    height: 20px;
    width: 20px;
  }
}
