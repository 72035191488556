$tablet: 'screen and (min-width: 1024px) and (max-width: 1200px)';
$mobile: '(max-width: 1023px)';
$ie: 'screen\0';

$border-radius:            4px;
$bd-color-black-light:     #00141d;
$bd-color-slate-darker:    #3b4858;
$bd-color-black-text:      #404040;
$bd-color-grey-lightest:   #F4F4F4;
$bd-color-white-middle:    #e7e7e7;
$bd-color-grey-darken:     #9b9b9b;
$bd-color-grey-darkest:    #546271;
$bd-color-white:           #fff;
$bd-color-slate-darker:    #3c4858;
$bd-color-slate:           #43536e;
$bd-color-grey-lighter:    #F0F7FE;
$bd-color-dark-text:       #303030;
$bd-color-grey-border:     #D8DADE;
$br-color-light-gray:      #EBEDEE;
$bd-color-grey-alto:       #d9d9d9;
$bd-color-blue-light:      #6ac6ff;
$bd-color-blue-dark:       #1f8efa;
$bd-color-blue-curious:    #2586ea;
$bd-color-btn-hvr:         #1a6dbd;
$bd-color-light-blue:      #F0F7FE;
$bd-color-light-violet:    #EAEFFA;
$color-dark-blue:          #1f4159;
$bd-color-pizzas:          #ff8c00;
$color-dark-link:          #1A20AB;

@mixin transition($property: all, $time: .2s, $timing: ease-in-out) {
  transition: $property $time $timing;
}

@mixin invisibility() {
  opacity: 0;
  visibility: hidden;
}

@mixin visibility() {
  opacity: 1;
  visibility: visible;
}

%ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
