$color-box-shadow-modal-authorization: rgba(0, 0, 0, .44);
$height-modal-authorization: 480px;

.modal-authorization {
  margin-top: calc(50vh - calc(#{$height-modal-authorization} / 2));
  width: 420px;

  .close {
    background: url('@/static/styles/images/icons/default/icon-close-modal.svg') no-repeat 50% 50%;
    height: 20px;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 20px;
  }

  .modal-content {
    border-radius: 15px;
    box-shadow: 5px 7px 24px $color-box-shadow-modal-authorization;
    min-height: $height-modal-authorization;
    padding: 35px 20px 30px;
    width: 100%;
  }

  &__logo {
    margin: 0 auto 35px;
  }

  &__wrapper-button {
    padding: 25px 0 20px;

    .btn {
      min-width: 300px;
    }
  }

  &__remember-me {
    input {
      float: left;
      margin: 6px 5px 0 0;
      outline: none;
    }

    label {
      cursor: pointer;
      margin: 0;
    }
  }

  a {
    color: $color-orange;

    &:hover {
      color: darken($color-orange, 10%);
    }
  }
}