@mixin block-height {
  height: 420px;

  @media #{$mobile} {
    height: 320px;
  }
}

@mixin white-gradient {
  background: linear-gradient(180deg, #FFFFFF 0.05%, rgba(255, 255, 255, 0) 96.44%);
}

@mixin blue-gradient {
  background: linear-gradient(180deg, #e9f3fd 0.05%, rgba(255, 255, 255, 0) 96.44%);
}

@keyframes bgImg {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 -10000%;
  }
}

.our-experts-index {
  margin-top: 30px;
  margin-bottom: 50px;

  .container {
    height: 100%;
    min-height: inherit;
    display: flex;

    @media #{$tablet} {
      flex-direction: column;
    }
  }
}

.our-experts {
  width: 100%;
  max-width: 600px;
  margin: 0 30px;
  overflow: hidden;
  position: relative;
  background: transparent;
  @include block-height;

  @media #{$tablet} {
    margin: auto;
  }

  &__shadow {
    width: 100%;
    overflow: hidden;
    position: relative;

    @media #{$tablet} {
      width: 100%;
      margin-bottom: 40px;
    }

    @include block-height;

    &:after,
    &:before {
      @include white-gradient;
      content: '';
      height: 150px;
      width: 100%;
      position: absolute;
    }

    &:after {
      top: -5px;
      left: 0;
    }

    &:before {
      bottom: -2px;
      left: 0;
      z-index: 3;
      transform: rotate(180deg);
    }
  }

  &__image {
    animation: bgImg 5000s linear infinite;
    height: 100%;
    background-size: cover;
    position: relative;
  }

  &__description {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 30px;
    max-width: 410px;
    @include block-height;

    @media #{$mobile} {
      margin-top: 0;
    }

    .static-title {
      text-align: left;

      @media #{$mobile} {
        text-align: center;
      }
    }

    @media #{$tablet} {
      height: auto;
      padding: 0 0 30px 0;
      margin-top: 20px;
      min-height: unset;
      text-align: center;
      margin-left: 0;
      max-width: unset;
      align-items: center;
    }

    .btn {
      max-width: 280px;

      @media #{$extra-small} {
        width: 100%;
      }
    }
  }

  &__text {
    color: $color-grey;
    font-size: 16px;
    line-height: 22px;
    margin: 20px 0;
    overflow: hidden;

    @media #{$tablet} {
      text-align: center;
    }
  }
}

// for new Home
.page__home--waves {
  .our-experts-index {
    .our-experts__shadow {
      height: 480px;

      @media #{$mobile} {
        height: 320px;
        margin: 0 auto;
      }

      &:after,
      &:before {
        @include blue-gradient;
      }

      &:before {
        bottom: 50px;

        @media #{$mobile} {
          bottom: -1px;
        }
      }
    }

    @media #{$mobile} {
      background-color: $color-sky-blue;
    }
  }
}

.our-experts-landing {
  position: relative;
  z-index: 1;
  background-color: $section-bg;
  padding-top: 70px;
  padding-bottom: 80px;

  @media #{$mobile} {
    padding-bottom: 60px;
  }

  &:after {
    position: absolute;
    content: '';
    width: 100%;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    background-color: white;
    background-image: url('@/static/images/pages/index/mobile-app/blue-wave-bottom.svg');
    background-position: left bottom;
    z-index: 1;
    height: 145px;

    @media #{$lg} {
      height: 115px;
    }

    @media #{$mobile} {
      height: 90px;
      background-size: auto;
      background-position: left bottom;
    }
  }

  .our-experts-index {
    margin: 0;
    background-color: $section-bg;

    .our-experts {
      height: 490px;

      @media #{$mobile} {
        height: 320px;
      }
    }

    .our-experts__shadow {
      height: 540px;

      @media #{$mobile} {
        height: 320px;
        max-width: 400px;
        margin: 0 auto;
      }

      &:after,
      &:before {
        @include blue-gradient;
      }

      &:before {
        bottom: 115px;

        @media #{$mobile} {
          bottom: -1px;
        }
      }
    }
  }

  &--pricing {
    .our-experts-index {
      margin-bottom: 0;
      padding-bottom: 0;

      .our-experts__shadow {
        @media #{$mobile} {
          max-width: 400px;
          height: 320px;
          margin: 0 auto;
        }
      }
    }

    .container {
      flex-direction: row-reverse;

      @media #{$mobile} {
        flex-direction: column;
      }

      .our-experts__description {
        padding-left: 0;
        padding-right: 30px;

        @media #{$mobile} {
          padding-right: 0;
        }
      }
    }
  }
}
