$styled-input-color: #656b6f;
$styled-input-label-color: #aeaeae;
$styled-input-border-color: #e3e3e3;
$form-group__styled_focused-box-shadow: rgba(170, 170, 170, .62);
$form-input-indent : 13px;

.form-group__styled {
  border-radius: 4px;
  margin: 0 0 11px;
  min-height: 56px;
  position: relative;
  transition: border .15s cubic-bezier(1, .5, .8, 1);
  width: 100%;
  line-height: 1;

  &.tfx-tooltip {
    position: relative;

    &.show {
      &::after {
        content: attr(data-tooltip);
        line-height: 1;
        bottom: -31px;
      }
    }
  }

  .label {
    background: $color-white;
    color: $color-light-black;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    margin: 0;
    padding-bottom: 5px;
    transition: all ease-in-out .2s;

    span {
      color: $color-orange;
    }

    &__select {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: calc(100% - 50px);
    }

    &__select--vue {
      background: transparent;
      pointer-events: none;
    }
  }

  .input {
    background-color: $color-white;
    border-radius: 4px;
    color: $styled-input-color;
    display: block;
    font-size: 16px;
    line-height: 1;
    height: auto;
    outline: none;
    padding: $form-input-indent;
    text-indent: 0;
    width: 100%;
    border: solid 1px $styled-input-border-color;

    &__select {
      display: none;

      &::-ms-expand {
        display: none;
      }
    }

    &::-webkit-contacts-auto-fill-button {
      display: none !important;
      pointer-events: none;
      position: absolute;
      right: 0;
      visibility: hidden;
    }
  }

  textarea {
    &.input {
      padding-top: 25px;
    }
  }
}

.form-group__styled-with-error,
.input-phone__wrapper.validate-msg,
.validate.validate-msg,
.input-password.validate-msg {

  .label {
    color: $color-orange;
  }

  .input {
    border-color: $color-orange;
  }

  .validate-msg,
  .error {
    position: absolute;
    border: 1px solid $color-orange;
    color: $color-orange;
    box-shadow: none;
    padding: 6px 4px;
    border-radius: 4px;
    z-index: 1;
    background: $color-white;
    top: calc(100% + 5px);
    right: 0;
    transition: all 0.3s ease-in-out;
    line-height: 8px;

    &--phone {
      top: -30px
    }

    &::before {
      border-bottom: 5px solid $color-orange;
      border-left: solid 5px transparent;
      border-right: solid 5px transparent;
      content: '';
      display: block;
      height: 0;
      top: -6px;
      right: 25%;
      opacity: 1;
      position: absolute;
      transform: rotate(0deg);
    }
  }
}

.form-group__styled__phone {
  margin-left: 75px;
  width: calc(100% - 75px);

  .intl-tel-input {
    width: 100%;

    .flag-container {
      left: -75px;

      &:hover {
        .selected-flag {
          background-color: transparent;
        }
      }
    }

    input[type="tel"] {
      padding-left: 16px;
      width: 100%;
    }

    .selected-flag {
      outline: none;
      width: 62px;

      .iti-arrow {
        background-color: $color-white;
        background-image: url('@/static/images/icons/default/icon-select-arrow.png');
        background-position: 50% 50%;
        background-repeat: no-repeat;
        border: 0;
        height: 8px;
        margin-top: -4px;
        right: 0;
        width: 14px;
      }

      .iti-flag {
        transform: scale(1.5);
      }
    }
  }
}

.form-group__styled_has-value {
  .label__select {
    width: calc(100% - 50px);
  }

  .input {
    &.input__select {
      background-color: $color-white;
      background-image: url('@/static/images/icons/default/icon-select-arrow.png');
      background-position: calc(100% - 16px) 73%;
      background-repeat: no-repeat;
      padding-top: 20px;
    }
  }
}

.form-group__styled_focused {
  .label {
    color: $color-blue;
  }

  .input {
    border-color: $color-blue;
  }

  .btn-show-password {
    background-image: url('@/static/styles/images/icons/default/icon-btn-show-password.svg');

    &.active {
      background-image: url('@/static/styles/images/icons/default/icon-btn-hide-password.svg');
    }
  }

  &.validate-msg {
    .input {
      border-color: $color-blue;
    }
  }
}

.form-group__styled-error {
  color: $color-orange;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: .2px;
  line-height: 12px;
  margin-top: 5px;
  padding-bottom: 2px;
  padding-left: 5px;
  position: absolute;
  right: -2px;
  top: 100%;
  white-space: nowrap;
  z-index: 9;

  .form-group__styled_focused & {
    background: transparent;
  }
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-background-fill-color: $color-white;
  -webkit-box-shadow: 0 0 0 1000px $color-black inset;
  -webkit-text-fill-color: $color-grey;
  background-color: transparent;
  background-image: none;
  box-shadow: 0 0 0 1000px $color-white inset;
  color: $color-grey;
  text-fill-color: $color-grey;
  transition: background-color 5000s ease-in-out 0s;
  // workaround to detect chrome autofill
  // https://bugs.chromium.org/p/chromium/issues/detail?id=669724
  // https://stackoverflow.com/questions/35049555/chrome-autofill-autocomplete-no-value-for-password
}

.btn-show-password {
  background-color: transparent;
  background-image: url('@/static/styles/images/icons/default/icon-btn-show-password.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 18px 18px;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  height: 20px;
  margin-top: -10px;
  outline: none;
  position: absolute;
  right: 13px;
  bottom: 13px;
  width: 20px;
  z-index: 1;

  &:hover {
    opacity: .85;
  }

  &.active {
    background-image: url('@/static/styles/images/icons/default/icon-btn-hide-password.svg');
  }
}

//for Edge
@supports (-ms-ime-align: auto) {

  input::-ms-clear,
  input::-ms-reveal {
    display: none !important;
  }
}

// for IE11
@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {

  input::-ms-clear,
  input::-ms-reveal {
    display: none !important;
  }
}
