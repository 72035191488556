$tfx-border-color: #B6DAFD;

.tfx-table-wrapper,
.article-view__content-wrapper-table,
.table-responsive {
  overflow: auto;
  border: 1px solid $tfx-border-color;
  border-radius: 4px;
}

.tfx-table,
.cke_editable table,
.article-view__content-wrapper-table table,
.article-view .styling-table,
.article-view .table-responsive table{
  width: 100%;
  border-collapse: collapse;

  caption {
    caption-side: top;
    background-color: $color-blue-navi;
    color: white;
    font-weight: bold;
    font-size: 18px;
    padding: 15px 20px;
  }

  thead {
    th {
      padding: 13px 20px;
      background-color: $color-sky-blue;
      font-weight: bold;
      font-size: 14px;
      line-height: 1.2;
      text-transform: uppercase;
      text-align: left;
      color: $color-blue-navi;
    }
  }

  /* only for country guides */
  .head {
    td {
      padding: 13px 20px;
      background-color: $color-sky-blue;
      font-weight: bold;
      font-size: 14px;
      line-height: 1.2;
      text-transform: uppercase;
      text-align: left;
      color: $color-blue-navi;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid $tfx-border-color;

      &:last-child {
        border-bottom: 0;
      }
    }

    td {
      padding: 15px 20px;
      font-size: 18px;
      line-height: 1.3;
      color: $color-light-black;
      vertical-align: middle;
      text-align: left;

      &.tfx-table__mid-title {
        background-color: $tfx-border-color;
        font-weight: bold;
        font-size: 14px;
        line-height: 1.2;
        text-transform: uppercase;
        text-align: left;
        color: $color-blue-navi;
      }

      @media #{$mobile} {
        width: auto !important;
      }
    }
  }

  tfoot {
    tr {
      border-top: 1px solid $tfx-border-color;

      td {
        color: $color-grey;
        padding: 15px 20px;
        font-weight: bold;
        font-size: 19px;
        line-height: 24px;

        &:last-of-type {
          border-right: 1px solid $tfx-border-color;
          text-align: right;
          font-size: 22px;
        }
      }
    }
  }

  // helpers

  &--price {
    thead {
      th {
        &:last-of-type {
          text-align: right;
        }
      }
    }

    tbody {
      td {
        &:last-of-type {
          border-left: 1px solid $tfx-border-color;
          text-align: right;
          font-weight: bold;
          line-height: 22px;
        }
      }
    }
  }

  &--pnone-cons {
    tbody {
      td {
        vertical-align: top;

        &:first-child {
          border-right: 1px solid $tfx-border-color;
        }
      }
    }
  }

  &--first-col-bold {
    tbody {
      td {
        &:first-child {
          border-right: 1px solid $tfx-border-color;
          font-weight: bold;
          vertical-align: top;
        }
      }
    }
  }

  &--last-col-bold {
    thead {
      th {
        &:last-child {
          text-align: right;
        }
      }
    }

    tbody {
      td {
        &:last-child {
          font-weight: bold;
          vertical-align: top;
          text-align: right;
        }
      }
    }
  }

  &--all-cells-bordered {
    tbody {
      td {
        border-right: 1px solid $tfx-border-color;

        &:last-child {
          border: 0;
        }
      }
    }
  }

  &--all-cells-top {
    tbody {
      td {
        vertical-align: top;
      }
    }
  }

  &--all-cells-center {
    thead {
      th {
        text-align: center;
      }
    }

    tbody {
      td {
        text-align: center;
      }
    }
    tfoot {
      td {
        text-align: center;
      }
    }
  }
}

//streamlined-domestic-offshore Service
.numeric-rows {
  position: relative;
  margin: 20px 0 15px;

  &:before {
    position: absolute;
    content: '';
    left: 47px;
    top: 15px;
    height: calc(100% - 80px);
    width: 1px;
    border-right: 2px dashed $color-white;
  }

  tr {
    td {
      padding: 15px;
      vertical-align: top;
    }
  }

  &_number {
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed $color-white;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: bold;
    font-size: 25px;
    background-color: $color-blue;
    position: relative;
    z-index: 1;
  }

  &_text {
    background: $color-white;
    box-shadow: 3px 2px 9px rgba(0, 0, 0, .4), 3px 19px 35px rgba(0, 0, 0, .2);
    border-radius: 4px;
    padding: 20px 40px;
    font-size: 17px;
    color: $color-grey;
  }
}

//Fixed width for table cells
@for $d from 0 through 500 {
  .cell-w-#{$d}{
    width: #{$d}#{'px'};
  }
}
