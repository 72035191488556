%elem-side {
  border: solid transparent;
  content: " ";
  height: 0;
  left: 50%;
  pointer-events: none;
  position: absolute;
  width: 0;
}

%tooltip-arrow-common-layout {
  background: $color-darkest;
  border: 1px solid transparent;
  left: calc(50% - 5px);
  position: absolute;
  display: none;

  &::after {
    $measurements: 5px;

    @extend %elem-side;

    border-color: transparent;
    border-width: $measurements;
    margin-left: -$measurements;
  }

  &::before {
    $measurements: 6px;

    @extend %elem-side;

    border-color: transparent;
    border-width: $measurements;
    margin-left: -$measurements;
  }
}

.tooltip {
  background: $color-darkest;
  border-radius: 4px;
  color: $color-white;
  padding: 5px 10px;
  border: 0;
  box-sizing: border-box;
  box-shadow: 0 4px 24px rgba(0, 8, 14, .12);
  z-index: 9999;

  > a {
    color: $color-white;
  }

  &::before {
    content: '';
    height: 140%;
    left: -7.5%;
    position: absolute;
    top: -20%;
    width: 115%;
  }

  &[x-placement^="top"] {
    .tooltip-arrow {
      @extend %tooltip-arrow-common-layout;
      display: block;

      bottom: -1px;

      &::after {
        border-top-color: $color-darkest;
        top: 100%;
      }

      &::before {
        border-top-color: $color-darkest;
        top: 100%;
      }
    }
  }

  &[x-placement^="bottom"] {
    .tooltip-arrow {
      @extend %tooltip-arrow-common-layout;
      display: block;

      top: -1px;

      &::after {
        border-bottom-color: $color-darkest;
        bottom: 100%;
      }

      &::before {
        border-bottom-color: $color-darkest;
        bottom: 100%;
      }
    }
  }

  &-inner, &-content {
    color: $color-white;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    white-space: nowrap;
    padding: 0 5px;

    ul,
    a {
      color: inherit !important;
    }
  }

  &_clicked {
    height: 100%;
    width: 100%;

    .trigger {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      outline: none;
      width: 100%;
    }
  }
}

.tooltip-right {
  position: absolute;
  right: 0;
  top: 0;
}

.tooltip-with-icon {
  &::before {
    background: url('../../admin/styles/icons/default/icon-info-blue.svg') no-repeat 50% 50%;
    content: '';
    display: block;
    height: 20px;
    left: -23px;
    position: absolute;
    top: -3px;
    width: 20px;
  }
}

.popover {
  padding: 20px 15px 14px;
  background: white;

  .paragraph {
    margin-bottom: 15px;
    width: 100%;
  }

  &-inner, &-content {
    .text-center {
      text-align: center;
    }
  }

  .button {
    display: inline-flex;

    &:first-of-type {
      margin-right: 15px;
    }
  }

  .popover-arrow {
    &:before {
      border-bottom-color: white !important;
    }
  }
}

.vue-tooltip-hidden {
  display: none;
}


// TQ Tooltip

$cubic: cubic-bezier(.64, .09, .08, 1);
$brand-orange: #f4610e;

.tooltip-info {
  position: relative;

  &::before {
    border-bottom: 5px solid rgba(7, 132, 255, .3);
    border-left: solid 5px transparent;
    border-right: solid 5px transparent;
    content: '';
    display: block;
    height: 0;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: -10px;
    transform: translateX(-50%) translateY(-3px) rotate(180deg);
    transition: opacity .2s $cubic, transform .2s $cubic;
    visibility: hidden;
    width: 0;
    z-index: 1000;
  }

  &::after {
    background: #fff;
    border: 1px solid rgba(7, 132, 255, .3);
    border-radius: 4px;
    box-shadow: 0 3px 11px 0 rgba(0, 22, 35, .19);
    color: #0784ff;
    content: attr(data-tooltip);
    display: block;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    left: 50%;
    line-height: 16px;
    opacity: 0;
    padding: 6px 10px;
    position: absolute;
    top: -40px;
    transform: translateX(-50%) translateY(-2px);
    transition: opacity .2s $cubic, transform .2s $cubic;
    visibility: hidden;
    white-space: nowrap;
    z-index: 1001;
  }

  &:hover {
    &::before {
      display: block;
      opacity: 1;
      transform: translateX(-50%) translateY(0) rotate(180deg);
      visibility: visible;
    }

    &::after {
      display: block;
      opacity: 1;
      transform: translateX(-50%) translateY(0);
      visibility: visible;
    }
  }
}
