.guides-tabs {
  background: $color-sky-blue;
  position: relative;

  @media #{$tablet} {
    padding: 30px 0 50px;
  }

  &::after {
    background: $color-blue;
    content: '';
    height: 380px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
}

.guides-tabs-content {
  position: relative;
  margin: 0 auto;
  max-width: 860px;
  padding: 40px 15px 60px;
  z-index: 2;
}

.guides-tabs-content-nav {
  @media #{$tablet} {
    position: relative;
    z-index: 2;
  }
}

.guides-tabs__title {
  color: $color-white;
  font-size: 34px;
  font-weight: 700;
  line-height: 1.2;
  margin: 20px 0 0;
  text-align: center;
}

.guides-tabs__nav {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: 0 0 50px 0;
  position: relative;
  transition: left ease .3s;

  &-wrapper {
    @media #{$tablet} {
      height: 180px;
      overflow: hidden;
      position: relative;
      margin: 0 auto 40px;
      width: 100%;
    }
  }

  &-item {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent white transparent;
    margin: 0 auto;
  }
}

.guides-tabs__content {
  background: $color-white;
  border-radius: 4px;
  padding: 40px;
  width: 100%;
  color: $color-grey;

  @media #{$mobile} {
    padding: 30px;
  }

  &-title {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: white;
    position: relative;
    margin-top: 168px;

    @media #{$mobile} {
      font-size: 11px;
      line-height: 14px;
      margin-top: 115px;
      height: 42px;
    }

    strong {
      font-size: 19px;
      line-height: 24px;

      @media #{$mobile} {
        font-size: 13px;
        line-height: 15px;
      }
    }
  }

  &-photo {
    align-items: center;
    display: flex;
    justify-content: center;

    &-block {
      @media #{$mobile} {
        flex: 1;
      }
    }

    &-item {
      cursor: pointer;
      margin: 0 20px 12px;
      opacity: .6;
      overflow: hidden;
      position: relative;
      width: 136px;

      @media #{$mobile} {
        width: 100%;
        margin: 10px;
        height: 160px;
      }

      &:hover {
        opacity: .75;
      }

      .image-margin-auto {
        border-radius: 100%;
        width: 104px;
        height: 104px;

        @media #{$mobile} {
          width: 54px;
          height: 54px;
        }
      }

      .active & {
        opacity: 1;
      }
    }
  }
}

.guides-tabs__content-column {
  margin: 0 60px 0 0;
  width: calc(50% - 30px);

  &:last-child {
    margin: 0;
  }
}

.guides-tabs__content-column--list {
  margin: 0 180px 0 0;
  width: calc(50% - 90px);
}