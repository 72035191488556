.wire-page {
  background: $color-sky-blue;
}

.wire {
  display: flex;
  align-items: flex-start;

  @media #{$mobile} {
    flex-direction: column;
  }

  &__nav {
    width: 200px;
    margin: 0 24px 0 0;

    @media #{$mobile} {
      width: 100%;
      margin: 0 0 10px 0;
      display: flex;
      justify-content: space-between;
    }
  }

  &__nav-item {
    width: 100%;
    height: 106px;
    border: 1px solid #A6C5E6;
    border-radius: 4px;
    margin: 0 0 20px;
    padding: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    font-weight: bold;
    font-size: 17px;
    line-height: 22px;
    color: $color-title;

    @media #{$mobile} {
      width: 48%;
      font-size: 18px;
      line-height: 20px;
    }

    &:hover {
      border-color: $color-blue;
      color: $color-blue;
    }

    &.active {
      border-color: #F8620E;
      color: $color-orange;
      position: relative;

      &:after {
        position: absolute;
        content: '';
        top: calc(50% - 8px);
        right: -25px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 0 8px 6px;
        border-color: transparent transparent transparent $color-white;
        transform: rotate(180deg);
      }
    }
  }

  &__content {
    width: calc(100% - 200px);

    @media #{$mobile} {
      width: 100%;
    }
  }

  &__content-item {
    box-shadow: 0 4px 25px rgba(0, 17, 34, .2);
    background: $color-white;
    border-radius: 4px;
    padding: 40px;

    @media #{$mobile} {
      padding: 20px;
    }
  }

  &__content-title {
    margin: 0 0 10px;
    font-size: 17px;
    line-height: 1.3;
    color: $color-grey;
    text-align: left;
    font-weight: 700;
  }
}

.wire-page-head {
  min-height: 380px;

  .base-page-head__column--image {
    padding-top: 60px;
  }
}
