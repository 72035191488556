.consultations-calendar {
  background: #fafafa;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;

  @media #{$mobile} {
    flex-direction: column;
    background: none;
    border: 0;
  }

  &--half {
    height: inherit;
    width: 50%;
    padding: 30px 40px 0;
    position: relative;

    @media #{$mobile} {
      width: 100%;
      padding: 0;
    }
  }

  .consultations-calendar--half + .consultations-calendar--half {
    border-left: 1px solid #f4f4f4;

    @media #{$mobile} {
      border-left: 0;
      border-top: 1px solid #f4f4f4;
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  &__select {
    position: absolute;
    left: 40px;
    top: 85px;
    width: calc(100% - 40px);
    height: calc(100% - 85px);
    overflow-y: auto;

    @media #{$mobile} {
      position: relative;
      top: 15px;
      left: 0;
      width: 100%;
      height: auto;
    }
  }

  &__header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__name-mounth {
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    color: #303030;
  }

  &__prev-mounth,
  &__next-mounth {
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    color: transparent;
    background-size: 100% auto;
    display: block;
    background-image: url("../images/icons/default/icon-calendar-arrow.svg");
  }

  &__prev-mounth {
    transform: rotate(180deg);
  }

  &__calendar-day {
    margin: 15px 0;
    width: 100%;
    display: flex;

    .consultations-calendar__item-day {
      width: 14.28%;
    }
  }

  &__item-day {
    width: 14.28%;
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #303030;
    text-align: center;
    justify-content: center;
  }

  &__time-slots {
    padding-right: 40px;

    @media #{$mobile} {
      padding-right: 0;
    }
  }

  &__item-time {
    background: $color-white;
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 7px;
    padding: 8px 80px 8px 11px;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #303030;
    position: relative;
    transition: all .3s ease-in-out;

    &--available-button {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      background: white;
      color: $color-blue;
      border-left: 1px solid #ebebeb;
      min-width: 80px;
      line-height: 35px;
      border-radius: 0 4px 4px 0;
      transition: all .2s ease-in-out;
    }

    &--available {
      .consultations-calendar__item-time--available-button {
        &:hover {
          background-color: $color-blue;
          color: white;
        }
      }
    }
  }

  &__consultation-days {
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  &__day {
    width: 14.28%;
    padding: 5px 0;
    text-align: center;
  }

  &__item-day {
    display: inline-block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    line-height: 32px;
    position: relative;
    color: #aaa;
    font-weight: bold;
    font-size: 15px;

    &--prev-mounth-day {
      color: #aaa;
    }

    &--working {
      background: #e8f1fd;
      color: $color-blue;
      padding: 0;
    }

    &.blue {
      color: $color-blue;

      &::before {
        background: $color-blue;
      }

      &:hover {
        color: darken($color-blue, 15%);

        &::before {
          background: darken($color-blue, 15%);
        }
      }
    }

    &--current {
      background: $color-blue-dark;
      color: $color-white;
      padding: 0;

      &::before {
        display: none;
      }

      &:hover {
        background: darken($color-blue, 15%);
      }
    }

    &--today {
      color: $color-orange;
    }
  }
}
