$blog-articles__top-block-border: rgba(168, 172, 185, .3);

.blog-articles {
  background: $color-white;
  color: $color-grey;
  font-size: 16px;
  line-height: 21px;
  padding: 0 0 70px;

  .container {
    max-width: 920px;
  }

  @media #{$tablet} {
    padding: 0 0 40px;
  }

  .static-title {
    margin: 0;

    @media #{$tablet} {
      margin: 0 0 30px;
      text-align: left;
    }

    @media #{$mobile} {
      margin: 0;
    }
  }

  &__top-block {
    border-bottom: 1px solid $blog-articles__top-block-border;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0 0 30px;
    padding: 70px 0 25px;

    .static-title {
      color: $color-title;
    }

    @media #{$tablet} {
      padding: 40px 0 20px;
      text-align: center;
    }
  }

  &__item-link {
    background: $color-blue url('@/static/images/pages/articles/icon-articles-list-categories__item-link.svg') no-repeat 50% 50%;
    background-size: 8px;
    bottom: 0;
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
    transition: all .3s ease-in-out;
  }

  &__item {
    border: 2px solid transparent;
    border-radius: 8px;
    float: left;
    font-size: 16px;
    line-height: 1.3;
    margin: 0 40px 0 0;
    min-height: 180px;
    overflow: hidden;
    padding: 15px 40px 15px 20px;
    position: relative;
    width: calc(50% - 20px);
    transition: all .3s ease-in-out;

    &:nth-child(2n) {
      margin-right: 0;
    }

    @media #{$tablet} {
      float: none;
      margin: 0 0 20px;
      width: 100%;
    }

    &:hover {
      cursor: pointer;
      border-color: $color-blue;

      .blog-articles__item-link {
        display: block;
      }
    }

    &__footer {
      display: flex;
      justify-content: space-between;
    }

    &__read-more {
      font-size: 14px;
      font-weight: bold;
      line-height: 1.43;
      letter-spacing: .18px;
      color: $color-blue;
    }
  }

  &__item-title {
    color: $color-title;
    font-size: 18px;
    font-weight: 700;
    min-height: 45px;
    line-height: 1.1;
    margin: 0 0 10px;
    overflow: hidden;
    text-align: left;
    padding: 0;

    @media #{$mobile} {
      height: auto;
    }
  }

  &__item-text {
    height: 62px;
    margin: 0 0 10px;
    overflow: hidden;
    line-height: 22px;
  }

  &__item-date {
    color: $color-date;
  }
}



