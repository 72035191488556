
@use "@tfx/common/styles/element-plus/var.scss" as *;

@import '@tfx/common/styles/tfx-fonts';
@import 'variables';
@import 'mixins';
@import 'base';
@import 'components/main';
@import 'plugins/main';
@import 'modules/main';
@import 'partials/main';
@import 'pages/main';
@import '@tfx/common/styles/main';
@import '@tfx/common/styles/tfx-notify';
@import '@tfx/client/styles/components/tfx-tooltips';
@import '@tfx/client/styles/modules/input-phone';
@import '@tfx/common/styles/tfx-multiselect';
@import '@tfx/common/styles/tfx-select';
@import '@tfx/common/styles/tfx-tooltip';
@import '@tfx/common/styles/tfx-switch';
@import 'tests/index-c';
@import 'tests/index-d';
@import 'tests/index-e';

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import '@/client/styles/modules/tooltips';

html {
  scroll-padding-top: 90px; // static-header height + 15px
}

.login-page__form {
  .multiselect {
    &__tags {
      height: 42px;

      @media #{$extra-small} {
        padding-right: 28px;
      }
    }

    &__single {
      padding-bottom: 0;
      margin-bottom: 0;
      line-height: 25px;
      font-size: 14px;

      @media #{$extra-small} {
        margin: 0;
        white-space: nowrap;
        max-width: 83px;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    &__option {
      &--highlight {
        background-color: $color-blue;
      }
    }

    &__placeholder {
      @media #{$extra-small} {
        margin: 0;
        white-space: nowrap;
        max-width: 83px;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
