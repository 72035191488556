.styled-page-bonus {
  align-items: center;
  background: $color-blue url('@/static/images/pages/common/styled-page-bonus.png') no-repeat 100% 50%;
  border-radius: 6px;
  color: $color-white;
  display: flex;
  margin: 0 -60px 50px;
  min-height: 240px;
  padding: 0 60px;
  position: relative;
  text-align: left;

  @media #{$tablet} {
    margin: 0 0 50px;
    padding: 0 430px 0 20px;
  }
}

h2.styled-page-bonus__title {
  color: $color-white;
  font-size: 60px;
  line-height: 72px;
  margin: 0 0 10px;
  text-align: left;
  text-transform: uppercase;
}

p.styled-page-bonus__text {
  color: $color-white;
  font-size: 25px;
  line-height: 34px;
  margin: 0 0 10px;
  max-width: 410px;
  text-align: left;
}

.styled-page-bonus__image {
  position: absolute;
  right: -1px;
  top: -18px;
}
