.our-services {
  margin-top: -30px;
  position: relative;
  z-index: 11;

  .container {
    max-width: 980px;
  }

  @media #{$sm} {
    margin-top: 20px;
  }

  .static-title {
    color: $color-header-text-title;
    padding-bottom: 15px;
    text-align: center;
  }

  &_inner {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 160px);
    grid-column-gap: 25px;
    grid-row-gap: 25px;
    margin: 30px 0;

    @media #{$sm} {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(6, 160px);
    }

    @media #{$mobile} {
      display: block;

      br {
        display: none;
      }
    }
  }

  &--item {
    border-radius: 4px;
    background-color: $color-blue-bg;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: contain;
    transition: background-color ease .2s;
    color: $color-white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.25;
    letter-spacing: .86px;
    padding: 10px 32px;

    &:hover {
      background-color: darken($color-blue, 10%);
      color: $color-white;
    }

    &--four { grid-area: 2 / 1 / 3 / 3; }
    //&--ten { grid-area: 4 / 2 / 5 / 4; }

    &--four {
      background-color: $color-orange;
      color: $color-white;
      cursor: pointer;

      &:hover {
        background-color: darken($color-orange, 10%);
      }
    }

    &--break {
      display: none;
    }

    @media #{$sm} {
      &--four {
        grid-area: 3 / 1 / 4 / 3;
      }
      &--five {
        grid-area: 2 / 2 / 3 / 3;
      }
      &--six {
        grid-area: 4 / 1 / 5 / 2;
      }
      &--seven {
        grid-area: 4 / 2 / 5 / 3;
      }
      &--eight {
        grid-area: 5 / 1 / 6 / 2;
      }
      &--nine {
        grid-area: 5 / 2 / 6 / 3;
      }
    }

    @media #{$mobile} {
      width: 100%;
      height: 120px;
      margin-bottom: 25px;
      font-size: 18px;
      padding: 10px 90px 10px 15px;

      &--four {
        padding-top: 35px;
        align-items: flex-start;
        height: 210px;
        background-size: auto 120px;
        background-position: bottom center;

        br {
          display: block;
        }
      }
    }
  }

  .btn {
    width: calc(33.33% - 30px);
    margin: 30px 10px;

    @media #{$mobile} {
      width: 100%;
      margin: 10px 0;
    }
  }

  &--landing {
    .our-services_inner {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 160px 160px;
      gap: 25px 25px;
      grid-template-areas:
              ". ."
              ". .";

      @media #{$mobile} {
        grid-template-columns: 1fr;
        grid-template-rows: 160px 160px 160px 160px;
        gap: 0;
        grid-template-areas:
                "."
                "."
                "."
                ".";
      }
    }

    &-pricing {
      position: relative;
      padding-bottom: 10px;
      margin-bottom: 50px;

      &:after {
        position: absolute;
        top: calc(100% + 30px);
        left: 50%;
        width: calc(100% - 40px);
        max-width: 860px;
        height: 1px;
        content: "";
        transform: translate(-50%);
        background: #a6c5e6;
      }

      .our-services_inner {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 160px;
        grid-template-areas: ". . .";

        @media #{$mobile} {
          margin-bottom: 0;
          grid-template-columns: 1fr;
          grid-template-rows: 160px 160px 160px;
          gap: 0;
          grid-template-areas:
                  "."
                  "."
                  ".";
        }
      }

      @media #{$mobile} {
        margin-top: 0 !important;
      }
    }
  }
}

.landing-body {
  .our-services {
    margin-top: 50px;

    .cf {
      display: none;
    }
  }
}

// for IE11
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .our-services {
    &_inner {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
    }

    &--item {
      flex: 1;
      margin: 12px;
      width: 100%;
      height: 160px;

      &--four,
      &--ten {
        flex: none;
        width: 64%;
      }

      &--break {
        display: block;
        flex-basis: 100%;
        height: 0;
      }
    }
  }
}
