body {
  &.article-index-page {
    min-width: auto;

    h1.page-title {
      margin-top: 20px;
      font-size: 60px;
    }

    .header {
      min-width: auto;
    }
  }
}

.articles-list-categories {
  margin: 35px 0 60px;

  > .container {
    max-width: 980px !important;
  }

  &__inner {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(6, 160px);
    gap: 25px 25px;
    grid-template-areas:
      "first-big-block first-big-block ."
      ". . ."
      ". . ."
      ". second-big-block second-big-block"
      ". . ."
      ". . .";

    @media #{$tablet} {
      gap: 10px 10px;
      max-width: 1330px;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(9, 160px);
      grid-template-areas:
        "first-big-block first-big-block"
        ". ."
        ". ."
        ". ."
        ". ."
        "second-big-block second-big-block"
        ". ."
        ". ."
        ". ."
    }

    @media #{$mobile} {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(15, 160px);
      grid-template-areas:
        "first-big-block"
        "."
        "."
        "."
        "."
        "."
        "."
        "."
        "."
        "second-big-block"
        "."
        "."
        "."
        "."
        "."
        "."
    }

    &--tax {
      // TODO:temporary solution until all niches are filled
      grid-template-rows: repeat(4, 160px);

      @media #{$tablet} {
        grid-template-rows: repeat(6, 160px);
      }

      @media #{$mobile} {
        grid-template-rows: repeat(10, 160px);

        .articles-list-categories__item {
          @media #{$mobile} {
            width: 100%;
          }
        }
      }
    }
  }

  &__item {
    background-color: $color-blue;
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 4px;
    color: $color-white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    padding: 0 150px 0 32px;
    position: relative;
    transition: background ease .1s;

    @media #{$mobile} {
      width: calc(100% - 20px);
      margin-right: 0;
    }

    &.foreign-business {
      grid-area: second-big-block;
      padding-right: 221px;
    }

    &.recent-published,
    &.tax-tips {
      grid-area: first-big-block;
      padding-right: 221px;
      background-color: $color-orange;
    }

    &.tax-tips{
      grid-area: second-big-block;
    }
  }

  &__item-name {
    color: $color-white;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: .7px;
    line-height: 1.2;
    text-align: left;
    margin-bottom: 0;

    @media #{$md} {
      font-size: 24px;
    }
  }

  @media #{$tablet} {
    .container {
      max-width: 980px !important;
    }
  }
}

.article-index-page {
  .container {
    max-width: 1330px;
  }

  .breadcrumbs {
    position: static;
    padding: 20px 20px 0;
  }
}
