//How we work progress bar
.progress-bar {
  display: flex;
  position: relative;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 8px 17px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
  min-height: 390px;

  @media #{$mobile} {
    flex-direction: column-reverse;
    min-height: 600px;
  }

  @media #{$extra-small} {
    min-height: auto;
  }

  &__inner {
    display: flex;
    flex-direction: column;
  }

  &__image {
    display: flex;
    height: inherit;
    padding: 20px;
    align-items: center;
    margin-left: 30px;

    @media #{$mobile} {
      margin: 0 auto 20px;
    }
  }

  &__image-pic {
    min-width: 220px;
    display: none;
    transition: opacity 1s ease-out;
    opacity: 0;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: 1s;

    &--active {
      opacity: 1;
      display: block;
    }
  }

  &__step-number {
    width: 25px;
    height: 25px;
    min-width: 25px;
    border-radius: 50%;
    background-color: #B8B8B8;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 26px;
    position: relative;
    z-index: 2;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    margin-top: 2px;
  }

  &__step-title {
    color: #11467D;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    margin: 0;
    text-align: left;
    transition: color 0.5s ease-out;
    cursor: pointer;

    &:hover {
      color: #E87436;
    }

    @media #{$mobile} {
      font-size: 20px;
      line-height: 24px;
    }
  }

  &__step-text {
    color: #171717;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    margin: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 1s linear;
    opacity: 0;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: 1s;
  }

  &__step {
    display: flex;
    align-items: flex-start;
    padding-bottom: 24px;
    position: relative;
    transition: all 1s linear;

    &:before{
      content: '';
      position: absolute;
      left: 13px;
      top: 4px;
      bottom: 0;
      width: 1px;
      background-color: #B8B8B8;
      z-index: 1;
      height: 100%;
    }

    &:after {
      opacity: 0;
      content: '';
      position: absolute;
      left: 13px;
      top: 4px;
      bottom: -4px;
      width: 1px;
      background-color: #E87436;
      z-index: 1;
      max-height: 0;
    }

    &:last-of-type {
      padding-bottom: 0;

      &:before {
        display: none;
      }
    }

    &.progress-bar__step-line {
      .progress-bar__step-number {
        background-color: #11467D;
      }

      &:after {
        opacity: 1;
        max-height: 100%;
      }

      &:last-of-type {
        &:after,
        &:before {
          display: none;
        }
      }
    }

    &--active {
      &.progress-bar__step-line {
        &:after {
          transition: all 5s linear;
        }
      }

      .progress-bar__step-number {
        background-color: #E87436;
      }

      .progress-bar__step-title {
        color: #E87436;
        cursor: default;
      }

      .progress-bar__step-text {
        max-height: fit-content;
        padding-top: 12px;
        visibility: visible;
        opacity: 1;
      }

      &.progress-bar__step-line {
        .progress-bar__step-number {
          background-color: #E87436;
        }
      }
    }
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
