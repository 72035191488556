.articles-show__toc {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
}

.toc-wrapper {
  position: sticky;
  max-height: calc(100vh - 50px - 16px);
  top: 80px;
  right: 80%;
  margin-left: -260px;
  margin-top: 100px;
  margin-right: 16px;
  min-width: 210px;

  @media #{$md} {
    margin-top: 24px;
    position: fixed;
  }
}

.toc {
  height: 100%;
  max-width: 210px;
  width: 100%;
  transition: all 150ms ease-in-out;
  overflow: visible;
  max-height: calc(100vh - 50px - 16px);

  > .toc-list {
    list-style: none;
    transition: all 150ms ease-in-out;
    box-shadow: 0 10px 30px rgba(0, 17, 34, 0.10);
    background: white;
    padding: 40px 10px 20px;
    position: relative;
    overflow-y: auto;
    max-height: calc(100vh - 220px);
    height: auto;

    &:before {
      content: 'Table of contents';
      position: absolute;
      top: 15px;
      left: 10px;
      color: #333;
      font-size: 20px;
      font-weight: bold;

      @media #{$md} {
        left: 24px;
        font-size: 16px;
      }
    }

    > .toc-list-item {
      margin: 12px 0;
    }

    a.toc-link {
      color: $color-grey;
      font-size: 16px;
      font-weight: bold;
      line-height: 22px;
      position: relative;

      &:before {
        background-color: #B6DAFD;
        left: -10px;
        margin-top: 0;
        content: '';
        position: absolute;
        width: 2px;
        height: 100%;
      }

      &.is-active-link {
        font-weight: bold;
        color: $color-blue;

        &:before {
          background-color: $color-blue;
        }
      }
    }

    .toc-list {
      margin: 5px 0;
      padding-left: 18px;

      li {
        padding-top: 5px;
        list-style-type: none;
      }

      a.toc-link {
        font-weight: normal;

        &:before {
          display: none;
        }
      }
    }

    @media #{$md} {
      box-shadow: 0 45px 30px rgba(0, 17, 34, 0.10);
    }
  }

  @media #{$md} {
    position: fixed;
    max-height: unset;
    max-width: unset;
    width: 0;
    left: 0;
    margin-top: 120px;
    z-index: 111;

    > .toc-list {
      padding: 0;
      opacity: 0;
    }

    &.open {
      width: 250px;

      > .toc-list {
        opacity: 1;
        padding: 44px 10px 24px 30px;
      }
    }
  }

  .toc-mobile {
    background-image: url('@/static/styles/images/icons/icon-toc.svg');
    background-repeat: no-repeat;
    background-color: white;
    background-position: center right 12px;
    border-radius: 0 90px 90px 0;
    box-shadow: 0 10px 30px rgba(0, 17, 34, 0.10);
    width: 60px;
    height: 44px;
    position: absolute;
    left: calc(100% - 3px);
    top: 0;
    z-index: -1;
  }

  .is-collapsible {
    max-height: 1000px;
    overflow: hidden;
    transition: all 300ms ease-in-out;
  }

  .is-collapsed {
    max-height: 0;
    overflow: hidden;
    transition: all 300ms ease-in-out;
  }
}
