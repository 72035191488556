.tax-return {
  &__panel {
    background: $color-white;
    box-shadow: 3px 2px 9px rgba(0, 0, 0, .4), 3px 19px 35px rgba(0, 0, 0, .2);
    border-radius: 4px;
    margin: 10px 10px 30px;
    overflow: hidden;
    padding: 25px 40px 25px 192px;
    background-repeat: no-repeat;
    background-position: left 60px center;
    background-image: url('@/static/images/pages/services/tax-return/pic-1.svg');

    @media #{$mobile} {
      text-align: center;
      padding: 140px 20px 20px;
      background-size: auto 100px;
      background-position: top 20px center;
    }

    &_title {
      margin-bottom: 10px !important;

      @media #{$mobile} {
        text-align: center !important;
      }
    }

    &_text {
      margin-bottom: 0 !important;

      @media #{$mobile} {
        text-align: center !important;
      }
    }

    &_right-side {
      padding: 25px 192px 25px 40px;
      background-repeat: no-repeat;
      background-position: right 10px bottom -1px;
      background-image: url('@/static/images/pages/services/tax-return/pic-2.svg');

      @media #{$mobile} {
        padding: 20px 20px 120px;
        background-size: auto 100px;
        background-position: bottom 0 center;
      }
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;

    @media #{$mobile} {
      flex-direction: column;
    }
  }

  &__item {
    padding-top: 50px;
    width: 48.5%;
    margin-bottom: 25px;
    display: flex;
    height: inherit;

    @media #{$mobile} {
      width: 100%;
      height: auto;
    }

    &_inner {
      padding: 80px 30px 40px;
      border: 1px solid #B6DAFD;
      border-radius: 4px;
      position: relative;
      text-align: center;
      height: 100%;
      width: 100%;
      transition: all .3s ease-in-out;

      &:hover {
        border-color: $color-orange;
        cursor: pointer;
      }
    }

    &_image {
      width: 98px;
      height: 98px;
      position: absolute;
      top: -50px;
      left: calc(50% - 50px);
      border: 1px solid $color-orange;
      background-color: $color-white;
      border-radius: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        margin: 0 !important;
      }
    }

    &_title {
      color: $color-title;
      font-weight: bold;
      font-size: 19px;
      line-height: 21px;
      text-align: center;
      letter-spacing: .2px;

      > span {
        font-weight: normal;
      }
    }

    &_text {
      font-size: 16px;
      line-height: 22px;
      text-align: justify;
      color: $color-grey;
      margin-bottom: 0 !important;
    }

    &_subtitle {
      text-transform: none;
    }
  }
}

.service-blocks {
  background-color: $color-sky-blue;
  padding: 104px 0 0;
  position: relative;
  text-align: center;

  @media #{$min-width-xl} {
    padding: 124px 0 0;
  }

  @media #{$mobile} {
    padding: 80px 0;
  }

  &:before,
  &:after {
    position: absolute;
    content: '';
    width: 100%;
    left: 0;
    background-repeat: no-repeat;
    background-size: 100% auto;
    z-index: 1;
    height: 104px;

    @media #{$min-width-xl} {
      height: 124px;
    }

    @media #{$tablet} {
      height: 100px;
    }

    @media #{$mobile} {
      background-size: auto;
    }
  }

  &:before {
    top: 0;
    background-color: white;
    background-image: url('@/static/images/pages/index/mobile-app/blue-wave-v2.svg');
    background-position: right top;

    @media #{$lg} {
      height: 80px;
    }
  }

  &:after {
    bottom: 0;
    height: 160px;
    background-color: white;
    background-image: url('@/static/images/pages/index/mobile-app/blue-wave-bottom.svg');
    background-position: left top;
  }

  &__inner {
    max-width: 980px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin: 25px auto;
    position: relative;
    z-index: 11;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr auto;
    gap: 24px 24px;
    grid-template-areas:
      "service-blocks__item1 service-blocks__item2"
      "service-blocks__item3 service-blocks__item4";

    @media #{$mobile} {
      grid-template-columns: 1fr;
      gap: 24px 0;
      grid-template-rows: auto auto auto auto;

      .service-blocks__item1 { grid-area: 1 / 1 / 2 / 2; }
      .service-blocks__item2 { grid-area: 2 / 1 / 3 / 2; }
      .service-blocks__item3 { grid-area: 3 / 1 / 4 / 2; }
      .service-blocks__item4 { grid-area: 4 / 1 / 5 / 2; }
    }
  }

  &__item {
    background: $color-white;
    box-shadow: 0 9px 22px rgba(0, 0, 0, .1), 0 3px 6px rgba(0, 0, 0, .1);
    border-radius: 6px;
    padding: 42px 60px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    color: $color-grey;
    height: 100%;

    @media #{$mobile} {
      padding: 42px 20px 0;
    }
  }
}
