$tfx-system__navigation__text-color: #656b6f;
$tfx-system__navigation-item-bg: #6bb3f5;
$tfx-system__item-content-box-shadow: 0 3px 7px 0 rgba(0, 0, 0, .1), 0 9px 28.4px 6.7px rgba(0, 0, 0, .14);

.tfx-system {
  background: white;
  padding: 50px 0;

  &__nav {
    @media #{$mobile} {
      font-size: 45px;
      background: $color-white;
      border-radius: 4px;
      margin: 0 0 22px;
      min-height: 150px;
      padding: 20px 25px;
      position: relative;
      text-align: center;

      &::after {
        border-color: $color-white transparent transparent;
        border-style: solid;
        border-width: 12px 12px 0 12px;
        content: '';
        height: 0;
        left: 50%;
        margin-left: -12px;
        position: absolute;
        top: 100%;
        width: 0;
      }
    }
  }

  &__nav-title {
    @media #{$mobile} {
      color: $color-orange;
      font-size: 17px;
      font-weight: 700;
      letter-spacing: .3px;
      line-height: 1.3;
      margin: 0 0 10px;
      text-transform: uppercase;
    }
  }

  &__nav-text {
    @media #{$mobile} {
      color: $tfx-system__navigation__text-color;
      font-size: 17px;
      letter-spacing: -.2px;
      line-height: 1.24;
      margin: 0;
    }
  }

  &__item-content {
    @media #{$mobile} {
      box-shadow: $tfx-system__item-content-box-shadow;
      margin: 0 0 15px;
    }
  }

  &__item-descr {
    @media #{$mobile} {
      color: $color-white;
      font-size: 15px;
      font-style: italic;
      line-height: 1.2;
    }
  }

  &--compare {
    .tfx-system__navigation {
      width: 350px;
    }

    .tfx-system__content {
      margin-left: 400px;
    }
  }

  &--expatriation {
    .tfx-system__title {
      margin-bottom: 10px;
      font-size: 34px;
      line-height: 40px;
    }

    .tfx-system__navigation {
      width: 270px;
    }

    .tfx-system__content {
      margin-left: 300px;
    }
  }

  &__title {
    text-align: left;
    font-size: 34px;
    margin: 0 0 20px;

    @media #{$mobile} {
      text-align: center;
    }
  }

  &__sub-title {
    font-size: 20px;
    line-height: 26px;
    color: $color-title;
    text-align: center;
    max-width: 540px;
    margin: 0 auto 35px;
  }

  &__desc {
    font-size: 17px;
    line-height: 22px;
    color: $color-white;
    margin: 50px 0 0;
  }

  &__navigation {
    float: left;
  }

  &__navigation__title {
    font-size: 19px;
    font-weight: 700;
    line-height: 22px;
    margin: 0 0 10px;
    text-align: left;
  }

  &__navigation-item {
    border: 1px solid $color-blue;
    color: $color-grey;
    background-color: white;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    line-height: 19px;
    margin: 0 0 10px;
    min-height: 130px;
    padding: 17px 20px;
    position: relative;
    transition: background ease .2s;
    max-width: 350px;

    &:hover:not(.active) {
      background: $tfx-system__navigation-item-bg;
      color: white;
    }

    &.active {
      background: $color-white;
      border-color: $color-orange;

      .tfx-system__navigation__title,
      .tfx-system__navigation__text {
        color: $color-blue;
      }

      &:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 0 10px 10px;
        border-color: transparent transparent transparent $color-orange;
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translate(0, -50%);
      }
    }
  }

  &__content-image {
    width: 100%;
  }

  // Item

  &__content-tab {
    border-radius: 4px;
    box-shadow: 3px 2px 9px 0 rgba(0, 0, 0, .4), 3px 19px 35px 0 rgba(0, 0, 0, .2);
    background-color: $color-white;
    padding: 35px 40px 40px;

    @media #{$mobile} {
      border-radius: 4px;
      background-color: $color-white;
      padding: 25px 20px 30px;
      margin: 0;
    }
  }

  &__item {
    color: $color-grey;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: .2px;
    border-bottom: 1px solid #d4d4d4;
    padding: 0 0 25px;
    margin: 0 0 25px;
    text-align: justify;

    &:last-child {
      border: 0;
      margin: 0;
      padding: 0;
    }

    strong {
      color: $color-orange;
    }

    [class*=" icon-"],
    [class^="icon-"] {
      margin-bottom: 8px;
    }

    p {
      margin: 0 0 20px;

      &:last-child {
        margin: 0;
      }
    }

    a {
      font-weight: 700;
    }
  }

  &__item-title {
    font-weight: 700;
    font-size: 17px;
    line-height: 22px;
    margin: 0 0 15px;
  }

  &__row {
    display: flex;
    align-items: flex-start;
  }

  &__item-tag {
    max-width: 130px;
    width: 100%;
    margin-right: 25px;
    text-align: left;
  }

  &__content-info {
    border: 1px dashed $color-white;
    background-color: $color-orange;
    border-top: 0;
    border-radius: 0 0 4px 4px;
    padding: 18px;
    text-align: center;
    font-size: 12px;
    color: $color-white;
    letter-spacing: .2px;
    line-height: 20px;
    font-weight: 700;
    width: calc(100% - 80px);
    margin: 0 auto;

    .icon-clock-opacity {
      margin-left: 25px;
    }
  }
}
