
@use "@tfx/common/styles/element-plus/var.scss" as *;


@import '@/static/styles/variables';

*, :before, :after {
  box-sizing: border-box;
}

#deadline-alert + .main-container.not-title {
  margin-top: 0;
}

.static-header + #deadline-alert .flip-clock {
  margin-top: -1px;
}

.block-counter {
  width: 420px;
  margin: 0;
}

.flip-clock {
  text-align: center;
  perspective: 1200px;
  margin: 0 auto;
  background: #f7f7f7;
  padding-top: 13px;
  border-top: 2px solid #eff0f4;
  border-bottom: 2px solid #eff0f4;
  position: relative;

  .close {
    position: absolute;
    top: 30px;
    right: 20px;
    font-size: 30px;
    line-height: 0;
    font-style: normal;
    cursor: pointer;

    @media #{$mobile} {
      right: 5px;
      top: 13px;
    }
  }

  .container {
    position: relative;
    width: 100%;
    overflow: hidden;
    max-width: 1280px;
    padding: 0 15px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .count-text {
      font-size: 17px;
      font-weight: bold;
      letter-spacing: 0.3px;
      max-width: 350px;
      transform: translateY(-8px);

      @media #{$mobile} {
        transform: none;
      }

      &_first {
        color: #828282;
      }

      &_last {
        color: #828282;

        a {
          color: #2588ed;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__piece {
    display: inline-block;
    margin: 0 15px;
    width: 60px;

    @media #{$tablet} {
      position: relative;
      margin: 0 5px;

      &:not(:last-child)::after {
        content: ':';
        position: absolute;
        right: -7px;
        top: 50%;
        transform: translateY(-50%);
        color: #aaa;
        font-size: 26px;
        z-index: 2;

        @media #{$mobile} {
          top: 10px;
        }
      }
    }
  }

  &__slot {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: bold;
    display: block;
    color: #b3b3b3;
    letter-spacing: 2.5px;
    margin-left: 2px;
    padding-top: 3px;
    height: 20px;
    line-height: 1.5;

    @media #{$tablet} {
      font-size: 12px;
      letter-spacing: 1px;
    }
  }

  &.stopped {
    border: 1px solid #f8620e;

    .flip-card__top {
      color: #f8620e;
    }

    .count-text_first {
      display: none;
    }

    .count-text_last {
      display: block;
    }

    &:after {
      content: 'Time is up. We review results.';
      color: #f8620e;
    }

    &:before {
      position: absolute;
      content: '!';
      width: 24px;
      height: 24px;
      font-weight: bold;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f8620e;
      border-radius: 100%;
      right: -12px;
      top: -12px;
      font-size: 19px;
    }
  }

  @media screen and (max-width: 1100px) {
    margin: 20px 15px;
    padding: 0;
    border-radius: 4px;
    border: 1px solid #dddddd;
    background-color: #f7f7f7;

    .container {
      flex-direction: column;
      padding: 10px 20px;
      overflow: visible;
    }

    .block-counter {
      width: 100%;
      padding-bottom: 10px;

      @media #{$mobile} {
        margin-top: 15px;
      }
    }

    .count-text {
      transform: none;
    }

    [class^=flip-card] {
      color: #404040;
      will-change: transform;
    }
  }
}

.flip-card {
  display: block;
  position: relative;
  padding-bottom: .81em;
  font-size: 2rem;
  line-height: 1.1;
  border: 1px solid #b3b3b3;
  border-radius: 10px;

  @media #{$tablet} {
    border: 0;
    font-size: 26px;
    line-height: 46px;
  }

  @media #{$mobile} {
    line-height: 0;
    padding-bottom: 0;
  }
}

.flip-card__bottom {
  @media #{$tablet} {
    &::before {
      display: none;
    }
  }

  @media #{$mobile} {
    animation: none;
  }
}

.flip-card__top {
  display: block;
  color: #828282;
  background: #f7f7f7;
  padding: .3em .15em .4em .19em;
  border-radius: .15em .15em 0 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform-style: preserve-3d;
  width: 100%;
  height: .81em;
}
