.base-page {
  padding: 0 0 50px;

  .container {
    max-width: 890px;

    &.wide-container {
      max-width: 980px;
      padding: 0;
    }
  }

  h2 {
    font-size: 34px;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
    color: $color-title;
    margin: 40px 0 20px;

    + h4 {
      margin-top: -5px;
    }
  }

  h4 {
    font-size: 18px;
    color: $color-title;
    line-height: 24px;
    text-align: left;
    margin: 0 0 20px;
    font-weight: 700;
  }

  p {
    font-size: 16px;
    line-height: 22px;
    text-align: left;
    color: $color-grey;
    margin: 0 0 25px;
  }

  p,
  ul {
    a {
      font-weight: 700;

      &:hover {
        color: $color-link;
        text-decoration: underline;
      }
    }
  }

  &__gray-bg {
    background-color: #EFF0F4;
    padding: 0 0 70px;

    @media #{$mobile} {
      padding: 0 0 60px;
    }

    p {
      color: $color-main;
    }
  }

  &__image-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media #{$mobile} {
      h2,
      p {
        text-align: center;
      }

      .btn {
        width: 100%;
      }
    }
  }

  &__image-content-pic {
    @media #{$mobile} {
      padding: 0 20px;
    }
  }

  &__auth {
    margin-top: 50px;
    background: $color-white;
    box-shadow: 0 4px 25px rgba(0, 17, 34, .225883);
    border-radius: 4px;
    max-width: 440px;
    width: 100%;
    padding: 30px;
    overflow: hidden;

    @media #{$mobile} {
      max-width: 100%;
    }

    .agreements {
      display: none !important;
    }
  }

  &.without-indent {
    padding: 0;
    margin: 0;
  }

  &__fee-calc-form {
    width:100%;
    height:600px;
    position: relative;
    z-index: 1;
    box-shadow: 0 2px 4px rgba(9, 19, 35, 0.14), 9px 19px 35px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }

  &--tax-fee {
    position: relative;
    background: white;

    .container {
      max-width: 980px;
    }

    &:before,
    &:after {
      position: absolute;
      left: 0;
      right: 0;
      content: '';
      width: 100%;
    }

    &:before {
      top: 0;
      background-color: $color-sky-blue;
      height: 65%;
    }

    &:after {
      background-color: white;
      background-image: url('@/static/images/pages/index/mobile-app/blue-wave-bottom.svg');
      top: 64%;
      background-size: 100%;
      background-position: left top;
      min-height: 134px;
      background-repeat: no-repeat;
    }

    .base-page-head__title,
    .base-page-head__content {
      color: $color-title;
      p {
        color: inherit;
        font-size: 25px;
        line-height: 1.1;
        padding: 0;
      }
    }

    section {
      position: relative;

      p {
        padding: 0 60px 15px;

        @media #{$mobile} {
          padding: 0 0 15px;
        }
      }
    }

    @media #{$mobile} {
      margin-top: -35px;
    }
  }
}

.base-page-image {
  margin: 40px 0;
  position: relative;

  img {
    position: relative;
    z-index: 2;
  }

  &:after {
    position: absolute;
    content: '';
    top: 30px;
    left: 0;
    right: 0;
    bottom: 40px;
    background: $color-border;
    z-index: 1;
  }
}

.base-page-head {
  min-height: 480px;
  background: url('@/static/images/pages/about/common/about-section-head-bg.svg') no-repeat 50% 100% / cover;
  margin: 0 0 50px;
  position: relative;

  h1 {
    text-align: left;

    @media #{$mobile} {
      text-align: center;
    }
  }

  .base-page-head__image {
    margin-top: 70px;

    @media #{$mobile} {
      margin-top: 35px;
    }
  }

  .base-page-head__image {
    margin-top: 70px;

    @media #{$mobile} {
      margin-top: 35px;
    }
  }

  @media #{$mobile} {
    min-height: 350px;
    margin: 0 0 10px;
  }

  .breadcrumbs {
    // top: 15px;
  }

  &[class*=" base-size-"],
  &[class^="base-size-"] {
    min-height: auto;

    .base-page-head__column--text {
      max-width: 60%;

      @media #{$mobile} {
        max-width: 100%;
      }
    }

    @media #{$mobile} {
      min-height: 600px;
    }
  }

  &.base-size--410 {
    .base-page-head__content {
      height: 410px;

      @media #{$mobile} {
        height: auto;
        min-height: 320px;
      }
    }

    .base-page-head__image {
      height: 410px;
      display: flex;
      align-items: flex-end;

      @media #{$mobile} {
        display: none;
      }
    }
  }

  &.base-size--430 {
    .base-page-head__content {
      height: 430px;
    }

    .base-page-head__image {
      height: 430px;
      display: flex;
      align-items: flex-end;
    }
  }

  &.base-size--460 {
    .base-page-head__content {
      height: 460px;
    }

    .base-page-head__image {
      height: 460px;
      display: flex;
      align-items: flex-end;
    }
  }

  &--tax-fee {
    background: none;
    margin: 0 0 35px;
    min-height: unset;
    position: relative;
  }
}

.base-page-head--affiliate {
  min-height: 390px;
}

.base-page-head__column--text {
  padding: 0 0 45px;

  &.tax-stimulus-indent {
    margin-top: 100px;

    @media #{$mobile} {
      margin-top: 0;
    }
  }

  @media #{$md} {
    width: 100%;
  }
}

.base-page-head__column--image {
  padding: 45px 50px 0 0;

  @media #{$md} {
    padding-right: 0;
  }

  @media #{$mobile} {
    width: 100%;
  }
}

.base-page-head__content {
  padding: 0 15px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media #{$mobile} {
    padding-top: 70px;
    flex-direction: column;
  }
}

.base-page-head__title {
  font-size: 60px;
  line-height: 1.2;
  font-weight: 700;
  color: $color-white;
  margin: 40px 0 15px;
  text-align: left;

  &:last-child {
    margin: 0;
  }

  @media #{$ie} {
    font-size: 50px;
  }

  @media #{$md} {
    font-size: 45px;
  }

  @media #{$mobile} {
    font-size: 34px;
    text-align: center;
  }
}

p.base-page-head__sub-title {
  font-size: 24px;
  color: $color-white;
  margin: 0;
  text-align: left;
  line-height: 1.3;

  @media #{$mobile} {
    text-align: center;
  }
}

.base-page-head__descr {
  p {
    color: $color-white;
    margin: 0 0 10px;
    text-align: left;
    font-size: 17px;

    @media #{$md} {
      font-size: 16px;
    }

    &:last-child {
      margin: 0;
    }
  }
}

.base-page-styled-block__content {
  border: 2px solid $color-orange;
  border-radius: 4px;
  padding: 30px 40px;
  font-size: 20px;
  line-height: 26px;
  color: $color-title;
  margin: 0 -40px 40px;

  @media #{$tablet} {
    margin: 0 0 25px;
  }
}

.base-page-info-graphic {
  display: flex;
  margin: 0 0 50px;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    top: 59px;
    left: 100px;
    right: 100px;
    height: 2px;
    background: $color-orange;
    z-index: 0;
  }
}

.base-page-info-graphic__item {
  width: 33.33%;
}

.base-page-info-graphic__image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 2px solid $color-orange;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 15px;
  background: $color-white;
  position: relative;
  z-index: 1;

  @media #{$mobile} {
    width: 90px;
    height: 90px;
  }

  .counter {
    position: absolute;
    bottom: 5px;
    right: 8px;
    width: 18px;
    height: 18px;
    background: $color-orange;
    color: $color-white;
    font-size: 12px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
}

.base-page-info-graphic__title {
  font-size: 16px;
  color: #000000;
  text-align: center;
  line-height: 22px;
  font-weight: 700;
  padding: 0 18px;
}

.base-page-styled-image {
  background: #f2f2f2;
  margin: 0 0 30px;
  padding: 60px;

  @media #{$mobile} {
    padding: 60px 20px;
  }
}

.base-page-styled-image__content {
  padding: 25px;
  background: $color-white;
  box-shadow: 4px 7px 22px 14px rgba(9, 19, 35, .15), 3px 8px 11px 0 rgba(9, 19, 35, .24);
}

.further-reading-links {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px 20px;
  padding: 0;
  list-style: none;

  li {
    width: calc(50% - 20px);
    margin: 10px;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 4px;
    border: 2px solid rgba(37, 136, 237, .5);
    height: 65px;
    padding: 10px 10px 10px 28px;
    text-decoration: none;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 1.1px;
    position: relative;

    &:before {
      position: absolute;
      content: '';
      top: -2px;
      left: -2px;
      bottom: -2px;
      width: 10px;
      border-radius: 4px 0 0 4px;
      background: $color-blue;
    }

    &:hover {
      border-color: $color-blue;
      text-decoration: none !important;
    }
  }

  @media #{$mobile} {
    margin: 0;
    flex-direction: column;

    li {
      width: calc(100% - 20px);
    }
  }
}

.text-up {
  text-transform: uppercase;
}
