[class*=" icon-"],
[class^="icon-"] {
  display: inline-block;
  vertical-align: middle;
}

.icon-star-blue {
  background: url('@/static/styles/images/icons/default/icon-star-blue.png') no-repeat 50% 50%;
  background-size: contain;
  height: 14px;
  width: 14px;
}

.icon-star-white {
  background: url('@/static/styles/images/icons/default/icon-star-white.svg') no-repeat 50% 50%;
  background-size: contain;
  height: 16px;
  width: 16px;
}

.icon-star-yellow {
  background: url('@/static/styles/images/icons/default/icon-star-yellow.svg') no-repeat 50% 50%;
  background-size: contain;
  height: 14px;
  width: 14px;
}

.icon-clock {
  background: url('@/static/images/icons/default/icon-clock.svg') no-repeat 50% 50%;
  background-size: cover;
  width: 13px;
  height: 20px;
}

// Social

.icon-social-facebook {
  background: url('@/static/images/icons/social/icon-social-facebook.svg') no-repeat 50% 50%;
  height: 26px;
  width: 13px;
}

.icon-social-linked-in {
  background: url('@/static/images/icons/social/icon-social-linked-in.svg') no-repeat 50% 50%;
  height: 25px;
  width: 26px;
}

.icon-social-twitter {
  background: url('@/static/images/icons/social/icon-social-twitter.svg') no-repeat 50% 50%;
  height: 21px;
  width: 26px;
}

// Files

[class*=" icon-file-"],
[class^="icon-file-"] {
  width: 60px;
  height: 60px;
}

.icon-file-doc,
.icon-file-docx {
  background: url('@/static/images/icons/files/icon-file-docx.svg') no-repeat 50% 50%;
}

.icon-file-pdf {
  background: url('@/static/images/icons/files/icon-file-pdf.svg') no-repeat 50% 50%;
}