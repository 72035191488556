.styling-list-dist {
  color: $color-grey;
  font-size: 16px;
  letter-spacing: .5px;
  line-height: 1.2;
  list-style: none;
  margin: 0;
  padding: 0 0 25px 20px;
  text-align: left;
  display: block;

  &.without-margin {
    margin: 0;
    padding: 0 0 0 20px;
  }

  ul {
    border: 0;
    font-size: 16px;
    letter-spacing: 0;
    margin: 20px 0 25px;
    padding: 0;

    li {
      margin: 0 0 10px;

      &::before {
        background: transparent;
        color: $color-orange;
        content: '▪';
        height: auto;
        padding-right: 5px;
        position: static;
        width: auto;
      }
    }

    ul {
      margin: 10px 0 0 20px;

      li {
        color: $color-grey-text-list;

        &::before {
          color: $color-blue;
          content: '•';
        }
      }
    }
  }

  &.with-disc {
    li {
      list-style: disc;
      margin-bottom: 10px;

      &::marker {
        color: $color-orange;
      }

      ul {
        margin-left: 20px;

        li {
          list-style: disc;

          &::marker {
            color: $color-blue;
          }

          &::before {
            display: none;
          }
        }
      }
    }
  }
}

.styling-list-decimal {
  counter-reset: heading;
  font-size: 16px;
  letter-spacing: .5px;
  line-height: 1.2;
  margin: 0;
  padding: 0 0 25px 20px;
  text-align: left;

  li {
    list-style: none;
    margin: 0 0 15px;
    position: relative;

    &::before {
      color: $color-orange;
      content: counter(heading) ".";
      counter-increment: heading;
      font-size: 16px;
      font-weight: 700;
      left: -20px;
      position: absolute;
      top: 0;
    }

    ol {
      border: 0;
      font-size: 16px;
      letter-spacing: 0;
      margin: 10px 0 25px;
      padding: 0;

      li {
        &::before {
          font-size: 16px;
          padding-right: 5px;
          position: static;
        }

        ol {
          counter-reset: my-awesome-counter;

          li {
            opacity: .6;

            &::before {
              color: $color-blue;
              content: counter(my-awesome-counter, lower-alpha) '.';
              counter-increment: my-awesome-counter;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.styling-list-title {
  padding: 10px 0 0;
  text-align: left;

  span {
    background: $color-orange;
    color: $color-white;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.2px;
    line-height: 22px;
    min-height: 27px;
    padding: 3px 0 0;
    position: relative;
    text-transform: uppercase;
    width: auto;

    @media #{$md} {
      margin: 0 0 0 60px;
    }

    @media #{$mobile} {
      display: block;
      margin: 0 20px;
      border-radius: 10px 10px 0 0;
      padding: 8px 15px 6px;
      line-height: 18px;
      text-align: center;

      &::before,
      &::after {
        display: none;
      }
    }

    &::before,
    &::after {
      bottom: 0;
      content: '';
      position: absolute;
      top: 0;
      width: 28px;
    }

    &::before {
      background: url('@/static/images/icons/default/icon-styled-list-title-corner-left.svg') no-repeat -1px -1px;
      background-size: 30px;
      left: -28px;
    }

    &::after {
      background: url('@/static/images/icons/default/icon-styled-list-title-corner-right.svg') no-repeat -2px -1px;
      background-size: 30px;
      right: -28px;
    }
  }
}

.styling-list-dist__border {
  border: 2px solid $color-orange;
  border-radius: 4px;
  margin: 0 -40px 30px;
  padding: 30px 0 20px;

  @media #{$md} {
    margin: 0 0 30px;
  }

  a {
    font-weight: 700;
    word-break: break-all;

    &:hover {
      color: $color-link;
      text-decoration: underline;
    }
  }

  ul {
    color: $color-title;
    font-size: 20px;
    line-height: 26px;
    padding: 0 58px;
    text-align: justify;

    li {
      list-style-type: none;
      margin: 0 0 18px;
      position: relative;
      text-align: left;

      &::before {
        background: $color-blue;
        border-radius: 50%;
        content: '';
        height: 10px;
        left: -34px;
        position: absolute;
        top: 10px;
        width: 10px;
      }
    }

    ul {
      color: $color-grey;
      font-size: 16px;
      line-height: 26px;
      padding: 9px 0 0 11px;

      li {
        margin: 0 0 6px;

        &:last-child {
          margin: 0 0 -5px;
        }

        &::before {
          background: $color-blue;
          content: '';
          height: 4px;
          left: -10px;
          position: absolute;
          top: 12px;
          width: 4px;
        }
      }
    }
  }
}
