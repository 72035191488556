$guides-head-bg: rgba(34, 134, 234, .85);

.guides-head {
  align-items: center;
  display: flex;
  height: 400px;
  justify-content: center;
  overflow: hidden;
  position: relative;

  @media #{$mobile} {
    height: auto;
    min-height: 250px;
  }

  &::after {
    background: $guides-head-bg;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.guides-head__image {
  bottom: 0;
  left: 0;
  margin: auto;
  max-height: none;
  max-width: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

.guides-head__title {
  color: $color-white;
  font-size: 54px;
  font-weight: 700;
  letter-spacing: -.6px;
  line-height: 1.15;
  max-width: 550px;
  position: relative;
  text-align: center;
  z-index: 2;

  @media #{$mobile} {
    font-size: 34px;
  }
}

.guides-head__sub-title {
  color: $color-white;
  font-size: 35px;
  letter-spacing: -.4px;
  margin: -5px 0 0;
  position: relative;
  text-align: center;
  z-index: 2;
}

.guides-head__title--big {
  max-width: 850px;
}
