$styled-page-graphic-blue: #A6C5E6;

.styled-page-graphic {
  color: $color-grey;
  font-size: 17px;
  line-height: 22px;

  &__text {
    margin: 60px 0;
  }

  &__head-descr {
    color: $color-white;
    font-size: 16px;
    letter-spacing: .3px;
    line-height: 1.31;
    text-align: justify;
  }

  &__head--margin {
    margin: 0 0 70px;
  }

  &__head-image {
    padding-left: 50px;
  }

  &__head-column {
    &--on-boarding {
      max-width: 620px;
    }

    &--security {
      max-width: 400px;
    }

    &--sp {
      max-width: 640px;

      @media #{$mobile} {
        h1.static-title {
          text-align: center;
        }
      }
    }

    &--testimonials {
      max-width: 570px;
    }

    &--about-us {
      max-width: 350px;

      @media #{$md} {
        padding-top: 50px;
        max-width: 100%;
      }
    }
  }

  &__head {
    background: url('@/static/images/pages/about/common/about-section-head-bg.svg') no-repeat 50% 100%;
    background-size: 100%;
    color: $color-white;
    padding: 110px 0 0;
    position: relative;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      height: 200px;
      background: $color-blue;
      z-index: -1;

      @media #{$md} {
        height: calc(100% - 80px);
      }
    }

    .container-inside {
      align-items: flex-start;
      display: flex;
      justify-content: space-between;

      @media #{$md} {
        padding: 0;
      }

      @media #{$mobile} {
        flex-direction: column;
      }
    }

    .btn {
      border-color: $white;
      min-width: 245px;
    }

    .static-title {
      color: $white;
      margin: 50px 0 15px;
      text-align: left;

      @media #{$md} {
        font-size: 50px;
        line-height: 60px;
        margin: 0 0 10px;
      }

      @media #{$tablet} {
        font-size: 40px;
        line-height: 48px;
      }
    }

    .static-title--secondary {
      color: $white;
      margin: 0 0 35px;
      text-align: left;

      @media #{$md} {
        font-size: 20px;
        margin: 0 0 20px;
      }
    }

    &--about-us {
      .container {
        max-width: 1320px;
      }

      .static-title {
        color: $white;
        font-size: 60px;
        line-height: 72px;

        @media #{$md} {
          font-size: 34px;
        }
      }
    }

    .container {
      max-width: 1320px;
    }

  &--sp {
    .static-title {
      font-size: 45px;

      @media #{$md} {
        font-size: 40px;
      }

        @media #{$mobile} {
          font-size: 34px;
        }
      }
    }

    &--security {
      padding-top: 50px;

      .styled-page-graphic__head-image {
        padding-right: 100px;

        @media #{$md} {
          padding: 0;
        }
      }

      .static-title {
        padding-top: 70px;
        font-size: 60px;
        line-height: 1;

        @media #{$md} {
          padding-top: 120px;
        }

        @media #{$tablet} {
          font-size: 34px;
          padding-top: 100px;
        }
      }
    }

    &--compare {
      padding-top: 60px;

      .static-title {
        margin-top: 35px;
      }

      .styled-page-graphic__head-column {
        max-width: 420px;
      }
    }

    &--sp {
      padding-top: 70px;
    }

    &--about-us {
      padding-top: 50px;

      @media #{$tablet} {
        padding-top: 0;
      }
    }

    &--testimonials {
      padding-top: 80px;

      .static-title {
        margin-top: 5px;
      }

      .static-title--secondary {
        margin-bottom: 25px;
      }
    }

    &--why-get-professional {
      padding-top: 90px;

      @media #{$md} {
        padding-top: 50px;
      }

      .static-title {
        margin-top: 35px;

        @media #{$md} {
          margin-top: 60px;
        }
      }

      .styled-page-graphic__head-image {
        padding-right: 120px;

        @media #{$md} {
          padding-right: 0;
        }
      }
    }

    &--early-bird-special {
      padding-top: 60px;

      .styled-page-graphic__head-column {
        max-width: 500px;

        @media #{$md} {
          max-width: 390px;
        }
      }

      .styled-page-graphic__head-image {
        min-width: 500px;

        @media #{$md} {
          min-width: auto;
        }
      }

      .static-title {
        @media #{$md} {
          margin-top: 80px;
        }

        @media #{$tablet} {
          margin-top: 50px;
        }
      }
    }

    &--flat-package-forms {
      padding-top: 60px;

      .static-title {
        margin-top: 60px;
      }

      .styled-page-graphic__head-image {
        padding-right: 70px;

        @media #{$tablet} {
          padding-right: 0;
        }
      }
    }

    &--referral-program {
      padding-top: 60px;

      .styled-page-graphic__head-image {
        padding-right: 130px;

        @media #{$md} {
          padding-right: 50px;
        }

        @media #{$tablet} {
          padding-right: 0;
        }
      }

      .static-title {
        @media #{$md} {
          margin-top: 60px;
        }
      }
    }

    &--information-we-need {
      padding-top: 60px;

      .static-title {
        margin-top: 90px;
      }

      .styled-page-graphic__head-image {
        padding-right: 100px;

        @media #{$tablet} {
          padding-right: 0;
        }
      }
    }

    &--irs-representation {
      padding-top: 70px;

      .styled-page-graphic__head-image {
        padding: 0;
      }

      .static-title {
        @media #{$md} {
          margin-top: 50px;
        }
      }
    }

    &--fees-all-forms {
      padding-top: 45px;

      .styled-page-graphic__head-image {
        padding-right: 90px;

        @media #{$md} {
          padding-right: 0;
        }
      }

      .static-title {
        padding-top: 5px;

        @media #{$md} {
          padding-top: 70px;
        }
      }
    }
  }

  //info
  &__info {
    color: $color-grey;
    font-size: 17px;
    line-height: 22px;
    padding: 95px 0 50px;

    > .container {
      max-width: 890px;
    }

    @media #{$mobile} {
      padding: 30px 0;
    }

    p {
      margin: 0 0 25px;
    }

    a {
      font-weight: 700;

      &:hover {
        color: $color-link;
      }
    }

    &.without-padding {
      padding-bottom: 70px;
    }

    &--on-boarding {
      .container {
        max-width: 890px;
        text-align: center;
      }
    }

    // Static
    &--static {
      margin: 0 0 85px;

      .container-inside {
        @media #{$tablet} {
          padding: 0;
        }
      }

      &.without-margin {
        margin: 0;
      }

      &.security-personal-data {
        margin: 60px 0 0;

        .container-inside {
          padding-bottom: 0;
        }
      }

      .container {
        max-width: 1010px;
      }

      h2 {
        color: $color-title;
        font-size: 34px;
        font-weight: 700;
        line-height: 40px;
        margin: 0 0 20px;
        text-align: center;
      }

      a {
        font-weight: 700;
      }

      p {
        margin: 0 0 25px;
        text-align: justify;
      }
    }

    &.small-padding {
      padding: 40px 0 0;
      margin: 0;
    }
  }

  &__info-column-wrapper {
    column-count: 2;
    column-gap: 30px;
    padding: 0;
    text-align: justify;
    margin: 70px 0 50px;

    @media #{$md} {
      padding: 0;
      margin: 50px 0 40px;
    }

    @media #{$mobile} {
      column-count: 1;
    }

    &.without-column {
      column-count: 1;
    }
  }

  &__info-buttons {
    align-items: center;
    border: 1px solid $styled-page-graphic-blue;
    border-radius: 4px;
    display: flex;
    margin: 50px auto 20px;
    padding: 32px 20px;

    @media #{$tablet} {
      flex-direction: column;
    }

    .button,
    .btn {
      margin: 0 12px;
      flex: 1;
      height: 44px;
      min-height: 44px;
      text-decoration: none;

      @media #{$tablet} {
        padding: 0;
        height: auto;
        margin: 0 0 20px;
        width: 100%;
        color: $color-white;
        border-radius: 4px;
        display: flex;
        transition: none;
        align-items: center;
      }

      &:hover {
        color: white;
      }
    }

    .btn-free-online-call {
      text-decoration: none;
      background: 0 0;
      border: 1px solid $color-blue;
      color: $color-white;

      &:hover {
        color: $color-white;
      }

      .btn-with-icon-content {
        width: calc(100% - 70px);
      }
    }

    .btn-online-chat {
      border: 2px solid $bg-dtn-online-chat;
      color: white;

      &:hover,
      &:focus {
        color: white;
      }
    }
  }

  &__info-subject {
    display: inline-block;
    font-weight: 700;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.35px;
    margin: 0 0 20px;
    position: relative;
    text-transform: uppercase;
    color: $styled-page-graphic-blue;
    width: 100%;
    z-index: 1;

    &::before {
      background: $styled-page-graphic-blue;
      content: '';
      height: 1px;
      margin-top: -1px;
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      z-index: -2;
    }

    &:after {
      position: absolute;
      content: '';
      height: 28px;
      width: 152px;
      background: white;
      margin: 0 auto;
      left: 0;
      right: 0;
      z-index: -1;
    }
  }

  &__info-phones {
    align-items: center;
    border: 1px solid $styled-page-graphic-blue;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    padding: 30px 10px;

    @media #{$tablet} {
      padding: 0 20px;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__info-phones-item {
    align-items: center;
    display: flex;
    padding: 10px 30px;
    text-decoration: none;

    @media #{$tablet} {
      padding: 20px 0;
    }

    &:last-child {
      border-left: 1px solid $styled-page-graphic-blue;

      @media #{$tablet} {
        border: 0;
      }
    }
  }

  &__info-phones-flag {
    align-items: center;
    border-radius: 4px;
    display: flex;
    height: 30px;
    justify-content: center;
    margin: 0 15px 0 0;
    overflow: hidden;
    width: 40px;

    img {
      height: 100%;
      max-width: none;
      width: auto;
    }
  }

  &__info-phones-number {
    color: $color-title;
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    white-space: nowrap;

    @media #{$mobile} {
      font-size: 14px;
    }

    &:hover,
    &:focus {
      color: $color-orange;
    }
  }

  // Graphic Block
  &__block {
    color: $color-white;

    > .container {
      align-items: center;
      display: flex;
      justify-content: space-between;
      max-width: 890px;

      @media #{$tablet} {
        padding-bottom: 35px;
      }
    }

    .btn {
      margin-top: 20px;
      min-width: 190px;

      @media #{$mobile} {
        width: 100%;
      }
    }

    .btn-outline-white {
      border-color: $color-white;
    }

    &_first-title {
      flex-direction: column;
      padding: 60px 15px 20px;
      text-align: center;
    }

    &--blue {
      background: $color-blue;

      .styled-page-graphic__block-title {
        text-align: left;
      }

      @media #{$mobile} {
        padding-top: 30px;

        .styled-page-graphic__block-title {
          text-align: center;
        }

        >.container {
          display: block;
        }
      }
    }

    &--orange {
      background: $color-orange;
    }

    &--dark {
      background: $color-title;
      padding: 20px 0;
      text-align: center;
    }

    &--col-reverse {
      @media #{$mobile} {
        padding-bottom: 40px;
      }

      > .container {
        @media #{$mobile} {
          flex-direction: column-reverse;
        }
      }

      .styled-page-graphic__block-image--sp-tax-return {
        margin-top: 40px;

        @media #{$mobile} {
          margin-top: -50px;
        }
      }
    }
  }

  &__block-image {
    &--left-alignment {
      padding-right: 50px;
    }

    &--right-alignment {
      padding-left: 50px;
    }

    &--sp-tax-return {
      margin: 0;
      min-width: 471px;
      width: 269px;

      @media #{$mobile} {
        margin: 20px auto 0;
        min-width: unset;
        padding: 0;
        width: 100%;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    &--sp-package {
      margin: -45px -75px -70px 0;
      min-width: 430px;
      width: 430px;

      @media #{$mobile} {
        width: 100%;
        min-width: unset;
        padding: 0;
        margin: 20px auto;
      }
    }
  }

  &__block-title {
    color: $color-white;
    font-size: 34px;
    font-weight: 700;
    letter-spacing: -.5px;
    line-height: 1.15;
    margin: 0 0 5px;

    @media #{$mobile} {
      text-align: center;
    }
  }

  &__block-sub-title {
    font-size: 25px;
    font-style: italic;
    letter-spacing: .5px;
    line-height: 1.2;
    margin: 0;

    @media #{$mobile} {
      text-align: center;
    }
  }

  &__block-list {
    list-style: none;
    margin: 20px 0 0 0;
    padding: 0;
  }

  &__block-list-item {
    color: $color-white;
    font-size: 17px;
    margin: 15px 0;
    padding: 0 0 0 25px;
    position: relative;
    text-align: left;

    &::before {
      background: url('@/static/images/pages/services/pricing/icon-graphic-block-list.svg') no-repeat 50% 50%;
      content: '';
      height: 20px;
      left: 0;
      position: absolute;
      top: 2px;
      width: 15px;
    }

    a {
      color: $color-blue;
      font-weight: 700;

      &:hover {
        text-decoration: none;
      }
    }
  }


  // Note
  &__wrapper-note {
    margin: 60px 0;
  }

  &__note {
    margin-top: 90px;
  }

  &--about-us {
    .mfa-section {
      .static-title {
        display: none;
      }
    }
  }
}
