$testimonials-page-block__subject-line-bg: rgba(168, 172, 185, .3);

.testimonials-header {
  border: 1px solid #A6C5E6;
  border-radius: 6px;
  max-width: 430px;
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 35px 0;

  @media #{$mobile} {
    margin: 35px auto;
    height: 80px;
  }

  &__title {
    font-size: 17px;
    line-height: 19px;
    color: $color-white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -18px;
    right: 15px;
    left: 15px;
    padding: 5px;
    background-color: $color-blue;
    white-space: nowrap;

    @media #{$mobile} {
      flex-direction: column;
      white-space: normal;
    }

    &--white {
      background-color: $color-white;
      color: $color-blue-navi;
      font-weight: bold;
      font-size: 17px;
      line-height: 19px;
      white-space: nowrap;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;

    > a {
      display: block;
      margin: 10px;
      transition: all 0.3s ease;

      &:hover {
        opacity: 0.7;
      }

      @media #{$mobile} {
        margin-top: 43px !important;
      }
    }

    &:after {
      position: absolute;
      content: 'Click icons above for details';
      display: inline-block;
      margin: 0 auto;
      padding: 0 6px;
      bottom: -7px;
      background-color: $color-blue;
      color: white;
      font-size: 14px;
    }

    &--white {
      &:after {
        background-color: white;
        color: $color-blue;
      }
    }
  }
}

.testimonials-page-block {
  padding: 70px 0;
  text-align: center;

  .static-title {
    padding: 0 0 25px;
  }

  .static-title--secondary {
    margin: 0 0 70px;
    padding: 0;

    @media #{$mobile} {
      margin: 0 0 30px;
    }
  }

  &__item {
    padding: 50px 30px 25px;
    border: 1px solid $color-lightest-grey;
  }

  &__read-more {
    background-color: $color-blue;
    padding: 55px 0;

    &--inner {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media #{$mobile} {
        flex-direction: column;
      }
    }

    .static-title {
      color: $color-white;
      text-align: left;

      @media #{$mobile} {
        text-align: center;
      }
    }
  }


  &__links {
    display: flex;

    @media #{$mobile} {
      flex-direction: column;
      margin-top: 40px;
      width: 100%;
    }

    .btn {
      margin: 0 15px 10px;
      width: 130px;
      line-height: 1.1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;

      @media #{$mobile} {
        width: 100%;
        margin: 0 0 15px;
      }
    }
  }

  &__list {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    min-height: 500px;
    position: relative;
  }

  &__list-column {
    padding: 0 10px;
    width: 33.33%;

    @media #{$mobile} {
      width: 100%;
    }
  }

  &__item {
    margin: 52px 0 30px;
    width: 100%;
    position: relative;
  }

  &__item-text {
    border-radius: 4px 4px 0 0;
    color: $color-grey;
    font-size: 16px;
    font-style: italic;
    line-height: 22px;
    padding: 0 0 25px;
    position: relative;
    text-align: center;

    >p {
      margin: 0;
    }

    b,
    strong {
      color: $color-orange;
    }
  }

  &__item-photo {
    border-radius: 50%;
    height: 64px;
    left: 50%;
    margin-left: -32px;
    overflow: hidden;
    position: absolute;
    top: -32px;
    width: 64px;

    img {
      height: calc(100% + 5px);
      object-position: center;
      object-fit: cover;
      width: 100%;
    }
  }

  &__item-name {
    color: $color-blue-navi;
    margin: 0 0 15px;
    font-weight: bold;
    font-size: 19px;
    line-height: 24px;
  }

  &__item-date {
    color: $color-grey;
    font-size: 16px;
    line-height: 1;
    margin: 0;
  }

  &__item-link {
    background: $color-orange;
    border-radius: 0 0 4px 4px;
    color: $color-white;
    display: block;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: .3px;
    line-height: 1;
    padding: 9px 10px;
    text-decoration: none;
    text-transform: uppercase;
    transition: background ease .2s;

    &:hover {
      background: darken($color-orange, 10%);
      color: $color-white;
    }
  }
}

.base-page {
  &.base-page--testimonials {
    padding: 0;
  }

  &.without-padding {
    padding: 0;
  }
}

.base-page-head--testimonials {
  margin: 0;

  .base-page-head__content {
    min-height: 540px;
    align-items: flex-start;
    padding-top: 60px;

    @media #{$mobile} {
      min-height: 660px;
    }
  }

  .base-page-head__column--text {
    max-width: 730px;
    padding: 0;

    @media #{$mobile} {
      max-width: 100%;
    }
  }

  .base-page-head__column--image {
    padding: 0;
  }
}

.testimonials-footer {
  &__wrapper {
    padding: 50px 0;
    background-color: #E9F3FD;
    width: 100%;
  }

  &__content {
    display: flex;
    align-items: center;

    @media(max-width: 959px) {
      flex-direction: column-reverse;

      .static-title {
        text-align: center;

        br {
          display: none;
        }
      }
    }
  }

  &__img {
    height: inherit;
    width: 60%;

    @media(max-width: 959px) {
      margin-top: 30px;
      width: 100%;
    }
  }
}
