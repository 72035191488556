$tablet-nav__main-width: 272px;

.tablet-nav {
  bottom: 0;
  display: none;
  left: -100%;
  position: fixed;
  right: 0;
  top: 0;
  transition: left ease .3s;
  width: 100%;
  z-index: 11;

  @media #{$tablet} {
    display: block;
  }

  &.open-tablet-nav {
    left: 0;
  }

  &__button {
    background: url('@/static/images/icons/default/icon-open-tablet-nav.svg') no-repeat 20px 25px;
    display: none;
    height: 60px;
    left: 10px;
    position: fixed;
    top: 10px;
    width: 60px;
    z-index: 12;

    &.open-tablet-nav {
      background: url('@/static/images/icons/default/icon-close-tablet-nav.svg') no-repeat 25px 25px;
    }

    @media #{$tablet} {
      display: block;
    }

    @media #{$mobile} {
      display: none;
    }
  }

  &__main {
    background: $color-white;
    bottom: 0;
    box-shadow: 5px 0 20px rgba(0, 0, 0, .2);
    left: 0;
    padding-top: 60px;
    position: absolute;
    top: 0;
    width: $tablet-nav__main-width;
    z-index: 5;

    @media #{$tablet} {
      width: 272px;
      overflow-y: auto;
    }
  }

  &__sub {
    background: $color-white;
    bottom: 0;
    box-shadow: 5px 0 30px rgba(0, 0, 0, .2);
    display: flex;
    left: 0;
    position: absolute;
    top: 0;
    transition: left ease .5s;
    width: $tablet-nav__main-width;
    z-index: 4;
    padding-top: 75px;

    &.open-sub-nav {
      left: $tablet-nav__main-width;

      @media #{$tablet} {
        left: 272px;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    a {
      color: $color-title;
      display: block;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      margin: 0;
      padding: 12px 15px 12px 40px;

      &:hover {
        color: $color-blue;
      }
    }
  }

  &__bottom-content {
    position: relative;
    left: 0;
  }

  &__buttons {
    border-top: 1px solid rgba(0, 0, 0, .2);
    display: flex;
    padding: 30px 40px;

    .btn {
      padding-left: 0;
      padding-right: 0;
      margin: 0;
      flex: 1;

      @media #{$tablet} {
        width: 100%;
      }

      &:first-child {
        margin-right: 10px;
      }
    }
  }

  &__phones-item {
    color: $color-title;
    display: flex;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1.4px;
    line-height: 20px;
    margin: 5px 0;
    padding: 10px 0;
    position: relative;

    &::after {
      border-color: $color-title;
      border-style: solid;
      border-width: 2px 2px 0 0;
      box-sizing: inherit;
      content: '';
      height: 10px;
      margin-top: -5px;
      position: absolute;
      right: 0;
      top: 50%;
      transform: rotate(45deg);
      vertical-align: top;
      width: 10px;
    }

    &:hover {
      color: $color-white;
    }
  }

  &__phones-flag {
    align-items: center;
    border-radius: 2px;
    display: flex;
    height: 20px;
    margin: 0 9px 0 0;
    overflow: hidden;
    width: 34px;

    img {
      height: 20px;
      width: auto;
    }
  }

  &__list-item {
    color: $color-title;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    margin: 20px 0;
    padding: 22px 30px;

    @media #{$tablet} {
      font-size: 22px;
      line-height: 18px;
      margin: 10px 0;
      padding: 20px 40px;
      display: block;

      &:focus,
      &:hover {
        color: $color-blue;
      }
    }

    &.with-sub-nav {
      position: relative;

      &::after {
        border-color: $color-title;
        border-style: solid;
        border-width: 2px 2px 0 0;
        box-sizing: inherit;
        content: '';
        height: 10px;
        margin-top: -5px;
        position: absolute;
        right: 30px;
        top: 50%;
        transform: rotate(45deg);
        vertical-align: top;
        width: 10px;

        @media #{$tablet} {
          right: 40px;
        }
      }
    }

    &.active {
      background: $color-white;
      color: $color-orange;

      &.with-sub-nav {
        &::after {
          border-color: $color-orange;
        }
      }
    }
  }

  // Search
  &__search,
  &__phones,
  &__apps {
    border-top: 1px solid rgba(0, 0, 0, .2);
    padding: 30px 40px;
  }

  &__search-form {
    position: relative;
  }

  &__search-input {
    background: transparent;
    border: 1px solid $color-orange;
    border-radius: 4px;
    box-sizing: border-box;
    color: $color-black;
    display: block;
    font-size: 14px;
    height: 42px;
    outline: none;
    padding: 5px 10px 7px 45px;
    width: 100%;

    &:focus {
      border-color: $color-orange;
    }

    @media #{$tablet} {
      padding-left: 10px;
    }
  }

  &__apps {
    display: none;
    padding-top: 10px;
    padding-bottom: 10px;

    @media #{$tablet} {
      display: block;
    }

    &--item {
      width: 100%;
      margin-bottom: 15px;
      background: #000;
      border-radius: 4px;
      height: 42px;

      img {
        width: 100%;
        margin-bottom: 15px;
        height: 100%;
      }
    }
  }

  &__apps-title {
    position: relative;
    width: 100%;
    color: #bec2cd;
    font-size: 14px;
    font-weight: 700;
    line-height: 2;
    letter-spacing: .35px;
    text-align: center;
    margin-bottom: 5px;
    text-transform: uppercase;

    &:after,
    &:before {
      position: absolute;
      top: 40%;
      width: calc(50% - 75px);
      height: 1px;
      content: "";
      background-color: #bec2cd;
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }
    @media #{$tablet} {
      padding-left: 10px;
    }
  }

  &__apps-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}

.tablet-nav-guide {
  align-items: center;
  background: $color-white;
  bottom: 0;
  box-shadow: 5px 0 30px rgba(0, 0, 0, .2);
  display: flex;
  position: absolute;
  right: calc(100% - 272px);
  top: 0;
  transition: left ease .5s;
  width: calc(100% - 272px);
  z-index: 4;

  &.open-guide-nav {
    box-shadow: none;
    right: 0;
  }
}
