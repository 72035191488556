.guides-column-block-image {
  background: $color-lightest-grey;
  padding: 20px 0 80px;

  .page-title {
    margin: 0 0 50px;
  }
}

.guides-column-block-image__wrapper {
  display: flex;
  justify-content: center;

  @media #{$tablet} {
    flex-direction: column;
  }
}

.guides-column-block-image__item {
  padding: 0 30px;
  text-align: justify;
  width: 25%;

  @media #{$tablet} {
    width: 100%;
  }

  @media #{$mobile} {
    padding: 0;
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.guides-column-block-image__image {
  align-items: flex-start;
  display: flex;
  height: 90px;
  margin: 0 auto 25px;
  width: 90px;
}

.guides-column-block-image__item-title {
  color: $color-title;
  font-size: 19px;
  font-weight: 700;
  letter-spacing: .4px;
  line-height: 1.1;
  margin: 0 0 30px;
  text-align: center;
  text-transform: uppercase;
}

.guides-column-block-image__text {
  color: $color-grey;
  font-size: 16px;
  letter-spacing: .3px;
  line-height: 1.19;

  p {
    &:last-child {
      margin: 0;
    }
  }
}
