$cubic: cubic-bezier(.64, .09, .08, 1);
$tfx-tooltip-color-border: 303030;
$tfx-tooltip-color-box-shadow: rgba(0, 8, 14, .12);
$tfx-tooltip-latin-char-color-border: #D0021B;
$color-white: #fff;
$color-blue: #2586ea;

.tfx-tooltip {
  position: relative;

  &::before {
    top: -7px;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent #303030 transparent;
    transition: opacity .2s $cubic, transform .2s $cubic;
    visibility: hidden;
    z-index: 1001;
  }

  &::after {
    border-radius: 4px;
    top: -35px;
    content: attr(data-tooltip);
    display: block;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    left: 50%;
    line-height: 16px;
    opacity: 0;
    padding: 6px 10px;
    position: absolute;
    transform: translateX(-50%) translateY(-2px);
    transition: opacity .2s $cubic, transform .2s $cubic;
    visibility: hidden;
    white-space: nowrap;
    z-index: 1000;
    color: #fff;
    background: #303030;
    box-shadow: 0 4px 24px rgba(0, 8, 14, .12);
  }

  &.show {
    &::before {
      display: block;
      opacity: 1;
      transform: translateX(-50%) translateY(0);
      visibility: visible;
      z-index: 9999;
      height: 28px;
    }

    &::after {
      display: block;
      opacity: 1;
      transform: translateX(-50%) translateY(0);
      visibility: visible;
      z-index: 9999;
      height: 28px;
    }
  }

  &.only-latin-char {
    &::before {
      border-width: 5px 5px 0 5px;
      border-color: #D0021B transparent transparent transparent;
      left: 20px;
      transform: translateX(0) translateY(0);
    }

    &::after {
      color: #fff;
      background: #D0021B;
      box-shadow: 0 4px 26px rgba(0, 8, 14, .12);
      left: 0;
      transform: translateX(0) translateY(0);
    }
  }

  &.tooltip-bottom {
    &::before {
      top: calc(100% - 17px);
      bottom: unset;
      transform: rotate(180deg);
    }

    &::after {
      top: calc(100% + 10px);
      bottom: unset;
    }
  }
}
