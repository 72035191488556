$guides-head-bg: rgba(34, 134, 234, .4);

.guides {
  &__main-photo {
    width: 100%;
    height: 400px;
    overflow: hidden;
    position: relative;
    z-index: 1;

    @media #{$mobile} {
      height: auto;
      min-height: 250px;
    }

    &::after {
      background: $guides-head-bg;
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    &--img {
      bottom: 0;
      left: 0;
      margin: auto;
      max-height: none;
      max-width: 100%;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &-main-actions {
    background-color: $color-sky-blue;
    padding-bottom: 60px;
    margin-bottom: 50px;

    &--row {
      display: flex;
      max-width: 860px;
      width: 100%;
      align-items: flex-start;
      justify-content: center;
      margin: -80px auto 0;
      position: relative;
      z-index: 11;

      @media #{$mobile} {
        flex-direction: column;
      }
    }

    &--item {
      background: white;
      box-shadow: 3px 8px 17px rgba(9, 19, 35, .24);
      padding: 24px;
      margin: 15px;
      flex: 1;

      p {
        color: $color-grey;
        font-size: 16px;
        line-height: 22px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    &--title {
      display: flex;
      align-items: center;
      margin-bottom: 14px;
      font-size: 19px;
      line-height: 21px;
      font-weight: bold;
      letter-spacing: .2px;
      color: $color-blue;
    }

    &--img {
      margin-right: 15px;

      img {
        min-width: 36px;
      }
    }
  }
}

.guides-page {
  min-width: 1000px;

  @media #{$tablet} {
    min-width: unset;
  }

  .header {
    min-width: 1000px;

    @media #{$tablet} {
      min-width: unset;
    }
  }

  .faq__container {
    padding: 20px 0;
  }
}

.guides-page__content {
  a {
    font-weight: 700;

    &:hover {
      color: $color-link;
      text-decoration: underline;
    }
  }

  ul {
    color: $color-grey;
    font-size: 20px;
    letter-spacing: .5px;
    line-height: 1.2;
    list-style: none;
    margin: 0;
    padding: 0 0 25px 30px;
    text-align: left;

    @media #{$tablet} {
      font-size: 16px;
    }

    li {
      list-style: none;
      margin: 0 0 15px;
      position: relative;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        background: $color-orange;
        border-radius: 50%;
        content: '';
        height: 10px;
        left: -30px;
        position: absolute;
        top: 8px;
        width: 10px;

        @media #{$mobile} {
          left: -20px;
        }
      }
    }

    ul {
      border: 0;
      font-size: 16px;
      letter-spacing: 0;
      margin: 20px 0 25px;
      padding: 0;

      li {
        margin: 0 0 10px;

        &::before {
          background: transparent;
          color: $color-orange;
          content: '▪';
          height: auto;
          padding-right: 5px;
          position: static;
          width: auto;
        }
      }

      ul {
        margin: 10px 0 0 20px;

        li {
          color: $color-grey-text-list;

          &::before {
            color: $color-blue;
            content: '•';
          }
        }
      }
    }
  }

  ol {
    content: counter(my-awesome-counter, lower-alpha) '.';
    counter-increment: my-awesome-counter;
    margin: 0;
    padding: 0 0 0 35px;

    li {
      color: $color-grey;
      font-size: 20px;
      letter-spacing: .4px;
      list-style: none;
      margin: 0 0 15px;
      position: relative;
      text-align: justify;

      &::before {
        color: $color-orange;
        content: counter(my-awesome-counter, lower-alpha) '.';
        counter-increment: my-awesome-counter;
        font-size: 20px;
        font-weight: 700;
        left: -35px;
        position: absolute;
        top: 0;
      }

      ol {
        border: 0;
        counter-reset: heading;
        font-size: 16px;
        letter-spacing: 0;
        margin: 10px 0 25px;
        padding: 0;

        li {
          color: #aaa;

          &::before {
            content: counter(heading) ".";
            counter-increment: heading;
            position: static;
          }
        }
      }
    }
  }
}

.guides-container {
  margin: 0 auto;
  max-width: 860px;

  @media #{$mobile} {
    margin: 0 20px 30px;
    overflow-x: auto;
  }
}

.guides-step-title {
  position: relative;
  text-align: center;
  font-weight: bold;
  font-size: 40px;
  line-height: 40px;
  margin-bottom: 18px;
  color: $color-orange;

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 16px 0 16px 16px;
    border-color: transparent transparent transparent $color-orange;
    left: 0;
    position: absolute;
    top: 0;
  }
}

.guides-author-date {
  font-size: 14px;
  font-weight: 700;
  height: 70px;
  letter-spacing: .3px;
  line-height: 1.5;

  .guides-container {
    align-items: center;
    display: flex;
    height: 70px;
    justify-content: space-between;
  }
}
