$footer-bg: #11467d;
$footer-color-copyright: rgba(255, 255, 255, 0.2);

.footer {
  background: $footer-bg;
  font-size: 14px;
  min-height: 490px;
  z-index: 1;
  position: relative;
  padding-top: 60px;
  padding-bottom: 40px;

  @media #{$tablet} {
    padding-top: 10px;
  }

  .container {
    max-width: 1400px;
  }

  &__top-block {
    display: grid;
    grid:
      "info product resources company" auto
      ". countries countries countries" auto
      / minmax(324px, 1fr) minmax(0, 280px) minmax(0, 280px) minmax(0, 280px);
    grid-gap: 20px;
    border-bottom: 1px solid $footer-color-copyright;

    @media #{$tablet} {
      grid:
        "info info info info" auto
        "product resources company countries" auto
        / 1fr 1fr 1fr 1fr;
      grid-gap: 40px 20px;
      padding-bottom: 24px;
    }

    @media #{$mobile-width-max} {
      grid:
        "info info" auto
        "product resources" auto
        "company countries" auto
        / 1fr 1fr;
      grid-gap: 40px 20px;
      padding-bottom: 24px;
    }
  }

  &__apps {
    flex: 1 0 0px;
    margin-top: 50px;

    @media #{$tablet} {
      margin-top: 0;
      text-align: center;
    }

    &--wrapper {
      display: flex;
      justify-content: space-between;
    }

    &--item {
      @media #{$tablet} {
        flex: 1;
        background: #fff;
        border-radius: 4px;
      }

      &:first-of-type {
        margin-right: 15px;
      }
    }
  }

  &__column-info {
    grid-area: info;
    max-width: 300px;
    width: 100%;

    @media #{$tablet} {
      margin-top: 30px;
      max-width: none;
      display: flex;
      gap: 20px;
    }

    @media #{$mobile-width-max} {
      display: block;
    }
  }

  &__main-info {
    flex: 1 0 0px;

    @media #{$mobile-width-max} {
      margin-bottom: 40px;
    }
  }

  &__logo {
    display: block;
    margin: 0 0 25px;
  }

  &__logo-image {
    margin: 0;
    height: 35px;
    width: auto;
  }

  &__text {
    color: $color-white;
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 1.29;
    text-align: left;
  }

  &__navigation-column {
    &--product {
      grid-area: product;
    }
    &--resources {
      grid-area: resources;
    }
    &--company {
      grid-area: company;
    }
    &--countries {
      grid-area: countries;

      display: flex;
      align-items: center;
      margin-bottom: 16px;

      @media #{$tablet} {
        display: block;
      }
    }
  }

  &__navigation-title {
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1.4px;
    line-height: 1;
    margin: 0 16px 16px 0;
    text-transform: uppercase;
    text-align: left;
  }

  &__apps-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.2px;
    margin-bottom: 14px;
    color: #FFF;
    text-align: left;
  }

  &__navigation-list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0 0 16px;
    }

    &--countries {
      display: flex;
      gap: 16px;

      @media #{$tablet} {
        display: block;
      }
    }
  }

  &__navigation-link {
    color: $color-white;
    display: inline-block;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2px;
    text-decoration: none;

    &:hover {
      color: darken($color-white, 20%);
    }
  }

  &__bottom-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;

    @media #{$tablet} {
      flex-direction: column-reverse;
      margin-top: 0;
      text-align: center;
    }
  }

  &__copyright {
    color: rgba(255, 255, 255, 0.6);
    width: 344px;
  }

  &__info {
    flex: 1;
    max-width: calc(3 * 280px + 2 * 20px);
    display: flex;
    justify-content: space-between;
    gap: 20px;

    @media #{$tablet} {
      max-width: none;
      align-self: stretch;
      margin: 30px 0;
    }

    @media #{$mobile-width-max} {
      gap: 0;
      align-self: center;
      flex-direction: column;
    }
  }

  &__info-item {
    color: $color-white;
    font-size: 30px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.1px;
    flex: 1 0 0px;
    text-align: left;

    @media #{$tablet} {
      margin: 10px 0;
      text-align: center;
    }
  }
}
