@import "../../../variables";

.tax-quiz-page {
  background: url('@/static/images/pages/about/common/about-section-head-bg.svg') no-repeat 50% 0 / auto;
  margin: 0 0 100px;
  position: relative;

  @media #{$mobile} {
    margin: 0 0 60px;
  }

  .container {
    max-width: 970px;
  }

  &__head {
    padding: 74px 0 0;

    color: $color-white;
  }

  &__title {
    font-size: 60px;
    line-height: 72px;
    font-weight: 700;
    margin: 0 0 22px;
    text-align: center;
    color: $color-white;

    @media #{$mobile} {
      font-size: 34px;
      line-height: 40px;
      margin: 0 0 19px;
    }
  }

  &__sub-title {
    text-align: center;
    font-size: 16px;
    line-height: 22px;
    margin: 0 20px 35px;
  }

  &__form {
    min-height: 400px;
    box-shadow: 6px 11px 36px 0 rgba(0, 0, 0, .1), 0 2px 3px 0 rgba(9, 19, 35, .14);
    background-color: $color-white;
    border-radius: 4px;
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    @media #{$mobile} {
      height: auto;
      min-height: auto;
    }
  }

  &__window {
    border-radius: 4px;
    background: $color-white;
    width: 100%;

    @media #{$mobile} {
      position: relative;
      padding: 20px;
    }
  }

  // Answers

  &__answers {
    padding: 50px 40px;

    @media #{$mobile} {
      padding: 40px 0 0;
    }
  }

  &__answers-title {
    font-size: 34px;
    line-height: 40px;
    margin: 0 0 22px;
    font-weight: 700;
    text-align: center;
    color: $color-title;
  }

  &__answers-true-answer {
    margin: 0 0 5px;
  }

  &__answers-user-answer {
    margin: 5px 0 0;
  }

  // Success

  $tax-quiz-all-done-color: #0f940c;

  &__complete {
    color: $color-orange;

    &.all-done {
      color: $tax-quiz-all-done-color;
    }
  }

  &__complete-window {
    height: 400px;
    box-shadow: 6px 11px 36px 0 rgba(0, 0, 0, .1), 0 2px 3px 0 rgba(9, 19, 35, .14);
    border-radius: 4px;
    background: $color-white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    @media #{$mobile} {
      height: auto;
      padding: 45px 10px;
    }
  }

  &__complete-image {
    margin: 0 auto 24px;
    width: 186px;
    height: 204px;
    background: url('@/static/images/pages/expat-tax-advice/tax-quiz/done.svg') no-repeat 50% 50%;

    .all-done & {
      width: 172px;
      height: 221px;
      background: url('@/static/images/pages/expat-tax-advice/tax-quiz/all-done.svg') no-repeat 50% 50%;
    }
  }

  &__complete-title {
    font-size: 40px;
    line-height: 40px;
    font-weight: 700;
    margin-top: 30px;

    @media #{$mobile} {
      font-size: 34px;
      line-height: 30px;
    }
  }

  &__complete-info {
    position: relative;
    z-index: -1;
    font-size: 20px;
    line-height: 26px;
    padding: 20px 30px;
    text-align: left;
    border: 2px dashed $color-orange;
    background-color: $color-white;
    color: $color-orange;
    border-top: 0;
    border-radius: 0 0 4px 4px;
    margin: 0 30px;

    .all-done & {
      border-color: $tax-quiz-all-done-color;
      color: $tax-quiz-all-done-color;
    }

    p {
      margin: 0;
      padding: 14px 0;
    }

    @media #{$mobile} {
      margin: 0;
      font-size: 17px;
      text-align: justify;

      p {
        padding: 10px 0;
      }
    }
  }
}

.tax-quiz {
  &__start {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;

    @media screen\0 {
      height: 400px;
    }

    @media #{$mobile} {
      display: block;
      width: 100%;
      padding-bottom: 30px;
    }

    .btn {
      min-width: 250px;
    }
  }

  &__start-image {
    width: 244px;
    height: 218px;
    margin: 0 auto 28px;
    background: url('@/static/images/pages/expat-tax-advice/tax-quiz/start.svg') no-repeat 50% 50%;

    @media #{$mobile} {
      width: 200px;
      height: 180px;
      background-size: contain;
      margin: 30px auto;
    }
  }

  // Questionnaire

  &__questionnaire-item {
    padding: 20px 0 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    height: 100%;
    min-height: 400px;

    @media #{$mobile} {
      padding: 10px 20px 0;
    }
  }

  // Question

  &__question {
    font-size: 18px;
    line-height: 22px;
    margin: 0 0 20px;
    color: $color-title;
    font-weight: 700;
    text-align: justify;
    padding: 0 30px;

    @media #{$mobile} {
      padding: 0;
    }
  }

  // Answers

  &__answers {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    padding: 0 30px;

    @media #{$mobile} {
      padding: 0;
      margin: 0 0 10px;
    }
  }

  &__answers-item {
    width: calc(50% + 30px);
    position: relative;
    margin: 12px 0;

    .tax-quiz__label {
      margin-right: 30px;
    }

    &:nth-child(2n) {
      width: calc(50% - 30px);

      .tax-quiz__label {
        margin-right: 0;
      }
    }

    @media #{$mobile} {
      width: calc(100% - 30px);
      padding-right: 0;
      height: auto;

      &:nth-child(2n) {
        width: calc(100% - 30px);
      }
    }
  }

  &__input {
    display: block;
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    position: absolute;

    + .tax-quiz__label {
      border: 1px solid $sidebar-gb;
      height: 78px;
      border-radius: 4px;
      width: auto;
      left: 0;
      position: relative;
      padding: 12px;
      font-size: 16px;
      color: $color-grey;
      line-height: 22px;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-left: 30px;

      @media #{$mobile} {
        height: auto;
        min-height: 54px;
        width: 100%;
      }

      + .tax-quiz__circle {
        position: absolute;
        width: 20px;
        height: 20px;
        border: 1px solid $sidebar-gb;
        border-radius: 50%;
        left: 0;
        top: 50%;
        margin-top: -10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        font-weight: 700;
        background: $color-white;
        color: $color-light-black;
      }

      &:hover {
        border-color: $color-blue-dark;
        color: $color-blue-dark;

        + .tax-quiz__circle {
          border-color: $color-blue-dark;
          color: $color-blue-dark;
        }
      }
    }

    &:checked {
      + .tax-quiz__label {
        border-color: $color-blue;
        color: $color-blue;

        + .tax-quiz__circle {
          border-color: $color-blue;
          color: transparent;
          background: $color-blue url('@/static/images/icons/default/icon-check-mark-blue.svg') no-repeat 50% 50%;
          background-size: 12px 10px;
        }
      }
    }
  }

  // Footer

  &__questionnaire-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 0 30px 0 0;
    margin: 12px 0 0;
    width: 100%;

    .btn {
      min-width: 154px;
    }

    @media #{$mobile} {
      position: relative;
      padding: 25px 0;
      bottom: auto;
      margin: 0;

      .btn {
        min-width: auto;
        width: 50%;
      }
    }
  }

  &__step {
    border-left: 0;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0 30px;
    color: #A3A3A3;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;

    @media #{$mobile} {
      width: auto;
      border: 0;
      padding-left: 0;
      flex-direction: column;
      align-items: flex-start;
    }

    &.has-error {
      color: #D0021B;
    }
  }

  &__step-number {
    display: flex;
    align-items: flex-start;
  }

  &__step-error {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    padding: 4px 8px;
    background-color: #D0021B;
    color: $color-white;
    box-shadow: 0 4px 24px rgba(0, 8, 14, .12);
    border-radius: 5px;
    position: relative;
    margin-left: 7px;

    &:before {
      content: '';
      position: absolute;
      top: 10px;
      left: -4px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 4px 4px 0;
      border-color: transparent #D0021B transparent transparent;

      @media #{$mobile} {
        border-width: 0 4px 4px 4px;
        border-color: transparent transparent #D0021B transparent;
        left: 15px;
        top: -4px;
      }
    }

    @media #{$mobile} {
      margin-left: 0;
      font-size: 13px;
      line-height: 15px;
      margin-top: 3px;
    }

    @media screen\0 {
      font-size: 11px;
    }
  }
}

// Table

.tax-quiz-table {
  width: 100%;

  &__head {
    font-size: 14px;
    line-height: 21px;
    color: $color-white;
    background: $color-title;
    position: relative;
    text-transform: uppercase;
    font-weight: 700;

    @media #{$mobile} {
      height: 30px;
    }

    &:before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: -10px;
      z-index: 2;
      width: 10px;
      content: '';
      background: $color-title;
    }

    &:after {
      position: absolute;
      bottom: -10px;
      left: -10px;
      width: 0;
      height: 0;
      content: "";
      border-color: transparent #0a0e15 transparent transparent;
      border-style: solid;
      border-width: 10px 10px 10px 0;
    }

    .tax-quiz-table__cell {
      padding: 5px 13px;

      @media #{$mobile} {
        display: none;
      }
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__head,
  &__body {
    > .tax-quiz-table__row {
      > .tax-quiz-table__cell {
        &:first-child {
          width: 6%;
          text-align: center;
          font-weight: 700;

          @media #{$mobile} {
            width: 37px;
            padding-left: 20px;
          }
        }
      }

      .tax-quiz-table__row-info {
        width: 92%;

        @media #{$mobile} {
          display: block;
        }

        .tax-quiz-table__cell {
          width: 50%;

          @media #{$mobile} {
            width: 100%;
          }
        }
      }
    }
  }

  &__body {
    font-size: 16px;
    line-height: 22px;
    color: $color-grey;
    letter-spacing: .2px;
    border: 2px solid $color-lightest-grey;
    border-top: 0;
    background: $color-white;

    .tax-quiz-table__row-info {
      .tax-quiz-table__cell {
        &:last-child {
          margin-top: 5px;
          font-weight: 700;

          @media #{$mobile} {
            margin: 0;
            padding-top: 0;
          }
        }
      }
    }

    .tax-quiz-table__cell {
      padding: 12px 13px;
    }

    > .tax-quiz-table__row {
      &:nth-child(2n) {
        background: $color-lightest-grey;
      }
    }
  }

  &__true-answer {
    padding: 0 0 5px;
    font-weight: 700;
  }

  &__user-answer {
    padding: 5px 0 0;
    color: $color-orange;
  }
}
