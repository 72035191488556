$dark-tooltip: #303030;

[tfx-tooltip] {
  position: absolute;
  z-index: 9999;
  top: auto;
  bottom: calc(100% + 7px);
  left: 50%;
  transform: translateX(-50%);
  display: block;
  flex-wrap: nowrap;
  white-space: nowrap;
  width: auto;
  padding: 5px 10px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  background: $dark-tooltip;
  box-shadow: 0px 4px 24px rgba(0, 8, 14, .12);
  border-radius: 4px;
  border: 0;
  font-size: 14px;
  font-weight: normal;

  opacity: 0;
  visibility: hidden;
  transition: all .3s ease;

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -4px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: $dark-tooltip transparent transparent transparent;
    display: block;
  }

  /* stylelint-disable */
  $i: 50;
  @while $i < 501 {
    &[width-#{$i}] {
      width: #{$i}#{'px'};
      white-space: normal;
      flex-wrap: wrap;
    }

    @media #{$mobile} {
      &[mob-width-#{$i}] {
        width: #{$i}#{'px'} !important;
        white-space: normal;
        flex-wrap: wrap;
      }
    }

    $i: $i + 5;
  }

  &[width-auto] {
    width: auto;
  }
  /* stylelint-enable */

  &[bottom] {
    top: calc(100% + 12px);
    bottom: auto;

    &::before {
      bottom: auto;
      top: -5px;
      transform: translateX(-50%);
      border-width: 0 5px 5px 5px;
      border-color: transparent transparent $dark-tooltip transparent;
    }
  }

  &[left] {
    left: -10px;
    right: auto;
    transform: none;

    &::before {
      right: auto;
      transform: none;
    }

    $s: 0;
    @while $s < 26 {
      &[arrow-#{$s}] {
        &::before {
          left: #{$s}#{'px'};
        }
      }
      $s: $s + 1;
    }
  }

  &[right] {
    left: auto;
    right: -10px;
    transform: none;

    &::before {
      left: auto;
      transform: none;
    }

    $s: 0;
    @while $s < 26 {
      &[arrow-#{$s}] {
        &::before {
          right: #{$s}#{'px'};
        }
      }
      $s: $s + 1;
    }
    /* stylelint-enable */
  }

  a {
    color: #fff;
    text-decoration: underline;
  }
}
[has-tooltip] {
  position: relative;
  overflow: visible !important;

  &:hover [tfx-tooltip] {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%);
    transition-delay: .1s;

    &[left],
    &[right] {
      transform: none;
    }
  }

  &.button {
    overflow: visible;
  }
}
