.landing-tfx-tax,
.landing-vs {
  padding-bottom: 60px;
  position: relative;

  &:before,
  &:after {
    position: absolute;
    content: '';
    width: 100%;
    left: 0;
    background-repeat: no-repeat;
    background-size: 100% auto;
    z-index: 1;
  }

  &:before {
    bottom: 145px;
    background-color: white;
    background-image: url('@/static/images/pages/index/mobile-app/blue-wave-top.svg');
    background-position: left top;
    transform: rotate(180deg);
    height: 165px;
  }

  &:after {
    bottom: 0;
    height: 145px;
    background-color: $section-bg;
  }

  .container {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .on-boarding-team {
    padding-top: 0;
    &:before {
      position: absolute;
      content: '';
      height: 100px;
      bottom: 0;
      top: unset;
      background-color: white;
      background-image: url('@/static/images/pages/index/mobile-app/blue-wave-bottom.svg');
      background-position: left bottom;

      @media #{$min-width-xl} {
        height: 140px;
      }

      @media #{$mobile} {
        display: none;
      }
    }
  }
}

.landing-vs {
  padding-bottom: 0;
  height: auto;
  min-height: 500px;

  .header__home {
    @media #{$mobile} {
      height: auto;
    }
  }

  .breadcrumbs {
    display: none;
  }

  &:before,
  &:after {
    display: none;
  }

  .landing__header-right {
    flex: 1;
  }

  .container {
    width: 100%;
    padding-bottom: 0;
  }

  .page__home--waves {
    top: 0;
  }

  .page__home--wave-bottom {
    background-color: transparent;
    margin-top: -100px;
    position: relative;
    z-index: 11;
  }
}

.comparing-quotes {
  background-color: $color-sky-blue;
  position: relative;
  padding: 190px 0 105px;

  @media #{$tablet} {
    padding-top: 120px;
  }

  &__row {
    display: flex;
    padding: 20px 0;

    &--reverse {
      flex-direction: row-reverse;

      @media #{$tablet} {
        flex-direction: column;
      }
    }

    @media #{$tablet} {
      flex-direction: column;
    }
  }

  &:after,
  &:before {
    position: absolute;
    background-repeat: no-repeat;
    background-color: white;
    background-size: cover;
    width: 100%;
    content: '';
  }

  &:before {
    background-image: url('@/static/images/pages/landing/tfx-tax/light-blue-wave-left-top.svg');
    left: 0;
    bottom: 0;
    height: 190px;
    background-position: left top;
    top: 0;

    @media #{$xl} {
      height: 160px;
    }

    @media #{$lg} {
      height: 120px;
    }

    @media #{$md} {
      height: 90px;
    }

    @media #{$tablet} {
      height: 70px;
    }

    @media #{$extra-small} {
      height: 45px;
    }
  }

  &:after {
    height: 125px;
    bottom: -1px;
    right: 0;
    background-image: url('@/static/images/pages/landing/tfx-tax/blue-wave-top.svg');
    background-position: right bottom;

    @media #{$xl} {
      height: 105px;
      background-position: right top;
    }

    @media #{$mobile} {
      bottom: -40px;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;

    &-item {
      padding-left: 25px;
      padding-top: 3px;
      background-position: left top;
      background-image: url('@/static/images/pages/landing/tfx-tax/comparing-list.svg');
      background-repeat: no-repeat;
      margin: 0 0 10px;
      font-size: 14px;
      line-height: 1.2;
      text-align: left;
      min-height: 20px;
      color: $color-grey;
    }
  }

  &__image {
    margin: 15px 20px;

    >img {

      @media #{$tablet} {
        width: 100%;
      }
    }
  }

  @media #{$extra-small} {
    padding: 65px 0;
  }
}

.tfx-table-wrapper {
  overflow: auto;
  border: 1px solid #B6DAFD;
  border-radius: 4px;
  margin-top: 25px;
}

.tfx-table {
  width: 100%;
  border-collapse: collapse;

  &__row {
    border-bottom: 1px solid #B6DAFD;

    &:last-child {
      border-bottom: 0;
    }
  }

  &__price {
    border-left: 1px solid #B6DAFD;
    text-align: right;
    font-weight: bold;
  }

  &__th {
    padding: 10px 20px;
    background-color: #B6DAFD;
    font-weight: bold;
    font-size: 14px;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: left;
    color: $color-blue-navi;
  }

  td {
    padding: 15px 20px;
    font-size: 16px;
    line-height: 1.1;
    color: $color-grey;
    vertical-align: middle;

    @media #{$mobile} {
      width: auto !important;
    }
  }
}

.client-referrals {
  position: relative;
  padding: 190px 0 80px;
  background-color: $color-blue;

  @media #{$mobile} {
    padding-top: 100px;
  }

  &:before {
    content: '';
    position: absolute;
    right: 0;
    top: -1px;
    height: 188px;
    width: 100%;
    background-color: white;
    background-image: url('@/static/images/pages/landing/tfx-tax/dark-blue-wave-top.svg');
    background-position: right top;
    background-repeat: no-repeat;
    background-size: cover;

    @media #{$mobile} {
      height: 90px;
      top: -6px;
    }
  }

  &:after {
    content: '';
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: $color-blue;
    top: 185px;
    left: 0;

    @media #{$extra-small} {
      top: 82px;
    }
  }

  &__inner {
    display: flex;
    align-items: center;

    @media #{$mobile} {
      flex-direction: column;
    }
  }

  &__image {
    margin: 20px;

    >img {
      @media #{$mobile} {
        width: 100%;
      }
    }
  }

  &__descr {
    .static-title {
      color: white;
    }

    p {
      color: white;
      font-size: 25px;
      line-height: 1.2;
    }
  }

  .btn {
    width: 240px;

    @media #{$mobile} {
      width: 100%;
    }
  }
}
