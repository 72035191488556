$list-containers__item-height: 340px;

body {
  &.list-containers-page {
    background: $color-border;
  }
}

.list-containers-page {
  .page-title {
    margin-top: 110px;
  }
}

.list-containers {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 70px;

  &__item {
    background: $color-white;
    border-radius: $border-radius-base;
    display: flex;
    height: $list-containers__item-height;
    margin: 0 30px 30px 0;
    position: relative;
    width: calc(50% - 15px);

    &:nth-child(2n) {
      margin-right: 0;
    }

    @media #{$mobile} {
      width: 100%;
    }
  }

  &__item-content {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    padding: 55px 25px 55px 40px;
    width: 55%;
  }

  &__item-date {
    color: $color-light-grey;
    font-size: 14px;
    font-weight: 700;
    left: 40px;
    letter-spacing: .3px;
    line-height: 1;
    position: absolute;
    top: 35px;
  }

  &__item-wrapper-title {
    display: block;
    width: 100%;
  }

  &__item-title {
    color: $color-title;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: .5px;
    line-height: 1.2;
    margin: 0 0 10px;
    max-height: 140px;
    overflow: hidden;
    width: 100%;
  }

  &__item-category {
    color: $color-orange;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: .3px;
    line-height: 1.3;
    width: 100%;
  }

  &__item-link {
    bottom: 35px;
    color: $color-blue;
    font-size: 14px;
    font-weight: 700;
    left: 40px;
    letter-spacing: 1.4px;
    line-height: 1;
    position: absolute;
    text-transform: uppercase;
  }

  &__item-photo {
    align-items: center;
    border-radius: 0 #{$border-radius-base} #{$border-radius-base} 0;
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 45%;

    @media #{$ie} {
      flex-direction: column;
    }

    img {
      height: 100%;
      max-height: 100%;
      max-width: none;
      width: auto;
    }

    &.image-preloader {
      position: relative;

      &::after {
        background-color: rgba(255, 255, 255, .85);
        background-image: url('@/static/images/default/loader.gif');
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: 80px;
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }

  &__item-image {
    max-height: 100%;
    max-width: none;
    width: auto;
  }

  &__wrapper-select {
    height: 20px;
    line-height: 20px;
    margin-right: -10px;
    position: absolute;
    right: 30px;
    top: 30px;

    .dropdown-menu {
      margin-top: 15px;
      min-width: 240px;
    }

    .dropdown-menu {
      &::before,
      &::after {
        left: auto;
        right: 10px;
      }
    }
  }

  &__button-select {
    background: transparent;
    border: 0;
    color: $color-blue;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: .7px;
    line-height: 20px;
    padding: 0 35px 0 0;
    position: relative;
    text-transform: uppercase;

    &:hover {
      color: darken($color-blue, 5%);
    }

    &::after {
      background-image: url('@/static/images/pages/articles/icon-articles-latest-news__article-link.svg');
      background-position: 50% 65%;
      background-repeat: no-repeat;
      background-size: 7px;
      border: 0;
      bottom: 0;
      content: '';
      height: 100%;
      margin: 0;
      position: absolute;
      right: 0;
      top: 0;
      transform: rotate(90deg);
      width: 35px;
    }
  }
}
