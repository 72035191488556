.login-page {
  align-items: stretch;
  display: flex;
  margin: 0;
  min-height: 100vh;
  min-width: auto;
  padding: 0;
  color: #303030;

  @media #{$ie} {
    height: 100vh;
  }

  @media #{$tablet} {
    flex-direction: column-reverse;
  }

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 29px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 22px;
  }

  &__text {
    font-size: 14px;
    line-height: 20px;
  }

  &__buttons {
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    margin-top: 24px;
  }

  &__buttons-group {
    display: flex;

    .button+.button {
      margin-left: 10px;
    }
  }

  &__auth {
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 40%;

    @media #{$tablet} {
      padding: 150px 0 30px;
      width: 100%;
      height: 100vh;
    }

    .button-signup {
      position: absolute;
      z-index: 12; // above loader overlay on login page
      right: 30px;
      top: 23px;
      max-width: 95px;
      background-color: transparent;
      background-image: none;
      border: 1px solid $color-blue;
      color: $color-blue;

      &:hover {
        background-color: $color-blue;
        border-color: $color-blue;
        color: $color-white;
      }

      @media #{$ie} {
        padding-top: 14px;
      }
    }

    .button-login {
      position: absolute;
      right: 130px;
      top: 23px;
      max-width: 93px;

      &--forgot-pass {
        right: 30px;
      }

      @media #{$ie} {
        padding-top: 14px;
      }
    }
  }

  &__auth-content {
    width: 320px;
  }

  &__logo {
    display: block;
    margin: 0 auto 46px;
    width: auto;
    height: 48px;
    position: absolute;
    left: 30px;
    top: 20px;

    img {
      height: 100%;
    }

    &.mobile {
      display: none;

      @media #{$mobile} {
        display: block;
      }

      @media #{$tablet} {
        display: block;
      }
    }

    @media #{$tablet} {
      display: none;
    }
  }

  &__separator {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 25px;
    border-bottom: 1px solid #D8D8D8;
    margin-bottom: 25px;

    &::after {
      content: attr(data-attr);
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      font-size: 14px;
      color: #D8D8D8;
      bottom: -10px;
      background: white;
      padding: 0 15px;
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  &__flash-message {
    padding: 12px;
    border-radius: 4px;
    border: solid 1px #F9630E;
    background-color: #FFF1E3;
    font-size: 12px;
    line-height: 16px;
    color: #F9630E;
    margin: 0 auto 10px;
    width: 100%;

    a {
      text-decoration: underline;
    }
  }

  .link {
    color: #2588ed;
    text-decoration: underline;
    font-size: 14px;
    background: none;
    border: 0;

    &:hover {
      text-decoration: none;
    }
  }

  .text {
    color: #404040;
    font-size: 14px;
    padding-top: 1px;

    &-reset-pass {
      margin-bottom: 70px;
    }
  }

  .additionals-between {
    display: flex;
    justify-content: space-between;
    margin: 17px 0;
    user-select: none;

    label.text::before {
      border-width: 1px;
    }
  }

  .validate,
  .input-password {
    position: relative;

    &.validate-msg,
    &.server-error {

      .input,
      .multiselect {
        border-color: $color-orange;
      }

      .validate-msg,
      .error {
        top: calc(100% + 7px);
        right: unset;
        left: 50%;
        transform: translateX(-50%) translateY(-2px);
        transition: all .3s ease-in-out;
        line-height: 1;
        white-space: nowrap;

        &::before {
          top: -7px;
          right: unset;
          left: 42%;
          opacity: 1;
          position: absolute;
          transform: rotate(180deg);
        }
      }

      .error {
        position: absolute;
        z-index: 1;
        border: 1px solid $color-orange;
        color: $color-orange;
        box-shadow: none;
        padding: 6px 4px;
        border-radius: 4px;
        background: $color-white;
        font-size: 12px;

        &:before {
          position: absolute;
          top: -7px;
          right: unset;
          left: 42%;
          opacity: 1;
          transform: rotate(180deg);
          height: 0;
          border-bottom: 5px solid $color-orange;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          content: "";
          display: block;
        }
      }

      .validate-msg {
        left: unset;
        right: -26px;
      }
    }

    .additionals-between {
      color: $color-light-black;
    }
  }

  &__gradient {
    background: linear-gradient(135deg, #FED7AA 0%, #FFEDD5 40.32%, #BAD7F5 76.09%);
    background-size: 240% 240%;
    animation: gradient-animation 12s ease infinite;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 70px 0 50px;
    width: 60%;

    @media #{$tablet} {
      width: 100%;
      padding: 20px 0 40px;
    }
  }

  @keyframes gradient-animation {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
}

.login-form {
  width: 100%;
  max-width: 320px;

  @media #{$mobile} {
    width: calc(100% - 30px);
  }

  &__label {
    display: block;
    width: 100%;
    margin: 0 0 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  &__input {
    outline: none;
    background: #FFFFFF;
    border: 1px solid #D6D6D6;
    border-radius: 4px;
    min-height: 40px;
    max-height: 40px;
    padding: 11px 10px;
    color: inherit;
    position: relative;
    display: block;
    width: 100%;
    transition: all .3s ease;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 10px;

    &::placeholder {
      color: #ACACAC;
    }
  }
}

.update-pass-btn {
  margin-top: 15px;

  &:disabled {
    background: #b7babe;
    border-color: #b7babe;
    cursor: not-allowed;

    &:hover {
      background: #b7babe;
      border-color: #b7babe;
      color: $color-white;
    }
  }
}

.alert {
  position: relative;
  padding: 12px 15px;
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;

  .link,
  p {
    font-size: 14px;
    line-height: 16px;
  }

  &-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }

  &-error {
    background-color: #f2dede;
    border-color: #ebcccc;
    color: $color-dark-orange;
  }
}
