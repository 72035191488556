$animationSpeed: 30s;

@mixin blue-gradient {
  background: linear-gradient(to right,  #2588ED 0%,rgba(255,255,255,0) 100%);
}

@keyframes scroll {
  0% { transform: translateX(0); }
  100% { transform: translateX(calc(-164px * 7))}
}

.pricing-page {
  position: relative;
  color: $color-grey;

  &--landing {
    position: relative;

    .pricing__services {
      padding-top: 60px;

      @media #{$mobile} {
        display: flex !important;
        padding-bottom: 0;
      }

      @media #{$extra-small} {
        flex-direction: column;
      }
    }

    .pricing__head {
      background-color: unset;

      .static-title {
        color: $color-title;
      }
    }

    .pricing__services-item {
      margin: 0 16px;
    }
  }

  &--landing-bg {
    background-color: $color-sky-blue;
    background-image: url('@/static/images/pages/index/goals/layer-1.svg'), url('@/static/images/pages/index/goals/layer-2.svg'), url('@/static/images/pages/index/goals/layer-3.svg');
    background-repeat: no-repeat;
    background-position: center;
    height: 80%;
    position: absolute;
    width: 100%;
    z-index: -1;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      height: 145px;
      width: 100%;
      background-color: white;
      background-image: url('@/static/images/pages/landing/tfx-tax/blue-wave-top.svg');
      background-position: right bottom;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}

.pricing {
  &__container {
    max-width: 1050px;
    padding: 0 15px;
    margin: 0 auto;

    &--inside {
      padding: 0 30px;

      @media #{$tablet} {
        padding: 0;
      }
    }
  }

  &-bg {
    background-color: $color-sky-blue;
    background-image: url('@/static/images/pages/index/goals/layer-1.svg'), url('@/static/images/pages/index/goals/layer-2.svg'), url('@/static/images/pages/index/goals/layer-3.svg');
    background-repeat: no-repeat;
    background-position: center;
    height: 80%;
    position: absolute;
    width: 100%;
    z-index: -1;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      height: 145px;
      width: 100%;
      background-color: white;
      background-image: url('@/static/images/pages/landing/tfx-tax/blue-wave-top.svg');
      background-position: right bottom;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  &__head {
    padding: 40px 0 0;

    .static-title {
      margin: 0;
      text-align: center;
    }
  }

  &__services {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 60px 0 50px;

    @media #{$mobile} {
      display: flex !important;
      padding-bottom: 0;
    }

    @media #{$mobile} {
      flex-direction: column;
    }
  }

  &__services-item {
    background: $color-white;
    box-shadow: 9px 19px 35px 0 rgba(0, 0, 0, .1), 0 2px 4px 0 rgba(9, 19, 35, .14);
    border-radius: 10px;
    width: 33.33%;
    text-align: center;
    padding: 0 36px 20px;

    @media #{$tablet} {
      padding: 0 20px 20px;
    }

    @media #{$mobile} {
      border-radius: 10px 10px 5px 5px;
      padding: 0 20px 20px;
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0 0 60px;
    }

    &--premier {
      border-radius: 10px 0 0 10px;
      min-height: 300px;

      .pricing__services-name {
        margin: -10px 0 10px;
      }

      @media #{$mobile} {
        min-height: auto;
        border-radius: 10px;
      }
    }

    &--sp {
      border-radius: 0 10px 10px 0;
      min-height: 300px;

      @media #{$mobile} {
        min-height: auto;
        border-radius: 10px;
      }
    }

    &--core {
      margin: -25px 0 0;
      min-height: 350px;
      z-index: 1;

      .pricing__services-name {
        margin: -10px 0 10px;
      }

      .pricing__services-price {
        font-size: 60px;
        font-weight: 400;
        margin: 0 0 20px;
      }

      .pricing__services-descr {
        min-height: 35px;
      }

      @media #{$mobile} {
        min-height: auto;
        margin: 0 0 60px;
      }
    }

    &--two-cols {
      margin: 0 16px;

      @media #{$mobile} {
        width: calc(100% - 30px);
        margin-bottom: 60px;
      }
    }

    &--two-cols {
      margin: 0 16px;

      @media #{$mobile} {
        width: calc(100% - 30px);
        margin-bottom: 60px;
      }
    }
  }

  &__services-image {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    background: $color-white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: -40px auto 0;
  }

  &__services-name {
    font-size: 25px;
    line-height: .9;
    margin: 0;
    font-weight: 700;
    height: 50px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      position: relative;
      display: inline-block;

      &:before,
      &:after {
        position: absolute;
        content: '';
        top: 50%;
        margin-top: -1px;
        height: 1px;
        width: 70px;
        opacity: .4;
        background-color: #D3E7FB;
      }

      &:before {
        right: calc(100% + 20px);
      }

      &:after {
        left: calc(100% + 20px);
      }
    }

    &--small {
      font-size: 16px;
    }
  }

  &__services-price {
    font-size: 60px;
    line-height: 1;
    margin: 0 0 15px;
    color: $color-orange;

    &--small {
      font-size: 34px;
      font-weight: bold;
    }
  }

  &__services-descr {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: .2px;
    color: $color-grey;
  }

  &__services-info {
    color: #b7babe;
    font-size: 14px;
    text-align: left;
  }

  // Features
  &__features {
    border: 2px dashed rgba(255, 255, 255, .5);
    border-radius: 4px;
    padding: 40px 0;
    margin: 0 0 25px;
    position: relative;
    display: flex;

    @media #{$mobile} {
      flex-direction: column;
      padding: 0 0 30px;
      border: 0;
      margin-top: 60px;
    }
  }

  &__features-title {
    position: absolute;
    left: 50%;
    margin-left: -60px;
    top: -15px;
    font-size: 22px;
    font-weight: 700;
    color: $color-white;
    width: 120px;
    height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2588ed;

    @media #{$mobile} {
      display: none;
    }
  }

  &__features-column {
    width: 33.33%;
    padding: 30px 40px 25px;
    border-right: 2px solid rgba(255, 255, 255, .5);

    @media #{$mobile} {
      padding: 0 0 30px;
      width: 100%;
      margin-bottom: 100px;
      border: 2px dashed rgba(255, 255, 255, .5);
      border-top: 0;
      border-radius: 0 0 4px 4px;
    }

    &:last-child {
      border: 0;

      @media #{$mobile} {
        border: 2px dashed rgba(255, 255, 255, .5);
        border-top: 0;
        margin-bottom: 0;
      }
    }
  }

  &__features-item {
    font-size: 13px;
    line-height: 17px;
    letter-spacing: .2px;
    color: $color-white;
    margin: 0 0 5px;
    padding: 0 0 0 20px;
    position: relative;

    @media #{$mobile} {
      font-size: 14px;
      padding: 0 20px;
      margin: 5px 20px;
    }

    a {
      color: $color-white;

      &:hover {
        text-decoration: underline;
      }
    }

    &:before {
      position: absolute;
      content: '';
      top: 3px;
      left: 0;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: $color-white url('@/static/images/icons/default/icon-check-mark-blue.svg') no-repeat 50% 50%;
    }
  }

  &__features-list {
    list-style: none;
    padding: 0;
    margin: 0;

    &-item {
      padding-left: 20px;
      background-position: left top 2px;
      background-image: url('@/static/images/icons/default/icon-list-pricing.svg');
      background-repeat: no-repeat;
      margin: 0 0 10px;
      font-size: 14px;
      line-height: 1.2;
      color: $color-blue-navi;
      text-align: left;
    }
  }
}

.pricing-bg {
  background-color: $color-sky-blue;
  background-image: url('@/static/images/pages/index/goals/layer-1.svg'), url('@/static/images/pages/index/goals/layer-2.svg'), url('@/static/images/pages/index/goals/layer-3.svg');
  background-repeat: no-repeat;
  background-position: center;
  height: 80%;
  position: absolute;
  width: 100%;
  z-index: -1;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    height: 145px;
    width: 100%;
    background-color: white;
    background-image: url('@/static/images/pages/landing/tfx-tax/blue-wave-top.svg');
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

// Offers
.pricing-offers {
  &__inner {
    background-color: $color-sky-blue;
    padding: 20px 0;
  }

  &__item {
    display: flex;
    margin: 15px 0;

    > div {
      @media #{$mobile} {
        width: 100%;
      }
    }

    @media #{$mobile} {
      flex-direction: column;
    }

    &:nth-child(2n) {
      @media #{$mobile} {
        flex-direction: column-reverse;
      }
    }
  }

  &__image {
    margin: 10px 20px;

    @media #{$mobile} {
      width: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;

    h3,
    p {
      text-align: left;

      @media #{$mobile} {
        text-align: center;
      }
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__list-item {
    color: $color-grey;
    font-size: 16px;
    margin: 10px 0;
    padding: 0 0 0 25px;
    position: relative;

    &::before {
      background: url('../images/pages/services/pricing/icon-graphic-block-list-blue.svg') no-repeat 50% 50%;
      content: '';
      height: 20px;
      left: 0;
      position: absolute;
      top: 2px;
      width: 15px;
    }
  }

  &__text {
    font-size: 22px;
    line-height: 32px;
    color: $color-title;
    max-width: 454px;
  }
}

// Graphic Block
.pricing-text-graphic-block {
  background: $color-blue;
  color: $color-white;
  position: relative;

  &_top-indent {

    &:after {
      position: absolute;
      content: '';
      height: 20px;
      width: 100%;
      background-color: $color-white;
      left: 0;
      top: 0;

      @media #{$mobile} {
        display: none;
      }
    }
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 280px;
    position: relative;
    padding: 0 20px;

    @media #{$mobile} {
      flex-direction: column;
    }

    &.columned {
      flex-direction: column;

      .text-align-left {
        @media #{$ie} {
          width: 100%;
        }
      }
    }
  }

  &_inner {

    @media #{$mobile} {
      margin-bottom: 50px;

      .btn {
        width: 100%;
      }
    }

    @media #{$mobile} {
      min-height: auto;
      flex-direction: column;
    }
  }

  &.last {
    @media #{$mobile} {
      padding: 45px 5px;
    }
  }

  h2,
  p {
    text-align: left;
    color: $color-white;
  }

  &__image {
    margin: 0 0 0 70px;
    position: relative;
    z-index: 1;

    @media #{$mobile} {
      margin: 35px auto 0;
    }

    &--comparing {
      bottom: 0;
      position: absolute;
      right: 0;

      @media #{$mobile} {
        position: relative;
        left: auto;
        right: auto;
      }
    }
  }

  &__title {
    color: $color-white;
    line-height: 1.2;
    margin: 0 0 10px;

    @media #{$mobile} {
      font-size: 34px;
      text-align: center;
    }
  }

  &__sub-title {
    font-size: 24px;
    font-style: italic;
    letter-spacing: .5px;
    line-height: 1.2;
    margin: 0;

    @media #{$mobile} {
      text-align: center;
    }
  }

  &__list {
    list-style: none;
    margin: 20px 0 0;
    padding: 0;
  }

  &__list-item {
    color: $color-white;
    font-size: 16px;
    margin: 10px 0;
    padding: 0 0 0 25px;
    position: relative;

    &::before {
      background: url('@/static/images/pages/services/pricing/icon-graphic-block-list.svg') no-repeat 50% 50%;
      content: '';
      height: 20px;
      left: 0;
      position: absolute;
      top: 2px;
      width: 15px;
    }
  }
}

// Head Info
.pricing-info {
  border-radius: 4px;
  box-shadow: 9px 19px 35px 0 rgba(0, 0, 0, .1), 0 2px 4px 0 rgba(9, 19, 35, .14);
  background-color: $color-white;
  display: flex;
  padding: 20px 5px;
  position: relative;

  @media #{$mobile} {
    flex-direction: column;
    padding: 20px;
  }

  &.two-col {
    bottom: -65px;
  }

  &__column {
    display: flex;
    align-items: center;
    border-right: 2px solid rgba(183, 186, 190, .4);
    padding: 20px;

    @media #{$mobile} {
      border-right: 0;
      border-bottom: 2px solid rgba(183, 186, 190, .4);
    }

    &:last-child {
      border: 0;
    }

    .two-col & {
      width: 50%;

      @media #{$mobile} {
        width: 100%;
      }
    }

    .three-col & {
      width: 33.33%;
      align-items: flex-start;

      @media #{$mobile} {
        width: 100%;
      }
    }
  }

  &__image {
    margin: 0 15px 0 0;
    min-width: 45px;

    .three-col & {
      margin-top: 4px;
    }
  }

  &__text {
    font-size: 17px;
    line-height: 22px;
    color: $color-title;
    margin: 0;
  }

  &__title {
    font-size: 17px;
    line-height: 22px;
    color: $color-title;
    margin: 0;
    font-weight: 700;
  }

  &__desc {
    font-size: 16px;
    line-height: 21px;
    color: #656b6f;
    margin: 0 0 5px;
  }
}

// Additional

.pricing-additional-forms {
  padding: 115px 0 60px;
}

.pricing-additional {
  .page-title {
    font-size: 34px;
    line-height: 40px;
    margin: 0 0 25px;
    letter-spacing: 0;
  }
}

.pricing-additional-services {
  padding: 50px 0 0;
  background-image: url('@/static/images/pages/services/pricing/additional-bg.svg');
  background-repeat: no-repeat;
  background-position: 50% calc(100% + 3px);
  background-size: contain;

  &_gray-wave {
    background-image: url('@/static/images/default/gray-wave.svg');
    background-repeat: no-repeat;
    background-position: 50% calc(100% + 3px);
    background-size: 100% auto;
    padding-bottom: 30px;

    @media #{$tablet} {
      padding: 0 20px;
    }

    @media #{$tablet} {
      background-size: cover;
    }
  }
}

// Local Tax Return Preparation
.pricing-tax-return {
  background: $color-blue;
  padding: 50px 0 100px;
  text-align: center;
  color: $color-white;
  font-size: 17px;
  line-height: 22px;

  .page-title {
    color: $color-white;
    margin: 0 0 17px;
  }

  a.link {
    color: $color-white;
    font-weight: 700;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .btn {
    min-width: 260px;
  }

  &__text {
    margin: 0 0 40px;
  }

  &__graphic {
    margin: 25px 0 40px;
  }
}

.pricing__our-experts {
  margin-top: 50px;

  &_line {
    height: 1px;
    max-width: 860px;
    margin: 55px auto;
    opacity: .4;
    background-color: white;
  }

  &_wrapper {
    display: flex;
    margin-top: 50px;

    > div {
      width: 50%;
      height: inherit;

      @media #{$mobile} {
        width: 100%;
      }
    }

    @media #{$mobile} {
      flex-direction: column;
    }
  }

  &_descr {
    padding: 20px 40px;
    display: flex;
    height: inherit;
    width: 50%;
    justify-content: center;
    flex-direction: column;

    @media #{$mobile} {
      padding: 20px;
    }
  }

  &_slider {
    height: 220px;
    width: 100%;
    overflow:hidden;
    position: relative;

    &::before,
    &::after {
      @include blue-gradient;
      content: '';
      height: 100%;
      position: absolute;
      width: 120px;
      z-index: 2;
    }

    &::after {
      right: 0;
      top: 0;
      transform: rotateZ(180deg);
    }

    &::before {
      left: 0;
      top: 0;
    }
  }

  &_slider-track {
    animation: scroll $animationSpeed linear infinite;
    display: flex;
    width: calc(164px * 20);

    > img {
      width: 140px;
      height: 140px;
      object-fit: cover;
      margin: 12px;
      box-shadow: 9px 19px 35px rgba(0, 0, 0, .1), 0 4px 4px rgba(0, 0, 0, .25);

      &:nth-child(odd) {
        margin-top: 42px;
      }
    }
  }
}
