.styled-page-quiz {
  background: $color-title;
  border-radius: 6px;
  margin: 95px -60px;
  padding: 30px;
  position: relative;

  &::before,
  &::after {
    background: $color-title;
    content: '';
    height: 50%;
    left: 10px;
    position: absolute;
  }

  &::before {
    top: 0;
    width: 285px;
  }

  &::after {
    bottom: 0;
    width: 430px;
  }

  @media #{$lg-xl} {
    &::before {
      width: 405px;
    }

    &::after {
      width: 550px;
    }
  }

  @media #{$tablet} {
    margin: 95px 0 40px;
    padding-top: 440px;

    &::before,
    &::after {
      display: none;
    }
  }

  @media #{$mobile} {
    padding-top: 240px;
  }

  .btn {
    min-width: 335px;

    @media #{$mobile} {
      min-width: unset;
    }
  }
}

.styled-page-quiz__image {
  left: -150px;
  position: absolute;
  top: -40px;
  z-index: 2;

  @media #{$lg-xl} {
    left: -30px;
  }

  @media #{$tablet} {
    left: 0;
    right: 0;
    top: -60px;
  }
}

.styled-page-quiz__content {
  border: 2px dashed $color-white;
  border-radius: 6px;
  color: $color-white;
  padding: 25px 80px 40px 460px;
  text-align: center;

  @media #{$lg-xl} {
    padding: 25px 10px 40px 500px;
  }

  @media #{$tablet} {
    padding: 25px 20px 40px;
  }
}

h2.styled-page-quiz__title {
  color: $color-white;
  font-size: 60px;
  line-height: 70px;
  margin: 0 0 10px;

  @media #{$mobile} {
    font-size: 42px;
  }
}

p.styled-page-quiz__text {
  font-size: 25px;
  line-height: 30px;
  margin: 0 0 35px;
  text-align: center;
}
