$login-page-graphic-top-color: $color-blue;
$login-page-graphic-bottom-color: #1c68b6;

.login-page__graphic {
  position: relative;
  align-items: center;
  background-image:
    url('@/static/images/pages/client/login/login-page-graphic-bg.png'),
    linear-gradient(to bottom, $login-page-graphic-top-color, $login-page-graphic-bottom-color);
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 70px 0 0;
  width: 60%;

  @media #{$tablet} {
    display: none;
  }

  .btn__sign-up {
    letter-spacing: 1px;
    padding: 10px 0 0;
    position: absolute;
    right: 30px;
    top: 20px;
    width: 87px;
    z-index: 3;

    &:hover {
      border-color: $color-white;
    }
  }
}

.login-page__logo {
  z-index: 11;
}

.forgot-pass-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  top: 0;
  left: 0;
}
