.resume-work {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 70px 0 100px;
}

.modal-graphic-form {
  min-height: 400px;
  border-radius: 10px;
  box-shadow: 0 19px 43px 0 rgba(0, 0, 0, .08), 0 23px 40px 0 rgba(0, 0, 0, .41);
  background-color: $color-white;
  display: flex;
  align-items: stretch;
  width: 660px;
  overflow: hidden;
  position: relative;
}

.form-text {
  .input {
    min-height: 120px;
  }

  .form-group__styled {
    margin: 0 0 30px;
  }
}

.modal-graphic-form__info {
  width: 166px;
  min-width: 166px;
  height: 400px;
  background-image: linear-gradient(to bottom, #2586ea, #18538f);
  padding: 50px 20px 0 40px;
}

.modal-graphic-form__desc {
  color: $color-white;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 17px;
}

.modal-graphic-form__image {
  position: absolute;
  bottom: 0;
  left: 10px;
}

.modal-graphic-form__content {
  padding: 40px;
}

.modal-graphic-form__title {
  font-size: 34px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -.1px;
  color: $color-title;
  margin: 0 0 20px;
  text-align: left;

  @media screen\0 {
    font-size: 30px;
  }
}

.modal-graphic-form__sub-title {
  font-size: 17px;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 700;
  color: $color-orange;
  margin: 0 0 20px;
}
