$color-header-text-title: #11467d;
$height-header-block: 625px;
$width-header-form: 574px;
$header__home__form-background: #ffffff;
$wrapper-photos-item-comment-border: #dfdfdf;
$wrapper-photos-item-comment-box-shadow: rgba(0, 0, 0, 0.24);
$wrapper-photos-item-comment-background: rgba(255, 255, 255, 0.9);

.page__home {
  .animated {
    animation-duration: 1.5s;
    animation-fill-mode: both;
  }

  .container {
    overflow: hidden;

    &.overflow-visible {
      overflow: visible;
    }
  }

  &--waves {
    top: -30px;
    position: relative;
    background-color: $color-sky-blue;

    @media #{$md} {
      margin-top: 75px;
      top: 0;
    }
  }

  &--wave {
    background-color: white;
  }
}

.header {
  &__home {
    height: $height-header-block;
    position: relative;

    @media #{$md} {
      height: auto;
      padding-bottom: 40px;
    }

    .container {
      position: relative;
    }

    .icon-clock {
      margin-top: -3px;
    }

    .auth-form-buttons {
      position: relative;
      width: 100%;

      &__group {
        display: flex;

        .button+.button {
          margin-left: 15px;
        }
      }
    }
  }

  &__home-content {
    left: 15px;
    position: absolute;
    top: 0;
    z-index: 1;

    @media #{$mobile} {
      position: static;
      text-align: center;
    }
  }

  &__home-image {
    float: right;
  }

  &__home-title {
    font-size: 40px;
    font-weight: 700;
    line-height: 1;
    margin: 0 0 14px;
    position: relative;
    color: $color-title;
    text-align: left;
  }

  &__home--sub-title {
    font-size: 24px;
    line-height: 1.4;
    margin: 0;
    text-align: left;
    color: $color-title;
  }

  &__home-info-text {
    color: $color-text;
    display: block;
    margin: 70px 0 32px;

    @media #{$md} {
      margin: 40px 0 32px 0;
    }

    &:hover {
      text-decoration: none;
      color: $color-text;
    }
  }

  &__home-form {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 430px;
    width: 100%;
    min-height: 190px;
    padding: 25px 25px 40px;
    border-radius: 6px;
    border: 1px solid #A6C5E6;
    margin-bottom: 20px;

    @media #{$mobile} {
      margin: 0 auto 20px;
    }
  }

  &__home-form-title {
    color: $color-orange;
    font-size: 17px;
    padding: 0 0 15px;
    font-weight: bold;
    text-transform: uppercase;
  }

  &__wave {
    background: url("@/static/images/pages/services/pricing/additional-bg.svg") no-repeat bottom center / contain;
    height: 100%;
    min-height: 100%;
    position: absolute;
    z-index: -1;
    bottom: -3px;
    right: 0;
    width: 100%;
    pointer-events: none;
  }
}

.landing__header {
  .separator::after {
    background: #f5f5f5;
  }
}

.slider-photos {
  float: right;
  height: $height-header-block;
  position: relative;
  width: 610px;
  margin-top: 70px;
  overflow: visible;

  @media #{$md} {
    width: 480px;
  }

  @media #{$mobile} {
    display: none;
  }

  .item-photo {
    max-width: 100%;
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    opacity: 0;
    padding-bottom: 60px;
    overflow: visible;

    @keyframes fadeOut {
      from {
        opacity: 1;
      }

      to {
        opacity: 0;
      }
    }

    img {
      opacity: 0;
      transition: opacity 1s ease;
      position: relative;
      z-index: -2;
    }

    &.active {
      opacity: 1;

      img {
        opacity: 1;
        display: block;
        width: auto;
        max-width: none;
        height: 600px;
      }

      .item-comment {
        opacity: 1;
        transform: translateY(0px);
      }
    }
  }

  .item-comment {
    position: absolute;
    top: 66%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    background: white;
    box-shadow: 9px 19px 25px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-size: 14px;
    line-height: 18px;
    max-width: 440px;
    padding: 24px 30px;
    color: $color-grey;
    z-index: 2;
    opacity: 0;
    transform: translateY(20px);
    transition: all 2s cubic-bezier(0.29, 0.51, 0, 1.09);
    transition-property: opacity, transform;
    transition-delay: 0.5s;
    overflow: visible;

    &-title {
      font-size: 16px;
      line-height: 23px;
      color: $color-title;
    }

    &-text {
      padding: 7px 0 15px;
      font-style: italic;
    }

    &-link,
    &-name {
      color: $color-blue;
      font-size: 16px;
      line-height: 23px;
    }
  }
}

#mobile-navigation {
  @media #{$tablet} {
    position: fixed;
    width: 70px;
    height: 70px;
    top: 0;
    left: 0;
    background: $color-white;
    z-index: 1041;

    .mobile-nav__button {
      top: 5px;
      left: 10px;
    }
  }
}
