
@mixin arrow_in_block ($color, $transform) {
  border-color: $color;
  border-style: solid;
  border-width: 2px 2px 0 0;
  box-sizing: border-box;
  content: '';
  height: 10px;
  position: absolute;
  transform: rotate($transform);
  vertical-align: top;
  width: 10px;
}

// scss-docs-start btn-size-mixin
@mixin button-size($padding-y, $padding-x, $font-size, $border-radius) {
  padding: $padding-y $padding-x;
}
