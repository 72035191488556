$login-page-text-color: #a3a3a3;

.login-page__form {
  font-size: 16px;
  position: relative;
  width: 100%;

  .form-group__styled--password {
    margin: 0 0 20px;
  }
}

.login-page__form-checkbox {
  border: 0;
  box-shadow: none;
  float: left;
  height: 0;
  opacity: 0;
  outline: none;
  overflow: hidden;
  width: 0;

  + label {
    color: $color-title;
    cursor: pointer;
    display: block;
    font-size: 16px;
    letter-spacing: .2px;
    line-height: 20px;
    margin: 0;
    padding: 0 0 0 30px;
    position: relative;

    &::before {
      background: $color-white;
      border: 2px solid $color-blue;
      border-radius: 4px;
      content: '';
      height: 20px;
      left: 0;
      position: absolute;
      top: 0;
      width: 20px;
    }
  }

  &:checked {
    + label {
      &::after {
        background: $color-blue;
        border-radius: 2px;
        content: '';
        height: 12px;
        left: 4px;
        position: absolute;
        top: 4px;
        width: 12px;
      }
    }
  }
}

.login-page__form-control {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 40px;
}

.login-page__form-sign-up {
  color: $login-page-text-color;
  font-size: 16px;
  letter-spacing: .2px;
  line-height: 20px;
  margin: 10px 0 0;
  text-align: center;

  .login-page__link {
    margin: 0 0 0 5px;
  }
}
