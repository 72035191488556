.client-complexity-form {
  margin: 0 -40px 20px;

  .registration__radio-buttons {
    margin: 0 0 0 50px;

    @media #{$mobile} {
      margin: 0;
    }
  }
}

.client-complexity__question {
  align-items: center;
  border-bottom: 2px solid $color-border;
  display: flex;
  height: 70px;
  justify-content: space-between;
  padding: 0 40px;

  @media #{$mobile} {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 40px;
    min-height: 70px;
    height: auto;
  }

  &:last-child {
    border: 0;
  }
}

.client-complexity__question-text {
  color: $color-grey;
  font-size: 16px;
  line-height: 21px;

  @media #{$mobile} {
    margin: 0 0 20px 0;
  }
}

.registration__step--complexity {
  padding-bottom: 120px;
}
