.login-page {
  .vue-tooltip-visible {
    .tooltip-content {
      display: block;
      background-color: transparent;
      width: auto;
    }
  }
}


