
.breadcrumbs {
  font-size: 12px;
  font-weight: bold;
  left: $indent-left-right-side-page;
  letter-spacing: .3px;
  line-height: 1.3;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  text-align: left;
  top: 20px;
  z-index: 2;

  @media #{$mobile} {
    left: 20px;
    right: 20px;
    top: 20px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}

.breadcrumbs__item {
  display: inline;
  height: 20px;
  margin: 0 3px 0 0;
  padding: 0;

  &::before {
    color: $color-breadcrumbs;
    content: '/';
    padding-right: 3px;

    .breadcrumbs--white &,
    .breadcrumbs--blue & {
      color: $color-white;
    }
  }

  &:first-child {
    &::before {
      display: none;
    }
  }

  &:last-child {
    &::before {
      color: $color-orange;
    }

    .breadcrumbs__item-link {
      color: $color-blue;

      span {
        text-decoration: none;
      }

      .breadcrumbs--white & {
        color: $color-title;
      }

      .breadcrumbs--blue & {
        color: $color-blue;
      }
    }
  }
}

.breadcrumbs__item-link {
  color: $color-breadcrumbs;

  span {
    text-decoration: underline;
  }

  .breadcrumbs--white &,
  .breadcrumbs--blue & {
    color: $color-white;

    &:hover {
      color: $color-white;
    }
  }

  &:hover {
    color: $color-breadcrumbs;

    span {
      text-decoration: none;
    }
  }
}
