$guides-column-block-head-base-bg: $color-orange;
$guides-column-block-head-light-bg: #e45b0e;
$guides-column-block-head-dark-bg: #c7500c;

$guides-column-block-content-base-bg: $color-white;
$guides-column-block-content-light-bg: #e1e1e1;
$guides-column-block-content-dark-bg: #cecece;

// Blue color

$guides-column-block-blue-head-base-bg: $color-blue;
$guides-column-block-blue-head-light-bg: #2077d2;
$guides-column-block-blue-head-dark-bg: #1d6bbd;



.guides-column-block {
  align-items: stretch;
  display: flex;

  @media #{$tablet} {
    flex-direction: column;
  }
}

.guides-column-block__item {
  background: $guides-column-block-content-base-bg;
  width: 33.33%;

  @media #{$tablet} {
    width: 100%;
  }

  &.item-light {
    background: $guides-column-block-content-light-bg;

    .guides-column-block__item-head {
      background: $guides-column-block-head-light-bg;

      .guides-column-block--blue & {
        background: $guides-column-block-blue-head-light-bg;
      }
    }

    .guides-column-block__item-image {
      background: darken($guides-column-block-head-light-bg, 5%);

      .guides-column-block--blue & {
        background: darken($guides-column-block-blue-head-light-bg, 5%);
      }
    }
  }

  &.item-dark {
    background: $guides-column-block-content-dark-bg;

    .guides-column-block__item-head {
      background: $guides-column-block-head-dark-bg;

      .guides-column-block--blue & {
        background: $guides-column-block-blue-head-dark-bg;
      }
    }

    .guides-column-block__item-image {
      background: darken($guides-column-block-head-dark-bg, 5%);

      .guides-column-block--blue & {
        background: darken($guides-column-block-blue-head-dark-bg, 5%);
      }
    }
  }
}

.guides-column-block__item-head {
  align-items: center;
  background: $guides-column-block-head-base-bg;
  display: flex;
  justify-content: space-between;

  .guides-column-block--blue & {
    background: $guides-column-block-blue-head-base-bg;
  }
}

.guides-column-block__item-image {
  align-items: center;
  background: darken($color-orange, 5%);
  display: flex;
  height: 80px;
  justify-content: center;
  min-width: 80px;
  padding: 15px;
  width: 80px;

  .guides-column-block--blue & {
    background: darken($guides-column-block-blue-head-base-bg, 5%);
  }
}

.guides-column-block__item-title {
  align-items: center;
  color: $color-white;
  display: flex;
  font-size: 17px;
  font-weight: 700;
  justify-content: center;
  letter-spacing: .3px;
  line-height: 1.24;
  text-align: center;
  width: 100%;
}

.guides-column-block__item-content {
  color: $color-grey;
  font-size: 16px;
  letter-spacing: .3px;
  line-height: 1.31;
  padding: 25px 70px;
  text-align: justify;

  @media #{$mobile} {
    padding: 20px;
  }

  ul {
    font-size: 16px;
  }
}
