$still-not-convinced__text-color: #656b6f;

.still-not-convinced {
  padding: 55px 0;

  @media #{$mobile} {
    padding: 30px 20px;
  }

  .container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    max-width: 850px;

    @media #{$mobile} {
      flex-direction: column-reverse;
      text-align: center;
      padding: 0;
    }
  }

  .static-title {
    margin-bottom: 25px;
  }

  &__column {
    width: 50%;

    @media #{$mobile} {
      width: 100%;
    }

    &:first-child {
      padding: 0 20px 0 0;

      @media #{$mobile} {
        padding: 0;
      }
    }
  }

  &__text {
    color: $still-not-convinced__text-color;
    line-height: 22px;
    margin-bottom: 35px;
  }

  &__image {
    float: right;
  }
}
