.guides-column-content {
  align-items: stretch;
  display: flex;

  @media #{$tablet} {
    flex-direction: column;
  }

  .guides-text__title {
    margin: 0;
    padding: 0 0 20px;
  }
}

.guides-column-content__item {
  width: 50%;

  @media #{$tablet} {
    width: 100%;
  }

  @media #{$mobile} {
    img {
      width: 100%;
    }
  }
}

.guides-column-content__item--light {
  background: $color-lightest-grey;
  color: $color-grey;
}

.guides-column-content__item--blue {
  background: $color-blue;
  color: $color-white;
}

.guides-column-content__item--dark {
  background: $color-title;
  color: $color-white;

  ul {
    color: $color-white;

    li {
      &::before {
        background: $color-white;
      }
    }

    ul {
      li {
        &::before {
          color: $color-white;
        }
      }
    }
  }

  .guides-text__title {
    color: $color-white;
  }
}

.guides-column-content__item--text {
  padding: 55px 60px 55px 135px;

  @media #{$tablet} {
    padding: 20px 20px 40px;
  }

  @media #{$mobile} {
    padding: 10px 20px;
  }
}

.guides-column-content__item--photo {
  font-style: italic;
  padding: 60px 60px 0;
  text-align: center;

  @media #{$mobile} {
    padding: 20px;
  }

  p {
    line-height: 1.31;
    margin: 0 0 30px;
  }
}

.guides-column-content__photo {
  background: $color-white;
  padding: 20px;
}

.guides-column-content__item--flex-container {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-bottom: 60px;

  @media #{$mobile} {
    padding-bottom: 0;
  }
}
