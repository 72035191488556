.pricing-page--b {
  .pricing__services {
    flex-direction: column;
    padding-top: 4px;
  }

  .pricing__services-item {
    width: 100%;
    padding: 30px;
  }

  .pricing__services-item-name {
    color: #11467D;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }

  .pricing__services-item-subname {
    color: #11467D;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 12px;
  }
}
