$country-guide-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .2);
$country-guide__nav-item-color:  #81868a;
$country-guide-list-border-color: #D4D4D4;

.country-guide-container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 15px;
}

.country-guide {
  &__nav {
    display: flex;
    justify-content: flex-start;

    @media #{$mobile} {
      overflow: hidden;
    }
  }

  &__nav-item {
    align-items: center;
    color: $country-guide__nav-item-color;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 700;
    justify-content: center;
    letter-spacing: .4px;
    margin: 0 20px;
    padding: 10px 20px;
    position: relative;
    text-transform: uppercase;

    .country-guide__nav-icon {
      display: none;

      @media #{$mobile} {
        display: block;
      }
    }

    &.active {
      background: $color-white;
      border-color: $color-white;
      color: $color-orange;
      z-index: 1;

      @media #{$mobile} {
        .country-guide__nav-icon {
          display: none;
        }
      }

      &::before {
        background: url('@/static/images/partials/country-guide/4.svg') no-repeat 0 0;
      }

      &::after {
        background: url('@/static/images/partials/country-guide/3.svg') no-repeat 0 0;
      }
    }

    @media #{$mobile} {
      align-items: center;
      background: url('@/static/images/pages/guides/icon-nav.svg') no-repeat 50% 50%;
      color: $color-orange;
      display: flex;
      font-size: 19px;
      font-weight: 700;
      height: 63px;
      justify-content: center;
      letter-spacing: .4px;
      line-height: 1.16;
      margin: 0 -20px;
      padding-bottom: 3px;
      position: relative;
      text-align: center;
      text-transform: uppercase;
      width: 100px;
      border: 0;

      &::before,
      &::after {
        display: none;
      }

      &.item-big-text {
        font-size: 14px;
        letter-spacing: .3px;
        line-height: 1;
      }

      .country-guide__nav-text {
        display: none;
      }

      &.active {
        background: url('@/static/images/pages/guides/icon-nav-active.svg') no-repeat 50% 50%;
        pointer-events: none;
        width: 173px;
        z-index: 1;

        .country-guide__nav-text {
          display: block;
        }

        .country-guide__nav-icon {
          display: none;
        }
      }
    }
  }

  &__content {
    position: relative;
    box-shadow: 3px 8px 17px rgba(9, 19, 35, .24);
    z-index: 5;
  }

  &__content-item {
    background: $color-white;
    border-radius: 4px;
    padding: 30px 10px;
    width: 100%;
  }

  &-list {
    column-count: 4;
    column-gap: 20px;
    column-rule: 1px solid $country-guide-list-border-color;
    margin: 0;
    padding: 0;

    @media #{$tablet} {
      column-count: 2;
    }

    @media #{$mobile} {
      column-count: 1;
    }

    &__item {
      list-style: none;

      @media #{$mobile} {
        &:nth-child(2n + 1) .country-guide-list__link {
          background: $color-lightest-grey url('@/static/images/icons/default/icon-arrow-country-guide-item.svg') no-repeat calc(100% - 20px) 50%;
        }
      }
    }

    &__link {
      align-items: center;
      background: url('@/static/images/icons/default/icon-arrow-country-guide-item.svg') no-repeat calc(100% - 20px) 50%;
      color: $color-grey;
      display: flex;
      font-size: 16px;
      justify-content: flex-start;
      line-height: 30px;
      padding: 0 20px;
      text-transform: capitalize;

      &:hover {
        background: url('@/static/images/icons/default/icon-arrow-country-guide-item-active.svg') no-repeat calc(100% - 20px) 50% $color-blue;
        color: $color-white;
      }
    }
  }
}

.country-guide-map {
  height: 432px;
  margin: 0 auto 80px;
  position: relative;
  width: 1246px;

  @media #{$lg-md} {
    left: 50%;
    margin: 0 0 20px -623px;
    transform: scale(.7);
  }

  @media #{$mobile} {
    display: none;
  }
}

// Map

.country-guide-tooltip {
  align-items: center;
  background-color: $color-white;
  background-image: url('@/static/images/icons/default/icon-arrow-country-guide-item.svg');
  background-position: calc(100% - 20px) 50%;
  background-repeat: no-repeat;
  border-radius: 4px;
  box-shadow: $country-guide-box-shadow;
  color: $color-grey;
  display: none;
  font-size: 16px;
  height: 50px;
  justify-content: flex-start;
  left: 50%;
  letter-spacing: .3px;
  margin: 1px -50% 0 -75px;
  padding: 0 40px 0 20px;
  position: absolute;
  text-align: left;
  text-transform: capitalize;
  top: 100%;
  width: 150px;
  z-index: 3;

  &::before,
  &::after {
    border-style: solid;
    border-width: 0 9px 9px 9px;
    content: '';
    height: 0;
    left: 50%;
    margin-left: -9px;
    position: absolute;
    width: 0;
  }

  &::before {
    border-color: transparent transparent $white;
    top: -9px;
    z-index: 1;
  }

  &::after {
    border-color: transparent transparent $color-white;
    top: -6px;
    z-index: 2;
  }
}

.country-guide-link {
  padding-bottom: 10px;
  position: absolute;

  &:hover {
    .country-guide-link__icon {
      background: darken($color-blue, 10%);
    }

    .country-guide-tooltip {
      display: flex;
    }
  }
}

.country-guide-link__icon {
  background: $color-blue;
  border: 2px solid $color-white;
  border-radius: 50%;
  box-shadow: $country-guide-box-shadow;
  display: block;
  height: 16px;
  width: 16px;
}

// Map Countries

.country-guide-link--ukraine {
  left: 695px;
  top: 110px;
}

.country-guide-link--austria {
  left: 645px;
  top: 100px;
}

.country-guide-link--switzerland {
  left: 620px;
  top: 100px;
}

.country-guide-link--indonesia {
  left: 970px;
  top: 255px;
}

.country-guide-link--malaysia {
  left: 945px;
  top: 245px;
}

.country-guide-link--iceland {
  left: 530px;
  top: 50px;
}

.country-guide-link--kazakhstan {
  left: 815px;
  top: 105px;
}

.country-guide-link--saudi-arabia {
  left: 745px;
  top: 170px;
}

.country-guide-link--nepal {
  left: 870px;
  top: 140px;
}

.country-guide-link--bulgaria {
  left: 675px;
  top: 115px;
}

.country-guide-link--bangladesh {
  left: 890px;
  top: 160px;
}

.country-guide-link--dominican_republic {
  left: 375px;
  top: 200px;
}

.country-guide-link--chile {
  left: 380px;
  top: 340px;
}

.country-guide-link--turkey {
  left: 700px;
  top: 125px;
}

.country-guide-link--iraq {
  left: 740px;
  top: 140px;
}

.country-guide-link--brazil {
  left: 435px;
  top: 290px;
}

.country-guide-link--taiwan {
  left: 985px;
  top: 195px;
}

.country-guide-link--ecuador {
  left: 355px;
  top: 260px;
}

.country-guide-link--argentina {
  left: 405px;
  top: 360px;
}

.country-guide-link--kenya {
  left: 735px;
  top: 235px;
}

.country-guide-link--mexico {
  left: 265px;
  top: 200px;
}

.country-guide-link--belgium {
  left: 615px;
  top: 85px;
}

.country-guide-link--venezuela {
  left: 380px;
  top: 230px;
}

.country-guide-link--oman {
  left: 785px;
  top: 185px;
}

.country-guide-link--ghana {
  left: 590px;
  top: 220px;
}

.country-guide-link--norway {
  left: 625px;
  top: 50px;
}

.country-guide-link--senegal {
  left: 550px;
  top: 195px;
}

.country-guide-link--singapore {
  left: 930px;
  top: 260px;
}

.country-guide-link--india {
  left: 855px;
  top: 180px;
}

.country-guide-link--australia {
  left: 1030px;
  top: 330px;
}

.country-guide-link--kyrgyzstan {
  left: 825px;
  top: 120px;
}

.country-guide-link--kuwait {
  left: 755px;
  top: 155px;
}

.country-guide-link--cambodia {
  left: 945px;
  top: 215px;
}

.country-guide-link--sri-lanka {
  left: 865px;
  top: 215px;
}

.country-guide-link--tunisia {
  left: 630px;
  top: 140px;
}

.country-guide-link--china {
  left: 965px;
  top: 135px;
}

.country-guide-link--netherlands {
  left: 605px;
  top: 93px;
}

.country-guide-link--uk {
  left: 580px;
  top: 80px;
}

.country-guide-link--france {
  left: 595px;
  top: 105px;
}

.country-guide-link--qatar {
  left: 772px;
  top: 165px;
}

.country-guide-link--egypt {
  left: 700px;
  top: 160px;
}

.country-guide-link--algeria {
  left: 590px;
  top: 145px;
}

.country-guide-link--israel {
  left: 715px;
  top: 142px;
}

.country-guide-link--thailand {
  left: 935px;
  top: 200px;
}

.country-guide-link--greece {
  left: 685px;
  top: 130px;
}

.country-guide-link--hong-kong {
  left: 990px;
  top: 175px;
}

.country-guide-link--japan {
  left: 1085px;
  top: 150px;
}

.country-guide-link--philippines {
  left: 1020px;
  top: 220px;
}

.country-guide-link--costa-rica {
  left: 330px;
  top: 235px;
}

.country-guide-link--russia {
  left: 900px;
  top: 60px;
}

.country-guide-link--nigeria {
  left: 619px;
  top: 222px;
}

.country-guide-link--canada {
  left: 250px;
  top: 90px;
}

.country-guide-link--colombia {
  left: 360px;
  top: 245px;
}

.country-guide-link--pakistan {
  left: 825px;
  top: 155px;
}

.country-guide-link--germany {
  left: 633px;
  top: 84px;
}

.country-guide-link--bahamas {
  left: 340px;
  top: 200px;
}

.country-guide-link--czech {
  left: 647px;
  top: 88px;
}

.country-guide-link--uae {
  left: 787px;
  top: 172px;
}

.country-guide-link--poland {
  left: 660px;
  top: 84px;
}

.country-guide-link--tanzania {
  left: 720px;
  top: 270px;
}

.country-guide-link--italy {
  left: 640px;
  top: 118px;
}

.country-guide-link--korea {
  left: 1025px;
  top: 145px;
}

.country-guide-link--ireland {
  left: 570px;
  top: 70px;
}

.country-guide-link--morocco {
  left: 575px;
  top: 150px;
}

.country-guide-link--cameroon {
  left: 630px;
  top: 230px;
}

.country-guide-link--panama {
  left: 345px;
  top: 240px;
}

.country-guide-link--uganda {
  left: 720px;
  top: 230px;
}

.country-guide-link--spain {
  left: 586px;
  top: 120px;
}

.country-guide-link--vietnam {
  left: 955px;
  top: 202px;
}

.country-guide-link--azerbaijan {
  left: 725px;
  top: 120px;
}

.country-guide-link--south-africa {
  left: 677px;
  top: 333px;
}

.country-guide-link--romania {
  left: 680px;
  top: 106px;
}

.country-guide-link--peru {
  left: 366px;
  top: 293px;
}

.country-guide-link--portugal {
  left: 570px;
  top: 115px;
}

.country-guide-link--sweden {
  left: 640px;
  top: 55px;
}

.country-guide-link--afghanistan {
  left: 803px;
  top: 143px;
}

.country-guide-link--nz {
  left: 1140px;
  top: 400px;
}

.country-guide-link--denmark {
  left: 625px;
  top: 73px;
}

.country-guide-link--finland {
  left: 668px;
  top: 51px;
}
