.tfx-switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 20px;
  margin-right: 7px;
  cursor: pointer;
  user-select: none;

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #DEDEDE;
    transition: .4s;
    border: 1px solid #DEDEDE;
    border-radius: 34px;
    display: inline-block;
  }

  .slider::before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 1px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
    transition: .4s;
    border-radius: 50%;
    border: .5px solid rgba(0, 0, 0, .04);
    box-shadow: 0 2px 1px rgba(0, 0, 0, .1), 0 1px 1px rgba(0, 0, 0, .16), 0 2px 5px rgba(0, 0, 0, .15);
  }

  input[type="checkbox"] {
    visibility: hidden;
  }

  input:checked + .slider {
    background-color: #2686EA;
    border: 1px solid transparent;

    &::before {
      transform: translate(13px, -50%);
    }
  }

  &.reverse {
    .slider {
      background-color: #2686EA;
      border: 1px solid transparent;

      &::before {
        transform: translate(13px, -50%);
      }
    }

    input:checked + .slider {
      background-color: #DEDEDE;
      border: 1px solid #DEDEDE;

      &::before {
        transform: translateY(-50%);
      }
    }
  }
}
