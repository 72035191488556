.article-signature {
  align-items: stretch;
  display: flex;
  padding: 15px 60px;
  margin-bottom: 50px;
  width: 100%;
  background-color: $color-white;

  &__information {
    color: $color-white;
    font-weight: 700;
    line-height: 1;
    position: relative;
    text-align: center;
    width: 100%;

    &:before {
      content: '';
      position: absolute;
      top: 45px;
      left: 0;
      height: 1px;
      width: 100%;
      background-color: rgba(184, 184, 184, .6);
    }
  }

  &__photo {
    margin: 0 auto;
    position: relative;
    padding: 10px;
    background: $color-white url('@/static/images/IJ-zemelman.png') no-repeat 50% 50%;
    background-size: calc(100% - 20px);
    border-radius: 100%;
    overflow: hidden;
    width: 100px;
    height: 100px;
  }

  &__name {
    font-weight: bold;
    font-size: 19px;
    line-height: 25px;
    text-align: center;
    color: $color-title;
  }

  &__position {
    font-weight: normal;
    color: $color-grey;
  }
}