$styled-input-border-color: #e0e2ea;

.contact-us-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  @media #{$mobile} {
    justify-content: center;
  }

  .form-group__styled {
    margin: 0 15px 15px 0;
    width: calc(50% - 8px);

    @media #{$mobile} {
      width: 100%;
      margin-right: 0;
    }

    &:nth-child(even) {
      margin-right: 0;
    }

    textarea.input {
      min-height: 168px;
    }
    .input {
      height: 42px
    }

    &-with-error {
      .label {
        color: #404040;
      }
    }

    .v-select {
      height: 42px;
    }
  }

  .form-group__styled--textarea {
    margin: 0 0 20px;
    width: 100%;
  }

  &__success {
    align-items: flex-end;
    background-color: $color-orange;
    background-image: url('@/static/images/partials/contact-us/success.svg');
    background-position: left 70px bottom;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 4px;
    bottom: -2px;
    display: flex;
    justify-content: flex-end;
    left: -2px;
    padding: 40px;
    position: absolute;
    right: -2px;
    text-align: center;
    top: -2px;
    z-index: 10;
    min-height: 350px;

    @media #{$tablet} {
      background-position: right bottom;
      background-size: cover;
    }

    @media #{$mobile} {
      background-position: left bottom;
      background-size: cover;
    }

    .btn {
      width: 100%;
    }
  }

  &__success-content {
    width: 240px;

    @media #{$mobile} {
      width: 150px;
    }
  }

  &__success-text {
    font-size: 20px;
    font-weight: bold;
    color: $color-title;
    text-align: left;
    margin-bottom: 65px;
    line-height: 1.4;

    @media #{$mobile} {
      margin-bottom: 0;
    }
  }

  .btn {
    float: right;

    @media #{$mobile} {
      width: 100%;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    width: 100%;

    @media #{$mobile} {
      align-items: center;
      flex-direction: column;
    }
  }

  &__terms-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-right: 32px;
    color: #737373;

    @media #{$mobile} {
      margin: 0 0 16px;
    }
  }
}
