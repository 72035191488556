$how-we-work__inactive: #b8b8b8;
$how-we-work__active: #f96b1b;
$color-bg: #2588ed;
$color-white: #ffffff;

.how-we-work {
  .container {
    overflow: visible !important;
    max-width: 920px;
  }

  &__header {
    margin-top: -1px;
  }

  &_header {
    position: relative;

    &__inner {
      background: $color-bg;
      padding: 30px 0 00px;

      .static-title {
        padding: 30px 0;
        margin: 0;
        text-align: center;
        color: $color-white;
      }

      hr {
        width: 90%;
        height: 0;
        margin: 10px auto;
        border-top: 1px solid $color-white;
        opacity: .4;
      }
    }

    &__wave {
      margin-top: -2px;
      position: absolute;
      width: 100%;

      img {
        transform: rotate(180deg);
        width: 100%;
      }
    }
  }

  &__list-wrapper {
    border-radius: 4px;
    box-shadow: 9px 19px 35px 0 rgba(0, 0, 0, .1), 0 2px 4px 0 rgba(9, 19, 35, .14);
    background-color: $color-white;
    position: relative;
    margin-bottom: 35px;
  }

  &__list {
    align-items: flex-start;
    display: flex;
    padding: 60px 0;
    margin: 0 60px;
    position: relative;
    overflow: hidden;

    @media #{$tablet} {
      padding: 30px 0;
      margin: 0 20px;
    }

    @media #{$mobile} {
      flex-direction: column;
      padding: 50px 0;
    }

    &::after {
      background: $how-we-work__inactive;
      content: '';
      height: 1px;
      left: 0;
      position: absolute;
      right: 0;
      top: 120px;
      z-index: 1;

      @media #{$tablet} {
        top: 90px;
      }

      @media #{$mobile} {
        top: 30px;
        bottom: 0;
        left: 75px;
        width: 1px;
        height: calc(100% - 80px);
      }
    }
  }

  &__line {
    background: $how-we-work__inactive;
    height: 2px;
    left: calc(50% - 4px);
    position: absolute;
    top: 60px;
    width: 0;
    z-index: 2;
  }

  &__item {
    color: $how-we-work__inactive;
    list-style: none;
    position: relative;
    text-align: center;
    transition: all ease .5s;
    width: 20%;

    &.reverse-move  {
      .how-we-work__line {
        left: inherit;
        right: calc(-50% + 5px);
      }

      @media #{$mobile} {
        .how-we-work__item__wrapper-line {
          left: inherit;
          right: calc(-50% + 5px);
        }
      }
    }

    @media #{$mobile} {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 20px;
    }

    path {
      transition: all ease .5s;
    }

    &.active {
      color: $how-we-work__active;

      path {
        fill: $how-we-work__active;
      }

      &::after {
        background: $how-we-work__active;
      }

      .how-we-work__photo {
        border-color: $how-we-work__active;
      }

      .how-we-work__line {
        background: $how-we-work__active;
      }

      .how-we-work__description {
        display: block;
      }
    }

    &__wrapper-line {
      @media #{$mobile} {
        position: absolute;
        top: 55px;
        left: 40px;
        z-index: 3;
        width: 70px;
        height: 1px;
        transform: rotate(90deg);

        .how-we-work__line {
          display: block;
          position: unset;
          height: 1px;
        }
      }
    }
  }

  &__progress {
    background: $color-white;
    border: 1px solid $how-we-work__inactive;
    border-radius: 3.5px;
    content: '';
    height: 7px;
    left: 50%;
    margin-left: -14px;
    position: absolute;
    top: 57px;
    transition: all ease .5s;
    width: 28px;
    z-index: 3;

    @media #{$mobile} {
      transform: rotate(90deg);
      top: 15px;
      left: 75px;
    }

    .active & {
      border-color: $how-we-work__active;
    }
  }

  &__progress-status {
    background: $how-we-work__active;
    height: 5px;
    width: 0;

    //@media #{$mobile} {
    //  height: 100%;
    //}
  }

  &__text {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.19;
    letter-spacing: .6px;
    margin: 0;
    padding: 12px 0 0;

    @media #{$mobile} {
      padding: 0;
      text-align: left;

      br {
        display: none;
        text-align: left;
      }
    }
  }

  &__photo {
    align-items: center;
    display: flex;
    height: 50px;
    justify-content: center;
    margin: 0 auto 12px;
    width: 50px;
    border: solid 1px $how-we-work__inactive;
    background-color: $color-white;
    border-radius: 100%;
    transition: all .3s ease-in-out;

    svg,
    img {
      width: 22px;
    }

    @media #{$mobile} {
      width: 50px;
      min-width: 50px;
      height: 50px;
      margin: 0 50px 0 0;

      svg,
      img {
        width: 25px;
        height: 25px;
      }
    }
  }

  &--landing {
    position: relative;
    z-index: 11;

    .how-we-work_header__inner {
      background: $color-white;
    }

    .how-we-work_header__wave {
      display: none;
    }

    .static-title {
      color: $color-title;
    }

    hr {
      display: none;
    }
  }
}

.landing-body {
  .how-we-work {
    margin-top: -90px;
  }
}
