.articles-latest-news {
  margin: 0 0 100px;

  .page-title {
    margin-bottom: 35px;
  }
}

.articles-latest-news {
  &__list {
    list-style: none;
    margin: 0 auto;
    max-width: 970px;
    padding: 0;
  }

  &__list-item {
    align-items: center;
    background-color: $color-white;
    border: solid 1px $color-blue;
    border-left-width: 8px;
    border-radius: 4px;
    color: $color-blue;
    display: flex;
    font-size: 16px;
    justify-content: space-between;
    line-height: 1.3;
    margin: 0 0 20px;
    padding: 22px 25px;
    position: relative;
  }

  &__list-name {
    color: $color-blue;
    text-decoration: none;
    font-weight: bold;
    width: 100%;

    &:hover {
      color: $color-blue;
    }
  }

  &__article-date {
    border-left: 1px solid $color-blue;
    margin-left: 20px;
    padding-left: 20px;
    white-space: nowrap;
    font-size: 16px;
    line-height: 22px;
  }
}
