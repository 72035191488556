$color-bg-org-hsbc: #e71d1c;
$color-bg-org-transport-for-london: #004fa3;
$color-bg-org-kbr: #003c23;
$color-bg-org-microsoft: #00a4ef;
$color-bg-org-google: #fbbc05;
$color-bg-org-exxon: #ed1b2d;
$color-bg-org-int: #0055a8;
$color-bg-org-morgan-stanley: #34c381;
$color-bg-org-ibm: #005eb8;
$color-bg: #2588ed;

.block-organizations {
  position: relative;

  &_inner {
    background: $color-bg;
    display: grid;
    grid-template-columns: repeat(6, 170px);
    grid-template-rows: repeat(2, 170px);
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    justify-content: center;
    padding: 40px 60px;
    margin-top: -1px;

    @media #{$lg-md} {
      grid-template-columns: repeat(6, 140px);
      grid-template-rows: repeat(2, 140px);
      grid-column-gap: 20px;
      grid-row-gap: 20px;
    }

    @media #{$tablet} {
      grid-template-columns: repeat(3, 32%);
      grid-template-rows: repeat(3, 150px) 1fr;
      padding: 30px;
    }

    @media #{$mobile} {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(5, 130px) 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      padding: 40px 20px 20px;
    }
  }

  &_wave {
    position: relative;

    img {
      width: 100%;
      line-height: 0;
    }

    &:before {
      content: '';
      height: 1px;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      background: $color-bg;
    }
  }

  &__item {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 9px 19px 35px 0 rgba(0, 0, 0, .1), 0 2px 4px 0 rgba(9, 19, 35, .14);
    padding: 25px;
    background-color: white;
    border-radius: 4px;

    img {
      height: auto;
      max-height: 100%;
      max-width: 100%;
      width: 100%;
    }

    &__break {
      display: none;
    }
  }

  &__item-info {
    padding: 0 0 0 40px;
    background-color: transparent;
    box-shadow: none;
    grid-area: 2 / 4 / 3 / 7;

    @media #{$tablet} {
      grid-area: 4 / 1 / 5 / 4;
    }

    @media #{$mobile} {
      grid-area: 6 / 1 / 7 / 3;
      padding: 0;

      .btn {
        margin-top: 20px;
        width: 100%;
      }
    }
  }

  &__item-info-wrapper {
    align-items: center;
    display: flex;

    .btn {
      white-space: nowrap;
    }

    @media #{$mobile} {
      display: block;
    }
  }

  &__item-info-title {
    color: $color-white;
    font-size: 34px;
    font-weight: 700;
    line-height: 46px;
    margin: 0;
    text-align: left;
    padding: 10px 20px;

    @media #{$xl} {
      font-size: 26px;
      line-height: 30px;
    }
  }
}

.landing-body {
  .block-organizations {
    top: -90px;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .block-organizations {
    &_inner {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
    }

    &__item {
      flex: 1;
      margin: 20px;
      width: 100%;
      height: 170px;

      &__break {
        display: block;
        flex-basis: 100%;
        height: 0;
      }
    }

    &__item-info {
      flex: 4;
      margin: 20px 50px;
    }
  }
}
