.modal {
  &-container {
    margin: 0 auto;
    min-height: 600px;
    position: relative;
    transform: translate(0, -50%);
    transition: transform 0.3s ease-out;
    width: auto;

    > div:not(:last-child) {
      display: none;
    }

    .overflow-hidden & {
      transform: translate(0, 0);
    }

    &-hide {
      display: none;
      opacity: 0;
      z-index: -9;
    }
  }

  &-window {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 996px;
    height: 0;
    background: $color-white;
    border-radius: 4px;
    display: none;
    flex-flow: column;
    padding: 0 30px 30px;
    margin: 100px auto;

    @media #{$mobile} {
      width: calc(100% - 40px);
      padding: 0 20px 20px;
    }
  }

  &-show-window {
    height: auto;
    opacity: 1;
    z-index: 100;

    &.modal-window {
      top: 0;
      transform: translate(-50%, 0%);
      opacity: 1;
      visibility: visible;
    }
  }

  &-displayed {
    display: flex;
  }

  // Positioning

  &-position-top {
    top: 80px;
    margin-bottom: 80px;
  }

  &-header {
    border-bottom: 1px solid $color-border;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 0 0 20px;
    padding: 15px 25px 15px 0;
    position: relative;
  }

  &-heading {
    color: $color-title;
    font-size: 18px;
    font-weight: bold;
    margin: 0 15px 0 0;

    @media #{$tablet} {
      margin: 0;
    }
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15.5px;
    text-align: center;

    [class^="button"] {
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-close,
  .close {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    right: 0;
    margin: -10px 0 0;
    cursor: pointer;

    &:before,
    &:after {
      position: absolute;
      content: "";
      top: 0;
      left: 12px;
      width: 1px;
      height: 20px;
      background: $color-light-grey;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }

    &:hover {
      &:before,
      &:after {
        background: $color-black;
      }
    }
  }
}

.global-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0;

  &-show {
    opacity: 1;
    z-index: 1042;
    transition: opacity 0.15s linear;
  }

  &.global-overlay-show {
    opacity: 0.7;
    z-index: 1043;
  }
}

.container-for-modal {
  bottom: 0;
  display: none;
  left: 0;
  outline: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1050;

  .overflow-hidden & {
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

// Free online call
.modal-free-online-call {
  .modal-dialog {
    margin: 108px auto 0;
    max-width: 420px;
    min-height: 250px;
    border-radius: 4px;
    box-shadow: 0 4px 25px 0 rgba(0, 17, 34, 0.23);
    background-color: $color-white;
    display: block;
    padding: 14px 30px 30px;

    @media #{$sm} {
      max-width: 100%;
      width: 100%;
      margin-left: 15px;
      margin-right: 15px;
    }

    .modal-content {
      background: transparent;
      border-radius: 0;
    }
  }
}
