$dropdown-menu-box-shadow: rgba(0, 0, 0, .2);

.dropdown-menu:not(.faq__content) {
  background: $color-white;
  border: 0;
  border-radius: 4px;
  border-top: 2px solid $color-orange;
  box-shadow: 2.5px 4px 20px 0 $dropdown-menu-box-shadow;
  margin: 0;
  padding: 25px 0;
  z-index: 9;

  &::before,
  &::after {
    border-style: solid;
    content: '';
    height: 0;
    left: 50%;
    margin-left: -9px;
    position: absolute;
    width: 0;
  }

  &::after {
    border-color: transparent transparent $color-orange;
    border-width: 0 9px 9px;
    top: -9px;
    z-index: 1;
  }

  &::before {
    border-color: transparent transparent $color-white;
    border-width: 0 9px 9px;
    top: -6px;
    z-index: 2;
  }
}

.dropdown-item {
  color: $color-grey;
  font-size: 14px;
  letter-spacing: .3px;
  line-height: 24px;
  min-height: 32px;
  padding: 4px 40px;

  &.active {
    background: $color-blue;

    &:hover {
      background: $color-blue;
      color: $color-white;
    }
  }

  &:hover {
    background: $color-border;
    color: $color-blue;
  }
}
