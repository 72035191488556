@import "~dropzone/dist/dropzone";

.report-form {
  .dropzone {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    height: 141px;
    padding: 22px 0;
    margin-bottom: 20px;
    border-radius: 4px;
    border: 1px dashed #3c4858;
    background-color: #f4faff;
    cursor: pointer;

    &:after {
      display: none;
    }

    @media #{$mobile} {
      align-items: flex-start;
    }

    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: 30px;
      transform: translateX(-50%);
      width: 54px;
      height: 53px;
      display: inline-block;
      background: url('~tfx-common/components/documents/icons/dragandrop.svg') no-repeat center / cover;

      @media #{$mobile} {
        top: 15px;
        background: url('~tfx-common/components/documents/icons/upload-icon-mobile.svg') no-repeat center / contain;
        width: 50px;
        height: 35px;
      }
    }

    .dz-message {
      width: 100%;
      text-align: center;
      color: #43536e;
    }

    .dz-preview,
    .dz-file-preview {
      width: calc(25% - 30px);
      margin: 0 15px;
      height: 100%;
      border-radius: 4px;
      border: 3px solid #fff;
      box-shadow: 0 2px 5px #9e9e9e;

      @media #{$mobile} {
        width: calc(25% - 12px);
        margin: 0 6px;
        min-height: auto;
      }

      .dz-image {
        height: 100%;
        width: 100%;
        border-radius: 4px !important;
        position: relative;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          cursor: zoom-in;
          transition: transform .3s;
        }
      }

      .dz-remove {
        font-size: 13px;
        margin-top: 6px;
        padding-top: 2px;
        text-decoration: none;
      }

      .dz-details {
        display: none;
        opacity: 0;
      }

      .dz-progress {
        display: none;
      }

      &.video {
        .dz-image {
          &:after {
            position: absolute;
            bottom: 35%;
            left: 0;
            width: 100%;
            content: "▶";
            color: #3c4858;
            font-size: 30px;
            text-align: center;
          }

          img {
            cursor: default;
          }
        }
      }

      &:hover {
        .dz-image {
          img {
            filter: none;
            transform: none;
          }
        }
      }

      &.zoomed {
        .dz-image {
          overflow: visible;
          img {
            cursor: zoom-out;
            z-index: 9999;
            transform: scale(3);
            background: #f5f5f5;
            padding: 2px;
            border: 1px solid #e3e3e3;
            object-fit: contain;
          }
        }
        .dz-remove {
          display: none;
        }
      }
    }

    &.dz-started {
      height: 170px;
      padding: 22px 0 50px;

      @media #{$mobile} {
        height: 140px;
      }

      &:before {
        display: none;
      }

      .dz-message {
        display: block;
        position: absolute;
        bottom: 10px;
        padding: 0;
        margin: 0;
        font-size: 14px;
        font-style: italic;
        opacity: 0.8;
      }
    }

    .dz-remove {
      position: absolute;
      width: 20px;
      height: 20px;
      color: #3c4858;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      top: -18px;
      right: -10px;
      background: #fff;
      box-shadow: 0 2px 5px #9e9e9e;
      z-index: 99;
      font-weight: bold;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
