$button-disabled-bg-color: #b7babe;
$btn-padding-y: .375rem !default;
$btn-padding-x: .75rem !default;
$btn-border-radius: 4px;

.btn {
  display: inline-block;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  @include button-size($btn-padding-y, $btn-padding-x, $font-size-default, $btn-border-radius);
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  border-radius: 4px;

  &:focus {
    outline: 0;
    box-shadow: none !important;
  }

  &:active {
    box-shadow: none !important;
  }

  &:hover {
    text-decoration: none;
  }
}

.btn-lg {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: .5px;
  line-height: 40px;
  min-height: 58px;
  padding-left: 30px;
  padding-right: 30px;
}

.btn-md {
  font-size: 14px;
  letter-spacing: .2px;
  line-height: 1.5;
  min-height: 46px;
  padding: .7rem 30px .65rem;
}

.btn-240-width {
  font-size: 14px;
  letter-spacing: .2px;
  line-height: 1.6;
  min-height: 46px;
  padding: 10px;
  max-width: 240px;
  width: 100%;

  &:hover,
  &:focus {
    color: white;
    text-decoration: none;
  }

  @media #{$mobile} {
    max-width: 100%;
  }
}

.btn-link {
  color: $color-blue;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: normal;

  &:hover {
    text-decoration: none;
  }

  &:active,
  &:focus,
  &:visited {
    text-decoration: none;
  }
}

.btn--width-small {
  min-width: 220px;
}

// Buttons styles

.btn-primary {
  background: $bg-button-primary;
  color: $color-white;
}

.btn-white {
  background: $color-white;
  color: $color-blue;

  &:hover,
  &:focus {
    background: transparent;
    border-color: $border-button-white;
    color: $color-white;
  }
}

.btn-white-orange {
  background: $color-white;
  color: $color-orange;

  &:hover {
    background: transparent;
    border-color: white;
    color: $color-white;
  }
}

.btn-orange {
  background: $color-orange;
  border-color: $color-orange;
  color: $color-white;

  &:hover {
    background: darken($color-orange, 10%);
    border-color: darken($color-orange, 10%);
    color: $color-white;
  }

  &:disabled {
    background: $button-disabled-bg-color;
    border-color: $button-disabled-bg-color;
    cursor: default;

    &:hover {
      background: $button-disabled-bg-color;
      border-color: $button-disabled-bg-color;
      color: $color-white;
    }
  }
}

.btn-blue {
  background: $color-blue;
  border-color: $color-blue;
  color: $color-white;

  &:hover {
    background: darken($color-blue, 10%);
    border-color: darken($color-blue, 10%);
    color: $color-white;
  }
}

.btn-outline-primary {
  background-color: transparent;
  background-image: none;
  border-color: $bg-button-primary;
  color: $bg-button-primary;

  &:hover {
    background-color: $bg-button-primary;
  }
}

.btn-outline-white {
  background-color: transparent;
  background-image: none;
  border-color: $color-white;
  color: $color-white;

  &:hover {
    background-color: $color-white;
    border-color: $color-white;
    color: $color-blue;
  }
}

.btn-outline-blue {
  background-color: transparent;
  background-image: none;
  border-color: $color-blue;
  color: $color-blue;

  &:hover {
    background-color: $color-blue;
    border-color: $color-blue;
    color: $color-white;
  }
}

.btn-outline-orange {
  background-color: transparent;
  background-image: none;
  border-color: $color-orange;
  color: $color-orange;

  &:hover {
    background-color: $color-orange;
    color: $color-white;
  }
}

// Contact Main Buttons

@mixin btn-with-icon($color, $blackout-percentage) {
  background: $color;
  border: 1px solid $color;
  border-radius: 4px;
  color: $color-white;
  display: flex;
  padding: 0;
  transition: none;
  width: 100%;
  height: 42px;

  .btn-with-icon-item {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 100%;
  }

  .btn-with-icon-image {
    width: 42px;
  }

  .btn-with-icon-content {
    background: $color;
    width: calc(100% - 44px);

    @media #{$mobile} {
      padding-right: 36px;
    }
  }

  path {
    fill: $color-white;
  }

  &:hover {
    border-color: darken($color, $blackout-percentage);
    color: $color-white;

    .btn-with-icon-content,
    .btn-with-icon-image {
      background: darken($color, $blackout-percentage);
    }
  }
}

.btn-free-online-call {
  @include btn-with-icon($color-white, 10%);
  color: $color-title;

  .btn-with-icon-image {
    background: $color-white;

    svg {
      path {
        fill: $color-title;
      }
    }
  }

  &:hover {
    color: $color-title;
  }
}

.btn-call-free-online {
  @include btn-with-icon($color-blue-bg, 5%);
}

.btn-whatsapp {
  @include btn-with-icon($bg-btn-dark-green, 5%);
}

.btn-online-chat {
  @include btn-with-icon($bg-dtn-online-chat, 5%);
  color: white;

  .btn-with-icon-content {
    padding-right: 36px;
    color: white;

    &:hover,
    &:focus {
      color: white;
    }
  }
}

.btn-watch-recordered {
  background-image: url('@/static/styles/images/icons/default/icon-play-button.svg');
  background-position: left 12px center;
  background-repeat: no-repeat;
  padding-left: 42px;
  font-weight: normal;

  &:hover,
  &:focus {
    background-image: url('@/static/styles/images/icons/default/icon-play-button-white.svg');
  }
}


.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  padding: 8px 12px;
  min-width: 93px;
  min-height: 42px;
  overflow: hidden;
  outline: none;
  text-align: center;
  text-overflow: ellipsis;
  text-decoration: none;
  white-space: nowrap;
  user-select: none;
  width: 100%;
  color: #fff;
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  border: 0;
  margin-bottom: 12px;
  transition: all .3s ease;
  font-weight: normal;

  &:focus,
  &:hover {
    opacity: 0.8;
  }

  &__icon {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }

  &__email {
    border: 1px solid #F9630E;
    background: #F9630E;
    color: white;
    font-weight: bold;
    margin-bottom: 0;

    .button__icon {
      background: url('@/static/images/icon-email.svg') no-repeat center;
    }

    &:hover {
      color: white;
      background-color: #f45c06;
    }
  }

  &__whatsapp {
    border: 1px solid #08B33B;
    color: #08B33B;

    .button__icon {
      background: url('@/static/images/icon-whatsapp.svg') no-repeat center;
    }

    &:hover {
      color: inherit;
      background-color: rgba(8,179,100,0.1);
    }
  }

  &__google {
    border: 1px solid #2586EA;
    color: #2586EA;

    .button__icon {
      background: url('@/static/images/icon-google.svg') no-repeat center;
    }

    &:hover {
      color: inherit;
      background-color: rgba(66,133,244,0.1);
    }
  }

  &__apple {
    border: 1px solid #303030;
    color: #303030;

    .button__icon {
      background: url('@/static/images/icon-apple.svg') no-repeat center;
    }

    &:hover {
      color: inherit;
      background-color: rgba(48,48,48,0.1);
    }
  }

  &.button-orange {
    background: #f8620e;
    color: #fff;
  }

  &.button-blue-outline {
    background: #fff;
    color: $color-blue;
    border: 1px solid $color-blue;
  }
}
