.on-boarding-team {
  background: $section-bg;
  padding: 120px 0 80px;
  text-align: center;
  position: relative;

  @media #{$min-width-xl} {
    padding: 0 0 140px;
  }

  @media #{$mobile} {
    padding: 50px 0;
  }

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
    height: 96px;
    top: 0;
    background-color: white;
    background-image: url('@/static/images/pages/index/mobile-app/blue-wave-v2.svg');
    background-position: right top;

    @media #{$min-width-xl} {
      height: 105px;
    }

    @media #{$tablet} {
      height: 82px;
    }

    @media #{$mobile} {
      display: none;
    }
  }

  &__title {
    color: $color-title;
    font-size: 60px;
    font-weight: 700;
    margin: 0 0 50px;
    text-align: center;

    @media #{$mobile} {
      font-size: 34px;
      margin: 0 0 25px;
      padding: 0 20px;
    }
  }

  &__list {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 50px;
    margin: 10px auto 20px;

    @media #{$mobile} {
      padding: 0;
    }
  }

  &__item{
    position: relative;
    z-index: 11;
    margin: 0 34px 32px;

    @media #{$mobile} {
      width: 50%;
      display: flex;
      justify-content: flex-start;
      margin: 0 0 30px;
      flex-direction: column;
      text-align: center;
      align-items: center;
      padding: 0 20px;
    }
  }

  &__item-photo {
    margin: 0 0 15px;
  }

  &__item-name {
    color: $color-blue;
    font-weight: bold;
    font-size: 19px;
    line-height: 24px;
    background: white;
    border-radius: 4px;
    padding: 3px 15px;
    box-shadow: 3px 8px 17px rgba(9, 19, 35, .24);
    position: relative;
    max-width: 100px;

    &:before {
      position: absolute;
      left: 0;
      right: 0;
      top: -8px;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8px 8px 8px;
      border-color: transparent transparent white transparent;
      margin: 0 auto;
    }
  }
}

.landing-body {
  .on-boarding-team {
    padding-top: 0;
  }
}
