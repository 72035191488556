.privacy-policy {
  color: $color-grey;
  font-size: 17px;
  line-height: 1.31;
  padding: 60px 0;
  text-align: justify;

  .static-title {
    margin: 0 0 25px;
    text-align: center;
  }

  .container {
    max-width: 890px;
  }

  p {
    margin: 0 0 25px;
  }

  a {
    font-weight: 700;

    &:hover {
      color: $color-link;
      text-decoration: underline;
    }
  }
}

.privacy-policy__date-updated {
  color: $color-title;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
}

.privacy-policy__title {
  color: $color-title;
  font-size: 60px;
  font-weight: 700;
  margin: 0 0 40px;
  text-align: center;
}

.privacy-policy__sub-title {
  color: $color-title;
  font-size: 34px;
  font-weight: 700;
  line-height: 40px;
  margin: 35px 0 20px;
  text-align: center;
}
