.advantages {
  background: $color-lightest-grey;
  color: $color-grey;
  font-size: 16px;
  line-height: 21px;
  padding: 70px 0 80px;
  text-align: center;

  .static-title {
    margin: 0;
    padding: 0 0 55px;
  }

  .btn {
    margin: 0 10px;
    min-width: 400px;

    @media #{$tablet} {
      margin: 0 10px 20px;
    }
  }

  &__item {
    color: $color-grey;
    float: left;
    padding: 0 0 40px;
    width: 33.33%;

    @media #{$tablet} {
      padding: 0 15px 40px;
    }

    @media #{$mobile} {
      width: 100%;
    }
  }

  &__item-photo {
    padding-bottom: 20px;
  }

  &__item-title {
    color: $color-main;
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    margin: 0;
    padding: 0 0 30px;
  }

  &__item-text {
    margin: 0 auto;
    max-height: 65px;
    max-width: 360px;
    overflow: hidden;
    padding: 0;

    @media #{$tablet} {
      text-align: center;
    }
  }
}

.advantages-landing {
  margin: 70px 0;

  @media #{$mobile} {
    margin: 50px 0;
  }

  &__inner {
    display: flex;
    align-items: baseline;
    justify-content: center;
    margin: 20px 0;
    position: relative;

    @media #{$mobile} {
      flex-direction: column;
      align-items: flex-start;
    }

    &:after {
      content: '';
      position: absolute;
      top: calc(100% + 50px);
      left: 50%;
      transform: translate(-50%, 0);
      height: 1px;
      width: 100%;
      max-width: 860px;
      background: #A6C5E6;
    }

    &--without-line {
      &:after {
        display: none;
      }
    }
  }

  &__item {
    display: flex;
    margin: 15px 20px;
    align-items: flex-start;
  }

  &__pic {
    width: 84px;
    min-width: 84px;
    height: 82px;
    background-image: url('@/static/images/pages/services/bg.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    font-weight: bold;
    font-size: 19px;
    line-height: 24px;
    color: $color-blue;
    max-width: 200px;
  }

  &__text {
    font-size: 16px;
    line-height: 23px;
    color: $color-blue;
    min-width: 160px;
  }
}
