.our-process-page {
  &__heading {
    position: relative;
    min-height: 322px;
    padding: 80px 0 280px;
    text-align: center;
    background-color: $color-blue;

    .static-title {
      font-size: 60px;
      color: white;

      @media #{$mobile} {
        font-size: 34px;
      }
    }
  }
}

// Stages
.our-process {
  position: relative;
  margin-top: -250px;

  .container {
    max-width: 980px;
  }

  &__wave {
    position: absolute;
    top: 250px;
    width: 100%;

    img {
      width: 100%;
    }
  }

  &__list-wrapper {
    border-radius: 4px;
    box-shadow: 9px 19px 35px 0 rgba(0, 0, 0, .1), 0 2px 4px 0 rgba(9, 19, 35, .14);
    background-color: $color-white;
    position: relative;
    margin-bottom: 60px;
    overflow: hidden;
  }

  &__list {
    align-items: flex-start;
    display: flex;
    padding: 60px 0 0;
    margin: 0 60px;
    position: relative;
    overflow: hidden;

    @media #{$tablet} {
      padding: 30px 0;
      margin: 0 20px;
    }

    @media #{$mobile} {
      flex-direction: column;
      padding: 20px 0 0;
    }

    &::after {
      background: $color-blue-dark;
      content: '';
      height: 1px;
      left: 0;
      position: absolute;
      right: 0;
      top: 120px;
      z-index: 1;

      @media #{$tablet} {
        top: 90px;
      }

      @media #{$mobile} {
        top: 48px;
        bottom: 0;
        left: 75px;
        width: 1px;
        height: calc(100% - 80px);
      }
    }
  }

  &__line {
    background: $color-blue-dark;
    height: 2px;
    left: calc(50% - 4px);
    position: absolute;
    top: 60px;
    width: 0;
    z-index: 2;
  }

  &__item {
    color: $color-blue-dark;
    list-style: none;
    position: relative;
    text-align: center;
    transition: all ease .5s;
    padding-bottom: 25px;
    width: 20%;

    @media #{$mobile} {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 10px;
      padding-bottom: 15px;
    }

    path {
      transition: all ease .5s;
    }

    &.active {
      color: $color-orange;

      path {
        fill: $color-orange;
      }

      &::after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        border-width: 0 8px 8px 8px;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: transparent transparent $color-orange transparent;

        @media #{$mobile} {
          display: none;
        }
      }

      .our-process__photo {
        border-color: $color-orange;
      }

      .our-process__line,
      .our-process__progress {
        background: $color-orange;
      }

      .our-process__description {
        display: block;
      }
    }
  }

  &__wrapper-line {
    @media #{$mobile} {
      position: absolute;
      top: 55px;
      left: 40px;
      z-index: 3;
      width: 70px;
      height: 1px;
      transform: rotate(90deg);

      .how-we-work__line {
        display: block;
        position: unset;
        height: 1px;
      }
    }
  }

  &__text {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.19;
    letter-spacing: .6px;
    margin: 0;
    padding: 12px 0 0;

    @media #{$mobile} {
      padding: 0;
      text-align: left;

      br {
        display: none;
        text-align: left;
      }
    }
  }

  &__photo {
    align-items: center;
    display: flex;
    height: 50px;
    justify-content: center;
    margin: 0 auto 12px;
    width: 50px;
    border: solid 1px $color-blue-dark;
    background-color: $color-white;
    border-radius: 100%;
    transition: all .3s ease-in-out;

    svg,
    img {
      width: 22px;
    }

    @media #{$mobile} {
      width: 50px;
      min-width: 50px;
      height: 50px;
      margin: 0 50px 0 0;

      svg,
      img {
        width: 25px;
        height: 25px;
      }
    }
  }

  &__progress {
    background: $color-white;
    border: 1px solid $color-blue-dark;
    border-radius: 3.5px;
    content: '';
    height: 7px;
    left: 50%;
    margin-left: -14px;
    position: absolute;
    top: 57px;
    transition: all ease .5s;
    width: 28px;
    z-index: 3;

    @media #{$mobile} {
      transform: rotate(90deg);
      top: 15px;
      left: 75px;
    }

    .active & {
      border-color: $color-orange;
    }

    &--status {
      background: $color-orange;
      height: 5px;
      width: 0;
    }
  }

  &__info {
    margin: 0 60px 60px;
    padding: 22px 30px;
    display: flex;
    align-items: center;
    background-color: $color-sky-blue;
    border-radius: 6px;
    border: 2px dashed $color-blue-dark;
    border-top: 1px solid $color-orange;
    justify-content: space-between;

    &.hide {
      display: none;
    }

    > p {
      font-size: 16px;
      line-height: 22px;
      color: $color-grey;
      margin: 0;

      strong {
        color: $color-blue;
      }
    }

    @media #{$mobile} {
      flex-direction: column-reverse;
      margin: 0 20px 20px;
    }

    .btn-md {
      text-transform: none;
    }
  }

  &__info-img {
    min-width: 200px;
    width: 200px;
    margin-left: 30px;

    @media #{$mobile} {
      margin-left: 0;
      margin-bottom: 20px;
    }

    > img,
    > svg {
      width: 100%;
    }
  }

  &__faq {
    padding-bottom: 20px;
    border-bottom: 1px solid $color-sky-blue;
    margin-bottom: 100px;
  }
}
