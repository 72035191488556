$contact-main__information__wrapper-btn-border: rgba(168, 172, 185, .3);
$contact-main__information__form-box-shadow: 3px 2px 9px 0 rgba(0, 0, 0, .4), 3px 19px 35px 0 rgba(0, 0, 0, .2);
$contact-main__information-border: rgba(255, 255, 255, .2);

.contact-block {
  background-color: $color-title;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 16px;
  line-height: 21px;
  padding: 70px 0 10px;
  position: relative;

  @media #{$tablet} {
    padding: 60px 0 50px;
  }

  @media #{$mobile} {
    padding: 50px 0 0;
  }

  .container {
    align-items: stretch;
    display: flex;
    max-width: 920px;
    padding-left: 15px !important;
    padding-right: 15px !important;

    @media #{$tablet} {
      display: block;
    }
  }

  &__information {
    &__wrapper-btn {
      margin-bottom: 50px;
      padding: 0 20px;
      border-radius: 4px;

      @media #{$mobile} {
        border: 0;
        padding-left: 0;
        padding-right: 0;
        border-radius: 0;
        width: 100%;
      }

      .wrapper-btn {
        float: left;
        margin: 0 15px 0 0;
        width: calc(50% - 8px);

        .btn {
          margin-top: 0;
        }

        @media #{$mobile} {
          width: 100%;
          margin-right: 0;
          float: none;
        }

        &:nth-child(2) {
          margin-right: 0;
        }
      }

      .btn {
        font-size: 14px;
        text-align: left;
        white-space: normal;
      }

      .btn-with-icon-content {
        font-size: 14px;
        font-weight: 700;
        justify-content: center;
        letter-spacing: .2px;
        line-height: 1.14;
        text-align: left;
        white-space: normal;
        width: calc(100% - 36px);
        text-transform: none;
      }
    }
  }

  .static-title {
    margin-bottom: 35px;
    color: $color-white;
    text-align: center;
  }


  &__information {
    color: $color-white;
    margin-left: 30px;
    width: calc(100% - 580px);

    @media #{$tablet} {
      margin: 0;
      width: 100%;
    }

    .btn {
      width: 100%;
    }

    .btn-white {
      color: $color-title;

      &:hover {
        color: $color-white;
      }
    }

    &__content {
      width: 100%;

      @media #{$tablet} {
        display: flex;
        margin-top: 60px;
      }

      @media #{$mobile} {
        display: block;
        margin-top: 0;
      }

      &__inner {
        padding: 0 20px;

        @media #{$mobile} {
          padding: 0;
        }
      }

      .btn {
        margin-bottom: 16px;
      }
    }

    &__link {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    &__title {
      font-size: 14px;
      line-height: 22px;
      font-weight: 700;
      text-transform: uppercase;
      color: $color-white;
      text-align: left;
      margin: 0;
    }

    &__text {
      margin-bottom: 20px;

      &.information-phones {
        min-height: 44px;
      }

      a {
        color: $color-white;

        &:hover {
          color: darken($color-white, 15%);
        }
      }

      .btn {
        @media #{$tablet} {
          display: none;
        }

        @media #{$mobile} {
          display: none;
        }
      }
    }

    &__phone {
      cursor: pointer;
      display: block;
      margin-bottom: 2px;

      &:hover {
        color: darken($color-white, 15%);
      }
    }
  }

  &__form {
    background-color: $color-white;
    border: solid 2px #eff0f4;
    border-radius: 4px;
    box-shadow: $contact-main__information__form-box-shadow;
    box-sizing: border-box;
    padding: 30px;
    position: relative;
    max-width: 580px;
    width: 100%;

    @media #{$tablet} {
      margin: 0 0 30px;
      width: 100%;
      max-width: 100%;
    }

    @media #{$mobile} {
      max-width: 100%;
    }

    .form-group__styled {
      textarea.input.big-height {
        min-height: 245px;
      }
    }
  }

  &__form-title {
    color: #1e2a42;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: -.6px;
    line-height: 1;
    margin: 0 0 30px;
    text-align: left;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .contact-block__information__wrapper-btn {
    .btn-with-icon-content {
      font-size: 12px;
    }
  }
}
