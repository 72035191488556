mark {
  background-color: #FDE68A;
}
.text-testimonials {
  margin: 70px 0;

  @media #{$mobile} {
    margin: 40px 0;
  }

  .splide {
    visibility: visible;

    &__slide {
      width: 100%;
      margin: 16px;

      @media #{$mobile} {
        margin: 0;
      }

      &:nth-child(-n + 3) {
        width: calc(33.33% - 32px);

        @media #{$mobile} {
          width: 100%;
        }
      }

      &:nth-child(4),
      &:nth-child(5) {
        width: calc(50% - 32px);

        @media #{$mobile} {
          width: 100%;
        }
      }
    }

    &__list {
      margin: 0 -16px !important;

      @media #{$mobile} {
        margin: 0 !important;
      }
    }

    &__pagination {
      bottom: 25px;
    }
  }
}
