.goals__index-c {
  .goals {
    padding: 25px 0;
    background: #0A315A;
  }
}

.why-us {
  padding: 70px 0;
  background-color: $color-orange;
  background-image: url('@/static/images/pages/index/goals/layer-1.svg'), url('@/static/images/pages/index/goals/layer-2.svg'), url('@/static/images/pages/index/goals/layer-3.svg');
  background-repeat: no-repeat;
  background-position: center;
}
