.old-browser-warning {
  padding: 1.5em .5em;
  color: white;
  background: rgb(51, 51, 221);
  text-align: center;
  font-size: 14pt;

  a {
    color: yellow;
    font-weight: bold;
    border-bottom: yellow 1px dashed;
  }

  &__close {
    float: right;
    margin-right: 15px;
    color: white;
    cursor: pointer;
    padding: 0 4px;
  }
}